// import React from 'react';
// import AddReview from '../Components/review';
// import Sidebar from '../Components/main_navbar';


// import { useNavigate } from "react-router-dom";



// const AddReviews = () => {
//     const navigate = useNavigate();
//     const handleBackClick = () => {
//       navigate('reviews_page');
//     };
// return(
//     <>
//     <Sidebar/>
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-poppins ">
   

//    <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//    <img 
//           src="/Assets/arrow_back.svg" 
//           alt="Back" 
//           className="cursor-pointer mr-6" 
//           onClick={handleBackClick} 
//         />
//      <h1 className="text-black font-semibold text-2xl">Reviews</h1>
//    </div>
//    <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
// <AddReview/>

// </div>
// </>
// );    
// };

// export default AddReviews;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import StarRating from './StarRating';
// import ImageUpload from './ImageUpload';
// import './AddReview.css'; // Assume this contains the necessary CSS

// const AddReview = () => {
//   const navigate = useNavigate();
//   const [productName, setProductName] = useState('');
//   const [review, setReview] = useState('');
//   const [rating, setRating] = useState(0);
//   const [images, setImages] = useState([]);

//   const handleSubmit = () => {
//     // Handle form submission, save the review data
//     // Add the new review to the reviews data and navigate back to reviews page
//     const newReview = {
//       name: productName,
//       comment: review,
//       rating,
//       image: images[0], // Assume only one image for simplicity
//     };
//     // Update the reviewsData (assume you have a function to update it)
//     // updateReviewsData(newReview);
//     navigate('/');
//   };

//   return (
//     <div className="add-review-page">
//       <h2>Add Review</h2>
//       <input
//         type="text"
//         placeholder="Product Name"
//         value={productName}
//         onChange={(e) => setProductName(e.target.value)}
//       />
//       <textarea
//         placeholder="Write your review..."
//         value={review}
//         onChange={(e) => setReview(e.target.value)}
//       />
//       <StarRating rating={rating} setRating={setRating} />
//       <ImageUpload images={images} setImages={setImages} />
//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// };

// export default AddReview;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import FileUpload from '../../Components/image_upload';
import InputBox from '../../Components/input_box';
import TextArea from '../../Components/text_area';
import { uploadMedia } from '../../../Controller/company_products';
import { showToast } from '../../Components/toast'; // Import the toast function

const CompanyProduct = () => {
    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate('/view_photos');
    };

    const handleUploadMedia = async () => {
        // Trim productName and description to avoid empty spaces
        const trimmedProductName = productName.trim();
        const trimmedDescription = description.trim();

        // Validation for fields
        if (!trimmedProductName || !trimmedDescription || images.length === 0) {
            showToast('Please fill all fields and upload at least one image.'); // Show toast instead of alert
            return;
        }

        setLoading(true);

        try {
            const imageUrlArray = images.map(img => img.url);
            const response = await uploadMedia(trimmedProductName, trimmedDescription, imageUrlArray);
            if (response.status === 201) {
                console.log('Media uploaded successfully:', response.data);
                navigate("/view_photos");
            } else {
                console.error("Error uploading media:", response);
                showToast('Failed to upload media.'); // Show toast for failure
            }
        } catch (error) {
            console.error("Error uploading media:", error);
            showToast('An error occurred during upload.'); // Show toast for error
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
            <Sidebar />
            <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
                <div className="flex items-center space-x-2">
                    <h1 className="text-black font-semibold text-xl">Create Post</h1>
                </div>
            </div>
            <div className="border-b border-black mt-4 md:mt-[25px]"></div>
            <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4 ">
                <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
                    {/* Product Name Field */}
                    <div className="mt-8">
                        <p className="font-semibold text-sm lg:text-[16px]">Product/Service Name</p>
                        <div className='mt-2'>
                        <InputBox
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder="Enter the product/service"
                            borderRadius="1px"
                            maxLength={50}
                     

                        />
                           </div>
                    </div>

                    {/* Description Field */}
                    <div className="mt-3">
                        <p className="font-semibold text-sm lg:text-[16px]">Description</p>
                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter the product/service description"
                            className="mt-2"
                            maxLength={200}
                        />
                    </div>

                    {/* File Upload Component with maxFiles prop */}
                    <FileUpload
                        files={images}
                        setFiles={setImages}
                        svgImagePath="/Assets/grey_plus.svg"
                        bgColor="#E6E6E6"
                        borderRadius="8px"
                        width="90px"
                        height="90px"
                        maxFiles={3} // Set the maximum number of files to be uploaded to 3
                    />

                    {/* Display Loading or Next Button */}
                    <div className="mt-24">
                        <Button
                            text='Next'
                            borderColor="border-red-600"
                            backgroundColor="bg-[#DB0000]"
                            textColor="text-white"
                            onClick={handleUploadMedia}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyProduct;
