
// import React, { useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import InputBox from '../../Components/input_box';
// import Button from '../../Components/button';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import ReportContainer from '../../Components/report';
// import { createManagementMember } from '../../../Controller/management';

// const AddManagement = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const profile = location.state?.profile || {};
//   const [fullName, setFullName] = useState(profile.fullName || '');
//   const [position, setPosition] = useState(profile.position || '');
//   const [logo, setLogo] = useState(profile.logo || null);

//   const handleSave = async () => {
//     const newProfile = { fullName, position, logo };
//     if (profile.id) {
//       newProfile.id = profile.id;
//     } else {
//       newProfile.id = Date.now();
//     }

//     console.log('Saving new profile:', newProfile);

//     try {
//       const response = await createManagementMember(fullName, position, logo);
//       console.log('Server response:', response);

//       // Save the new profile to local storage
//       const storedProfiles = JSON.parse(localStorage.getItem('managementProfiles')) || [];
//       const updatedProfiles = [...storedProfiles, newProfile];
//       localStorage.setItem('managementProfiles', JSON.stringify(updatedProfiles));
//       console.log('Updated profiles:', updatedProfiles);

//       navigate('/view-management-owner', { state: { newProfileAdded: true } });
//     } catch (error) {
//       console.error('Error creating management member:', error);
//     }
//   };

//   const handleBackClick = () => {
//     navigate('/view-management-owner');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Management</h1>
//         </div>
//         <ReportContainer showRemove={true} />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="w-4/5 lg:ml-7 mx-auto">
//         <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto  md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-techna" style={{ height: '600px', width: '450px' }}>
//           <div className="flex justify-center p-4 md:p-6 lg:p-8 mt-8">
//             <LogoUploader image_url={logo} onUpload={setLogo} isEditable={false} />
//           </div>
//           <div className="flex flex-col mt-5">
//             <p className="font-bold">Full Name</p>
//             <InputBox
//               placeholder="Full Name"
//               value={fullName}
//               onChange={(e) => setFullName(e.target.value)}
//               borderRadius="1px"
//             />
//           </div>
//           <div className="flex flex-col mt-5">
//             <p className="font-bold">Position</p>
//             <InputBox
//               placeholder="Position"
//               value={position}
//               onChange={(e) => setPosition(e.target.value)}
//               borderRadius="1px"
//             />
//           </div>
//           <div className="flex justify-center mt-20">
//             <Button
//               text={profile.id ? "Update" : "Save"}
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSave}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddManagement;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputBox from '../../Components/input_box';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import LogoUploader from '../../Components/circle_avatar';
import ReportContainer from '../../Components/report';
import { createManagementMember, updateManagementMember } from '../../../Controller/management';
import uploadImage from '../../../Controller/firebase/firebase_upload_image';

const AddManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profile = location.state?.profile || {};
  const [name, setFullName] = useState(profile.name || '');
  const [position, setPosition] = useState(profile.position || '');
  const [linkedinUrl, setLinkedinUrl] = useState(profile.linkedin_url || '');  // New state for LinkedIn URL
  const [logo, setLogo] = useState(profile.image_url || null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (profile.image_url) {
      setLogo(profile.image_url);
    }
    if (profile.linkedin_url) {
      setLinkedinUrl(profile.linkedin_url);
    }
  }, [profile.image_url, profile.linkedin_url]);

  const handleImageUpload = async (file) => {
    try {
      setIsLoading(true);
      const url = await uploadImage(file);
      setLogo(url);
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image.');
      setIsLoading(false);
    }
  };

  // LinkedIn URL validation
  const isValidLinkedInUrl = (url) => {
    const linkedInRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
    return linkedInRegex.test(url);
  };

  const handleSave = async () => {
    if (!name || !position || !linkedinUrl || !logo) {
      setError('Please fill in all fields and upload an image.');
      return;
    }

    // Check if the LinkedIn URL is valid
    if (!isValidLinkedInUrl(linkedinUrl)) {
      setError('Please enter a valid LinkedIn URL.');
      return;
    }

    setIsLoading(true);

    try {
      let response;

      if (profile.id) {
        response = await updateManagementMember(profile.id, name, position, linkedinUrl, logo);
      } else {
        response = await createManagementMember(name, position, linkedinUrl, logo);
      }

      console.log('Server response:', response);
      navigate('/view-management', { state: { newProfileAdded: true } });
    } catch (error) {
      console.error('Error creating/updating management member:', error);
      setError('Failed to save management member. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold  text-xl">Management</h1>
        </div>
        <ReportContainer showRemove={Boolean(profile.id)} profileId={profile.id} />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
      <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex justify-center ">
            <LogoUploader 
              logo={logo} 
              onUpload={handleImageUpload} 
              isEditable={true} 
              showEditIcon={true} 
              error={error}
              onError={setError}
            />
          </div>
          <div className="flex flex-col mt-5 font-poppins">
            <p className="font-semibold">Full Name</p>
            <InputBox
              placeholder="Full Name"
              value={name}
              onChange={(e) => setFullName(e.target.value)}
              borderRadius="1px"
            />
          </div>
          <div className="flex flex-col mt-5 font-poppins">
            <p className="font-semibold">Position</p>
            <InputBox
              placeholder="Designation"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              borderRadius="1px"
            />
          </div>
          <div className="flex flex-col mt-5 font-poppins">
            <p className="font-semibold">LinkedIn Url</p>
            <InputBox
              placeholder="https://www.linkedin.com/in/username"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}  // Handle change for LinkedIn URL
              borderRadius="1px"
            />
          </div>
          {error && <p className="text-red-600 text-[10px] mt-4">{error}</p>}
          <div className="flex justify-center mt-20">
            <Button
              text={profile.id ? "Update" : "Save"}
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManagement;
