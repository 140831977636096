import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai'; // Import the close icon from react-icons
import CenteredImageContainer from '../../Components/black_container.jsx';
import PasswordInputField from '../../Components/password-input-field.jsx';
import InputBox from '../../Components/input_box.jsx';
import Button from '../../Components/button.jsx';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { handleLogin } from '../../../Controller/login_controller.js';
import { Toast } from '../../Components/toast.jsx';

const MainContentLogin = () => {
  const [mail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(true); // State to control alert visibility
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically show alert when the page loads
    setTimeout(() => {
      setShowAlert(true);
    }, 500); // Delay for alert to show after page loads (optional)
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onLoginClick = () => {
    setLoading(true);
    handleLogin(password, mail, navigate, setLoading);
  };

  const handleSignUpClick = () => {
    localStorage.clear();
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8] relative">
      <Toast />


      {showAlert && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="relative flex flex-col items-center justify-center mx-2">
      {/* Close button inside the top-right corner of the image */}
      <button
        className="absolute top-2 right-2 text-white"
        onClick={() => setShowAlert(false)} // Close alert
      >
        <AiOutlineClose size={24} color="#616060" /> {/* X icon with specified color */}
      </button>

      {/* Clickable area for small screens */}
      <a 
        href="https://play.google.com/store/apps/details?id=com.wecodelife.eswaf" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img 
          src="./get mobil web.svg" 
          alt="Alert Small Screen" 
          className="w-full sm:hidden " 
        />
      </a>

      {/* Clickable area for larger screens */}
      <a 
        href="https://play.google.com/store/apps/details?id=com.wecodelife.eswaf" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img 
          src="./get app web.svg" 
          alt="Alert Large Screen" 
          className="hidden sm:block w-full lg:w-full " 
        />
      </a>
    </div>
  </div>
)}


      <div
        className="hidden lg:block w-full lg:w-1/2  h-full  relative"
        // style={{
        //   backgroundImage: `url('/Assets/hexagon_bg.svg')`,
        //   backgroundPosition: ' right',
        //   backgroundSize: 'auto',
        //   backgroundRepeat: 'no-repeat',
        // }}
      > <div className='lg:w-4/5 h-full'> 
        <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
      {/* Right side with login form */}
      <div className="w-full lg:w-2/5  h-full flex flex-col justify-center items-start space-y-4  font-poppins">
        <div className="p-6  w-full max-w-2/5">
          <div className="flex  mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <h1 className="text-xl lg:text-lg font-normal mb-2 text-[#9F9F9F]">WELCOME BACK!</h1>
          <h2 className="text-xl lg:text-3xl font-semibold text-[#0D0907] mb-6">Login to continue</h2>
          <form className="flex flex-col space-y-6" onSubmit={(e) => e.preventDefault()}>

            {/* Email Label and Input */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="email" className="text-xs lg:text-sm font-medium text-[#545454]">
                Email
              </label>
              <InputBox
                id="email"
                type="email"
                placeholder="Company email"
                value={mail}
                onChange={handleEmailChange}
                borderRadius="4px"
                backgroundColor="#FFFFFF"
             
              />
            </div>

            {/* Password Label and Input */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="password" className=" text-xs lg:text-sm font-medium text-[#545454]">
                Password
              </label>
              <PasswordInputField
                id="password"
                placeholder="Enter the password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                borderRadius="4px"
                backgroundColor="#FFFFFF"
                inputClassName="placeholder-gray-300 font-helvetica h-full"
              />
            </div>

            {/* Forgot Password Link */}
            <div className="flex justify-end text-[#888888] text-sm mb-4 ml-4">
              <a href="forgot-password-page" className="forgot-password lg:text-sm text-xs">
                Forgot Password? <span className="text-black font-semibold text-xs lg:text-sm underline ">Click to Reset Password</span>
              </a>
            </div>

            {/* Login Button */}
            <Button
              text="Log In"
              onClick={onLoginClick}
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              
              loading={loading}
            />
            </form> 
           
            <div className="mt-4 text-center text-black-500">
              <span className="text-[#171717] text-xs lg:text-base">Don’t have an account? </span>
              <Link to="/sign-up" className="text-[#DB0000] font-semibold text-xs lg:text-base" onClick={handleSignUpClick}>
                Sign up
              </Link>
            </div>
        
          <div className="mt-10 text-center text-gray-500 text-[10px] lg:text-sm">
            <Link to="/privacy_policy" className="hover:underline">Privacy Policies</Link>
            <span className="mx-2">|</span>
            <Link to="/terms_policies" className="hover:underline">Terms and Conditions</Link>
            <div className="mt-1">© 2024 Eswaf</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContentLogin;
