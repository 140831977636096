// import React, { useState } from 'react';
// import InputBox from '../../Components/input_box'; // Assuming the path to your InputBox component
// import Button from '../../Components/button';
// import Sidebar from '../../Components/main_navbar';
// import { useNavigate } from 'react-router-dom';
// import ReportContainer from '../../Components/report';

// const ProductService = () => {
//   const [productName, setProductName] = useState('');
//   const [products, setProducts] = useState([]);

//   const handleSave = () => {
//     if (productName.trim() !== '') {
//       setProducts([...products, productName]);
//       setProductName('');
//     }
//   };

//   const handleDelete = (index) => {
//     const newProducts = products.filter((_, i) => i !== index);
//     setProducts(newProducts);
//   };

//   const navigate = useNavigate();
      
//   const handleBackClick = () => {
//     navigate('/profile1');
//   };
//   const OnClick = () => {
//     navigate('/report_page'); 
//   };
//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] poppins text-sm  overflow-auto">
//       <Sidebar />
//       <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
//             <div className="w-full flex justify-start items-center space-x-2">
                
//               <img
//                 src="/Assets/arrow_back.svg"
//                 alt="Back"
//                 className="cursor-pointer mr-2 h-6 w-6"
//                 onClick={handleBackClick}
//               />
//               <h1 className="text-black font-bold text-xl">Product/Service</h1>   </div>
//              <ReportContainer/>
//           </div>
//       <div className="border-b border-black mt-4 md:mt-[25px] bg-[#F2F3F3] "></div>
//       <div className="w-4/5 ml-10 mt-9">
//       <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F9F9F9] rounded-lg poppins text-sm " style={{ height: '600px', width: '450px' }}>
//         <p className="text-[#535353] text-sm mb-4">
//           Avoid listing the same product with different keywords. (Example: Nails, Strong Nails, Good quality Nails)
//           <br />
//           Just list the exact name of the product clearly. (Example: Nails, Wire)
//         </p>
//         <div className="mt-7">
//           <InputBox
//             value={productName}
//             onChange={(e) => setProductName(e.target.value)}
//             placeholder="Product Name"
//             borderRadius='1px'
//           />
//           <Button
//             onClick={handleSave}
//             text="Add"
//             borderColor="border-red-600"
//             textColor="text-red-600"
//           />
//         </div>
//         <div className="mt-7">
//           {products.map((product, index) => (
//             <div key={index} className="bg-gray-200 p-2 mb-2 rounded flex justify-between items-center">
//               <span className="text-bold">{product}</span>
//               <img
//                 src="Assets/bin.svg" 
//                 alt="delete icon"
//                 className="w-5 h-5 cursor-pointer"
//                 onClick={() => handleDelete(index)}
//               />
//             </div>
//           ))}
//         </div>
//         <div className="flex  mt-60 justify-end">
//         <Button
//           text="Save"
//           borderColor="border-red-600"
//           backgroundColor="bg-[#DB0000]"
//           textColor="text-white"
//           navigateTo="/product_service_list"
//         />
//         </div>
//       </div>
     
//     </div>
//     </div>
//   );
// };

// export default ProductService;
// import React, { useState } from 'react';
// import InputBox from '../../Components/input_box';
// import Button from '../../Components/button';
// import Sidebar from '../../Components/main_navbar';
// import { useNavigate } from 'react-router-dom';
// import ReportContainer from '../../Components/report';
// import { createProduct } from '../../../Controller/product_service';
// import { showToast, Toast } from '../../Components/toast'; // Import the toast functions
// import LoaderSpinner from '../../Components/loader_spinner'; // Import the LoaderSpinner component
// import DeletePopup from '../../Components/delete_pop_up';


// const ProductService = () => {
//   const [items, setItems] = useState('');
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const [deleteIndex, setDeleteIndex] = useState(null);
//   const navigate = useNavigate();

//   const handleAddProduct = () => {
//     if (items.trim() !== '') {
//       setProducts([...products, items.trim()]);
//       setItems('');
//     } else {
//       showToast('Please enter a product name.', 'error');
//     }
//   };

//   const handleSave = async () => {
//     if (products.length > 0) {
//       setLoading(true);
//       try {
//         const response = await createProduct(products);
//         console.log('add response', response);
//         setProducts([]);
//         localStorage.setItem('productsCompleted', 'true');
//         navigate('/product_service_list');
//       } catch (error) {
//         console.error('Error:', error);
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       showToast('Please add at least one product.', 'error');
//     }
//   };

//   const handleDelete = (index) => {
//     setDeleteIndex(index);
//     setShowPopup(true);
//   };

//   const handleConfirmDelete = () => {
//     const newProducts = products.filter((_, i) => i !== deleteIndex);
//     setProducts(newProducts);
//     setShowPopup(false);
//     showToast('Product removed successfully.', 'success');
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const handleBackClick = () => {
//     navigate('/profile1');
//   };

//   return (
//     <div className="flex flex-col w-full h-full  bg-[#FBFCF8] font-poppins  overflow-auto ">
//       <Sidebar />
//       <Toast />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-[#171717] font-semibold  sm:text-lg lg:text-xl">Product / Service</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px] bg-[#FBFCF8]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-10 mt-10 mx-4 overflow-auto ">
//       <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <p className="text-[#535353] lg:text-sm text-[12px] mb-4 font-poppins">
//             Enter the name of the product or service you'd like to add (up to 4 items).
//             {/* <br />
//             Just list the exact name of the product clearly. (Example: Nails, Wire) */}
//           </p>
//           <div className="mt-7">
//             <InputBox
//               value={items}
//               onChange={(e) => setItems(e.target.value)}
//               placeholder="Product/Service name"
//               borderRadius="1px"
//             />
//             <Button
//               onClick={handleAddProduct}
//               text="Add"
//               borderColor="border-red-600"
//               textColor="text-red-600"
          
//             />
//           </div>
//           <div className="mt-7">
//             {products.map((product, index) => (
//               <div key={index} className="bg-gray-200 p-2 mb-2 rounded flex justify-between items-center">
//                 <span className="font-normal font-techna">{product}</span>
//                 <img
//                   src="Assets/bin.svg"
//                   alt="delete icon"
//                   className="w-5 h-5 cursor-pointer"
//                   onClick={() => handleDelete(index)}
//                 />
//               </div>
//             ))}
//           </div>
//           <div className="flex justify-center items-center mt-40">
//           <Button
//               text="Save"
//               onClick={handleSave}
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               fontWeight="font-bold"
//               loading={loading}
//             />
//           </div>
//         </div>
//       </div>
//       {showPopup && (
//         <DeletePopup
//           message="Are you sure you want to delete this product?"
//           onConfirm={handleConfirmDelete}
//           onClose={handleClosePopup}
//         />
//       )}
//     </div>
//   );
// };

// export default ProductService;
import React, { useState } from 'react';
import InputBox from '../../Components/input_box';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import { createProduct } from '../../../Controller/product_service';
import { showToast, Toast } from '../../Components/toast'; // Import the toast functions
import LoaderSpinner from '../../Components/loader_spinner'; // Import the LoaderSpinner component
import DeletePopup from '../../Components/delete_pop_up';
import TextArea from '../../Components/text_area';

const ProductService = () => {
  const [items, setItems] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const navigate = useNavigate();

  const handleAddProduct = () => {
    if (products.length >= 4) {
      showToast('You can only add up to 4 items at a time.', 'error');
      return;
    }

    if (items.trim() !== '') {
      setProducts([...products, items.trim()]);
      setItems('');
    } else {
      showToast('Please enter a product name.', 'error');
    }
  };

  const handleSave = async () => {
    if (products.length > 0) {
      setLoading(true);
      try {
        const response = await createProduct(products);
        console.log('add response', response);
        setProducts([]);
        localStorage.setItem('productsCompleted', 'true');
        navigate('/product_service_list');
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    } else {
      showToast('Please add at least one product.', 'error');
    }
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setShowPopup(true);
  };

  const handleConfirmDelete = () => {
    const newProducts = products.filter((_, i) => i !== deleteIndex);
    setProducts(newProducts);
    setShowPopup(false);
    showToast('Product removed successfully.', 'success');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleBackClick = () => {
    navigate('/profile1');
  };

  return (
    <div className="flex flex-col w-full h-full  bg-[#FBFCF8] font-poppins  overflow-auto ">
      <Sidebar />
      <Toast />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold  sm:text-lg lg:text-xl">Product / Service</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px] bg-[#FBFCF8]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-10 mt-10 mx-4 overflow-auto ">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <p className="text-[#535353] lg:text-sm text-[12px] mb-4 font-poppins">
            Enter the name of the product or service you'd like to add (up to 4 items).
          </p>
          <div className="mt-7">
            <InputBox
              value={items}
              onChange={(e) => setItems(e.target.value)}
              placeholder="Product/Service name"
              borderRadius="1px"
              maxLength={50}
            />
            <Button
              onClick={handleAddProduct}
              text="Add"
              borderColor="border-red-600"
              textColor="text-red-600"
            />
          </div>
          <div className="mt-7">
            {products.map((product, index) => (
              <div key={index} className="bg-gray-200 p-2 mb-2 rounded flex justify-between items-center">
                <span className="font-normal poppins lg:text-[16px] text-[12px]">{product}</span>
                <img
                  src="Assets/bin.svg"
                  alt="delete icon"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => handleDelete(index)}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center mt-40">
            <Button
              text="Save"
              onClick={handleSave}
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              fontWeight="font-bold"
              loading={loading}
            />
          </div>
        </div>
      </div>
      {showPopup && (
        <DeletePopup
          message="Are you sure you want to delete this product?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default ProductService;
