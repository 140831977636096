import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { FaSpinner, FaPlus, FaEllipsisV } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import PostCard from '../../Components/post_card';
import PostDetails from '../../Components/post_details';
import { getAllPosts, getPostByCompanyUID, getPostById, deletePostById } from '../../../Controller/post_request';
import { Toast } from '../../Components/toast';
import DeletePopup from '../../Components/delete_pop_up';

const PostList = () => {
  const [posts, setPosts] = useState([]); // Combined state for all posts
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false); // State for toggling menu visibility
  const [isMyPosts, setIsMyPosts] = useState(false); // State to track if we are viewing My Posts
  const [showDeletePopup, setShowDeletePopup] = useState(false); // State to control delete popup visibility
  const [postIdToDelete, setPostIdToDelete] = useState(null); 
  const [hasMore, setHasMore] = useState(true);

  const popupRef = useRef(null);
  const loadingRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const company_id = localStorage.getItem('company_id'); // Fetch company_uid from localStorage
  const newPostData = location.state?.newPostData || null;

  useEffect(() => {
    if (newPostData) {
      if (isMyPosts && newPostData.company_id === company_id) {
        setPosts((prevPosts) => [newPostData, ...prevPosts]);
      }
      navigate(location.pathname, { replace: true, state: {} });
    }

    fetchPosts();
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMyPosts, newPostData, company_id]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingMore) {
          handleLoadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, loadingMore]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const skip = (currentPage - 1) * 5;
      
      let res;
      if (isMyPosts && company_id) {
        res = await getPostByCompanyUID(company_id, skip);
      } else {
        res = await getAllPosts(skip);
      }
  
      if (Array.isArray(res)) {
        setPosts((prevPosts) => 
          currentPage === 1 ? res : [...prevPosts, ...res]
        );
        setHasMore(res.length === 5);
      } else {
        setPosts([]);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (!loadingMore && hasMore) {
      setLoadingMore(true);
      setCurrentPage((prevPage) => prevPage + 1);
      fetchPosts().finally(() => setLoadingMore(false));
    }
  };
  const handleViewPost = (postId) => {
    getPostById(postId)
      .then((res) => {
        setSelectedPost(res);
        if (isMobile) {
          setShowPopup(true); // Show as popup only in mobile view
        }
      })
      .catch((error) => {
        console.error('Error viewing post:', error);
      });
  };

 

  const handleAddPost = () => {
    navigate('/post_request');
  };

  const handleAllPosts = () => {
    setIsMyPosts(false); // Switch back to viewing "All Posts"
    setPosts([]);        // Reset the posts to clear old data
    setSelectedPost(null); // Clear the selected post when switching
    setCurrentPage(1);   // Reset page number to start fresh
  };
  
  const handleMyPosts = () => {
    setIsMyPosts(true);  // Indicate we are viewing "My Posts"
    setPosts([]);        // Reset the posts to clear old data
    setSelectedPost(null); // Clear the selected post when switching
    setCurrentPage(1);   // Reset page number to start fresh
    // navigate(`/posts/${company_id}`);
  };
  

  const toggleMenu = () => {
    setShowMenu(!showMenu); // Toggle the three-dot menu visibility
  };

  // DELETE POST FUNCTIONALITY
  const confirmDeletePost = async () => {
    try {
      if (postIdToDelete) {
        await deletePostById(postIdToDelete);
        setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postIdToDelete));
        setSelectedPost(null);
        showToast('Post deleted successfully!', 'success');
      }
    } catch (error) {
      console.error('Failed to delete post:', error);
      showToast('Failed to delete post.', 'error');
    } finally {
      setShowDeletePopup(false); // Close the popup
      setPostIdToDelete(null); // Reset the post ID
    }
  };

  const handleDeletePost = (postId) => {
    setPostIdToDelete(postId); // Set the ID of the post to be deleted
    setShowDeletePopup(true); // Show the delete confirmation popup
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup && isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup, isMobile]);

  return (
    <div className="flex flex-col md:flex-row w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto relative">
      <Toast />
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5 items-center justify-between">
          <h1 className="text-black font-semibold text-xl">Posts</h1>
  
          {/* Conditionally show the three-dot menu only if not on the profile page and not viewing My Posts */}
          {!(location.pathname.includes('/profile') || isMyPosts) && (
  <div className="relative">
    <img 
      src="/Assets/my_post.svg"  // Replace with the actual path to your "my_post" image
      alt="My Posts"
      className="cursor-pointer w-6 h-6" // Adjust size as needed
      onClick={toggleMenu}
    />
    {showMenu && (
      <div className="absolute right-0 mt-2 w-32 bg-white shadow-lg rounded-lg z-10">
        <div className="cursor-pointer text-sm p-4 hover:bg-gray-200 font-semibold" onClick={handleMyPosts}>
          My Posts
        </div>
      </div>
    )}
  </div>
)}

        </div>

        {/* Hide the border partition on mobile screens */}
        <div className={`${isMobile ? 'hidden' : 'border-b border-black mt-4 md:mt-[25px] grow'}`}></div>

        <div className="flex flex-col md:flex-row h-full relative">
          {/* Left Section with Scrollable Content */}
          <div className={`md:w-1/3 ${isMobile ? '' : 'border-r border-gray-400'} relative h-full overflow-y-auto`}>
          <div className="flex flex-col items-center p-3 md:p-9 h-full">
            {loading && posts.length === 0 ? (
              <div className="my-4">
                <FaSpinner className="animate-spin text-gray-500 text-2xl" />
              </div>
            ) : (
              <>
                {posts.length > 0 ? (
                  posts.map((post, index) => (
                    <PostCard
                      key={index}
                      post={post}
                      onView={handleViewPost}
                    />
                  ))
                ) : (

                  <div className="flex justify-center items-center h-full">
                  <div className="text-center text-black mt-4">No Posts to show!</div>
                </div>
                )}
                {hasMore && (
                  <div ref={loadingRef} className="my-4">
                    {loadingMore && <FaSpinner className="animate-spin text-gray-500 text-2xl" />}
                  </div>
                )}
              </>
            )}
          </div>

            {/* Floating Add Button positioned in the left section, always visible */}
            <button
              className="fixed bottom-5 right-5 lg:left-[28%] bg-red-600 rounded-full w-14 h-14 flex items-center justify-center text-white shadow-lg hover:bg-red-500 transition duration-300 z-50"
              onClick={handleAddPost}
            >
              <FaPlus size={24} />
            </button>
          </div>

          {/* Right Section - Fixed */}
          {selectedPost && !isMobile && (
            <div className="md:w-2/3 lg:w-1/2 h-full sticky top-0 p-10">
              <div className="w-full text-left">
                <PostDetails post={selectedPost} isMyPosts={isMyPosts} onPostDeleted={handleDeletePost} />
              </div>
            </div>
          )}
        </div>
      </div>

      {showPopup && isMobile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={popupRef} className="bg-white p-5 rounded-lg w-11/12 max-w-md">
            <PostDetails post={selectedPost} isMyPosts={isMyPosts} onPostDeleted={handleDeletePost} />
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup 
          message="Are you sure you want to delete this post?"
          onConfirm={confirmDeletePost} // Function to confirm deletion
          onClose={() => setShowDeletePopup(false)} // Function to close popup
        />
      )}
    </div>
  );
};

export default PostList;
