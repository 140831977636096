// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import PasswordInputField from "../../Components/password-input-field";
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";
// import { changeUserPassword } from "../../../Controller/change_password";
// import { FaCheck } from "react-icons/fa";
// import { MdError } from 'react-icons/md';

// const NewPassword = () => {
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [retypeNewPassword, setRetypeNewPassword] = useState(''); // Retype New Password state
//   const [passwordError, setPasswordError] = useState(''); // Error message state
//   const [validationMessages, setValidationMessages] = useState({
//     length: false,
//     lowercase: false,
//     uppercase: false,
//     number: false,
//     special: false,
//   });
//   const [loading, setLoading] = useState(''); // To manage loading state
//   const navigate = useNavigate(); // Navigate hook to navigate to different pages

//   const handlePasswordChange = (e) => {
//     const newPassword = e.target.value;
//     setNewPassword(newPassword);
//     validatePassword(newPassword);
//   };

//   const handleRetypePasswordChange = (e) => {
//     setRetypeNewPassword(e.target.value);
//   };

//   const validatePassword = (password) => {
//     const length = password.length >= 8;
//     const lowercase = /[a-z]/.test(password);
//     const uppercase = /[A-Z]/.test(password);
//     const number = /\d/.test(password);
//     const special = /[!@#$%^&*(),.?":{}|<>]/.test(password);

//     setValidationMessages({
//       length,
//       lowercase,
//       uppercase,
//       number,
//       special,
//     });
//   };

//   const handleForgotPassword = () => {
//     navigate('/forgot-password-page'); // Navigate to the forgot password page
//   };

//   const handleChangePassword = async () => {
//     if (newPassword !== retypeNewPassword) {
//       setPasswordError('Passwords do not match!'); // Set error message
//       return;
//     }

//     setPasswordError(''); // Clear error if passwords match
//     setLoading(true);
//     try {
//       const response = await changeUserPassword(oldPassword, newPassword);
//       console.log("Change Password API Response:", response);
//       if (response.status === 202) {
//         navigate('/'); // Navigate to the login page after success
//       } else if (response.status === 400) {
       
//       }
//     } catch (error) {
//       console.error('Error changing password:', error);
      
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-black font-semibold text-xl">Security</h1>
//         </div>
//       </div>

//       <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

//       <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <p className="font-semibold text-lg">Password</p>
//           <div className="mt-4">
//             <PasswordInputField
//               placeholder="Current Password"
//               name="oldPassword"
//               onChange={(e) => setOldPassword(e.target.value)}
//               border="1px solid #ccc"
//             />
            
//             <PasswordInputField
//               placeholder="New Password"
//               name="newPassword"
//               onChange={handlePasswordChange}
//               border="1px solid #ccc"
//             />
//             <PasswordInputField
//               placeholder="Retype New Password"
//               name="retypeNewPassword"
//               onChange={handleRetypePasswordChange} // Change handler for retype password
//               border="1px solid #ccc"
//               inputClassName="placeholder-red-600 font-helvetica"
//             />

//             {passwordError && (
//               <p className="text-red-600 mt-2 text-[10px] lg:text-[12px]">{passwordError}</p> // Display error message if passwords don't match
//             )}

//             <div className="flex justify-end mt-2">
//               <button
//                 onClick={handleForgotPassword}
//                 className="text-red-600 lg:text-sm text-xs underline cursor-pointer"
//               >
//                 Forgot Password?
//               </button>
//             </div>

//             <div className="mt-10">
//               <Button
//                 text='Save'
//                 backgroundColor="bg-[#DB0000]"
//                 textColor="text-white"
//                 onClick={handleChangePassword} // Call the API when the user clicks "Save"
//                 loading={loading}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewPassword;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordInputField from "../../Components/password-input-field";
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import { changeUserPassword } from "../../../Controller/change_password";
import { FaCheck } from "react-icons/fa";
import { MdError } from 'react-icons/md';

const NewPassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState(''); // Retype New Password state
  const [passwordError, setPasswordError] = useState(''); // Error message state
  const [validationMessages, setValidationMessages] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    match: false,
  });
  const [loading, setLoading] = useState(false); // To manage loading state
  const navigate = useNavigate(); // Navigate hook to navigate to different pages

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
    validateConfirmPassword(newPassword, retypeNewPassword);
  };

  const handleRetypePasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setRetypeNewPassword(confirmPassword);
    validateConfirmPassword(newPassword, confirmPassword);
  };

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    setValidationMessages((prev) => ({ ...prev, ...validations }));
    return validations;
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    const match = password === confirmPassword;
    setValidationMessages((prev) => ({ ...prev, match }));
    return match;
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password-page'); // Navigate to the forgot password page
  };

  const handleChangePassword = async () => {
    if (!validationMessages.match) {
      setPasswordError('Passwords do not match!'); // Set error message
      return;
    }

    setPasswordError(''); // Clear error if passwords match
    setLoading(true);
    try {
      const response = await changeUserPassword(oldPassword, newPassword);
      console.log("Change Password API Response:", response);
      if (response.status === 202) {
        navigate('/'); // Navigate to the login page after success
      } else if (response.status === 400) {
        // Handle error
      }
    } catch (error) {
      console.error('Error changing password:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold text-xl">Security</h1>
        </div>
      </div>

      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <p className="font-semibold text-lg">Password</p>
          <div className="mt-4">
            <PasswordInputField
              placeholder="Current Password"
              name="oldPassword"
              onChange={(e) => setOldPassword(e.target.value)}
              border="1px solid #ccc"
            />
            
            <PasswordInputField
              placeholder="New Password"
              name="newPassword"
              onChange={handlePasswordChange}
              border="1px solid #ccc"
            />

            <PasswordInputField
              placeholder="Retype New Password"
              name="retypeNewPassword"
              onChange={handleRetypePasswordChange} // Change handler for retype password
              border="1px solid #ccc"
              inputClassName="placeholder-red-600 font-helvetica"
            />

            {/* Password Validation Messages */}
            <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2 text-sm text-[#545454] font-poppins font-normal">
              <div className="text-[12px] flex items-center space-x-2">
                {validationMessages.length ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
                <span>At least 8 characters</span>
              </div>
              <div className="text-[12px] flex items-center space-x-2">
                {validationMessages.lowercase ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
                <span>Lowercase letter</span>
              </div>
              <div className="text-[12px] flex items-center space-x-2">
                {validationMessages.uppercase ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
                <span>Uppercase letter</span>
              </div>
              <div className="text-[12px] flex items-center space-x-2">
                {validationMessages.number ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
                <span>Number</span>
              </div>
              <div className="text-[12px] flex items-center space-x-2">
                {validationMessages.special ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
                <span>Special character</span>
              </div>
            </div>

            {/* Password Match Error */}
            {passwordError && (
              <p className="text-red-600 mt-2 text-[10px] lg:text-[12px]">{passwordError}</p> // Display error message if passwords don't match
            )}

            <div className="flex justify-end mt-2">
              <button
                onClick={handleForgotPassword}
                className="text-red-600 lg:text-sm text-xs underline cursor-pointer"
              >
                Forgot Password?
              </button>
            </div>

            <div className="mt-10">
              <Button
                text='Save'
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                onClick={handleChangePassword} // Call the API when the user clicks "Save"
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;

