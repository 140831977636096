import React from 'react';
import StarRating from './review_star';
import { categoryMapping } from './category';
const CompanyCard = ({ company }) => {
  // Get the category label based on the categoryId
  const categoryLabel = categoryMapping[company.category] || 'Category not provided';


  return (
    <div className="flex flex-row w-full bg-[#FFFFFF] shadow-xl mt-7 rounded-lg">
      {/* Left Section for the Logo */}
      <div className="flex items-center justify-center p-0 md:p-6 bg-[#FFFFFF] w-1/3 rounded-lg ">
        {company.logo ? (
          <img src={company.logo} alt={company.name} className="h-20 w-20 rounded-full" />
        ) : (
          <div className="h-20 w-20 bg-gray-300 rounded-full flex items-center justify-center lg:text-lg  text-[14px] font-bold">
            {company.name.charAt(0)} {/* Show the first letter if logo is not available */}
          </div>
        )}
      </div>

      {/* Right Section for Details */}
      <div className="flex flex-col justify-center p-2 md:p-6 bg-white w-2/3 rounded-lg">
        {/* Company Name */}
        <h3 className="mb-1 sm:text-sm text-left font-poppins font-bold lg:text-lg leading-tight text-[#181717]">
          {company.name}
        </h3>

        {/* Category */}
        <p className="text-xs lg:text-sm font-semibold font-gotham mb-1">
          {categoryLabel} {/* Display the category name */}
        </p>


        {/* Location with Icon */}
        <div className="flex items-center mb-1 text-sm font-semibold text-[#181717]">
          <img src="/Assets/location-new.svg" alt="Location" className="w-4 h-4 mr-1" />
          <span>{company.location || 'Location not provided'}</span>
        </div>

        {/* Star Rating */}
        <div className="mt-1">
          <StarRating review={company.rating } /> {/* Pass the rating to the StarRating component */}
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
