import React, { useState } from 'react';
import InputBox from '../../Components/input_box';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../Components/toast';
import { saveManagerDetails } from '../../../Controller/manager';

const CompanyAccount_Managed = () => {
  const navigate = useNavigate();
  const [manager, setManagerName] = useState('');
  const [linkedInUrl, setLinkedInUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [authorizationFileUrl] = useState(
    'https://firebasestorage.googleapis.com/v0/b/eswaf-d4041.appspot.com/o/authorizationDocx%2Flatest%20authorization%20letter(21-09-2024).pdf?alt=media&token=5e4e1734-154e-499d-a18b-bcb21aff1698'
  );
  const [authorizationFileName] = useState('Authorization letter.pdf');

  const handleNameChange = (e) => {
    setManagerName(e.target.value);
  };

  const handleLinkedInUrlChange = (e) => {
    setLinkedInUrl(e.target.value);
  };

  const handleAgreeToTerms = () => {
    setAgreeToTerms(!agreeToTerms);
  };

  const isValidLinkedInUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
    return regex.test(url);
  };

  const handleSave = async () => {
    if (!agreeToTerms) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
      return;
    }

    if (linkedInUrl && !isValidLinkedInUrl(linkedInUrl)) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
      return;
    }
  
    setLoading(true);
    try {
      await saveManagerDetails(manager, linkedInUrl, navigate, setLoading);
      localStorage.setItem('COMPANY_DETAILS_MANAGER_COMPLETED', 'true');
    } catch (error) {
      console.error('Error saving manager details:', error);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleViewAuthorizationFile = () => {
    window.open(authorizationFileUrl, '_blank');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
      <Sidebar />
      {showToast && <Toast message="Please provide valid details" />}
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Details</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex items-center mt-2">
            <img src="Assets/account_managed.svg" alt="company icon" className="w-6 h-6 mr-2" />
            <p className="text-[#171717] font-semibold sm:text-sm lg:text-[16px] font-poppins">Account Managed By</p>
          </div>
          <div className="mb-6 mt-5">
            <InputBox
              placeholder="Name"
              value={manager}
              onChange={handleNameChange}
              borderRadius="4px"
            />
          </div>

          {/* LinkedIn URL Section */}
          <div className="flex items-center mt-6">
            <img src="/Assets/linkedin_new.svg" alt="LinkedIn Icon" className="w-6 h-6 mr-2" />
            <p className="text-[#171717] font-semibold sm:text-sm lg:text-[16px] font-poppins">LinkedIn URL</p>
          </div>
          <div className="mb-6 mt-2">
            <InputBox
              placeholder="https://www.linkedin.com/in/username"
              value={linkedInUrl}
              onChange={handleLinkedInUrlChange}
              borderRadius="4px"
            />
          </div>

          {/* Authorization File Section */}
          <div className="flex items-center mt-10">
            <img src="Assets/authorization.svg" alt="PDF Icon" className="w-5 h-5 mr-2" />
            <p className="text-[#171717] font-semibold sm:text-sm lg:text-[16px] font-poppins">Authorization File</p>
          </div>

          {authorizationFileUrl && (
            <div className="flex items-center bg-[#FFFFFF] p-5 mt-3 rounded-lg shadow-md">
              <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-8 h-8 mr-3" />
              <span className="text-[#171717] font-semibold text-xs lg:text-sm font-gotham">{authorizationFileName}</span>
              <button
                onClick={handleViewAuthorizationFile}
                className="text-red-600 text-sm ml-auto underline"
              >
                View
              </button>
            </div>
          )}
        
          <div className="mt-6 flex items-center mb-4">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeToTerms}
              onChange={handleAgreeToTerms}
              className="checkbox-round"
              style={{ accentColor: agreeToTerms ? '#DB0000' : '#6D6D6D' }}
            />
            <label htmlFor="agreeTerms" className="text-[#6D6D6D] lg:text-sm text-[12px] font-poppins">
              I agree to the <span className="text-[#DB0000]">Terms and Conditions</span>
            </label>
          </div>
          <div className="mt-20">
            <Button
              text="Save"
              backgroundColor={agreeToTerms ? "bg-[#DB0000]" : "bg-gray-400"}
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
              disabled={!agreeToTerms || loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAccount_Managed;
