// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";
// import InputBox from '../../Components/input_box';
// import TextArea from "../../Components/text_area";
// import VideoUpload from '../../Components/video_upload';
// import { post_video } from '../../../Controller/upload_videos';
// import uploadThumbnailToFirebase from '../../../Controller/firebase/thumbnail_upload';
// import { showToast } from '../../Components/toast';

// const UploadVideo = () => {
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [videos, setVideos] = useState([]);
//   const [thumbnail, setThumbnail] = useState('');
//   const [loading, setLoading] = useState(false); // State to track loading status
//   const navigate = useNavigate();

//   const handleDescriptionChange = (e) => {
//     setDescription(e.target.value);
//   };

//   const handleTitleChange = (e) => {
//     setTitle(e.target.value);
//   };

//   const handleGenerateThumbnails = async (videoFile) => {
//     try {
//       const video = document.createElement("video");
//       video.src = URL.createObjectURL(videoFile);
//       video.width = 320;
//       video.height = 240;
//       video.autoplay = false;
//       video.muted = true;

//       const canvas = document.createElement("canvas");
//       canvas.width = 320;
//       canvas.height = 240;
//       const ctx = canvas.getContext("2d");

//       await new Promise((resolve) => {
//         video.addEventListener("canplaythrough", resolve);
//       });
//       video.play();
//       await new Promise((resolve) => setTimeout(resolve, 1000));
//       ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
//       const thumbnailBase64 = canvas.toDataURL("image/jpeg", 0.8);

//       const thumbnailUrl = await uploadThumbnailToFirebase(thumbnailBase64);
//       setThumbnail(thumbnailUrl);
//     } catch (error) {
//       console.error("Error generating thumbnails:", error);
//       // alert("Error generating thumbnails. Please try again.");
//     }
//   };

//   const handleNextClick = async () => {
//     if (videos.length > 0 && thumbnail) {
//       setLoading(true); // Set loading to true when the button is clicked
//       const video_url = videos[0];
//       try {
//         const thumb_nail_url = thumbnail;
//         const res = await post_video(title, description, video_url, thumb_nail_url);
//         if (res.status === 201) {
//           navigate('/uploaded_video');
//         } else {
//           console.error('Failed to upload video. Please try again.');
//         }
//       } catch (error) {
//         console.error('Error during video upload:', error);
//       } finally {
//         setLoading(false); // Set loading to false when the upload is complete
//       }
//     } else {
    
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins overflow-auto">
//       <Sidebar />
//       <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
//         <div className="flex items-center space-x-2">
//           <h1 className="text-black font-semibold sm:text-lg lg:text-xl font-poppins">Upload Video</h1>
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <div className="mt-2">
//             <VideoUpload
//               files={videos}
//               setFiles={setVideos}
//               onUploadComplete={handleGenerateThumbnails}
//               svgImagePath="Assets/grey_plus.svg"
//               bgColor=""
//               borderRadius="8px"
//               width="80px"
//               height="120px"
//             />
//           </div>
//           <div className="mt-4">
//             <p className="font-semibold text-sm lg:text-lg">Title</p>
//             <InputBox
//               placeholder="Title"
//               borderRadius="2px"
//               value={title}
//               onChange={handleTitleChange}
//             />
//           </div>
//           <div className="mt-4">
//             <p className="font-semibold text-sm lg:text-lg">Description</p>
//             <TextArea
//               placeholder="Description"
//               value={description}
//               onChange={handleDescriptionChange}
//               maxLength={150}
//             />
//           </div>
//           <div className="mt-20">
//             <Button
//               text="Next"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleNextClick}
//               loading={loading} // Pass the loading state to the Button component
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UploadVideo;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import InputBox from '../../Components/input_box';
import TextArea from "../../Components/text_area";
import VideoUpload from '../../Components/video_upload';
import { post_video } from '../../../Controller/upload_videos';
import uploadThumbnailToFirebase from '../../../Controller/firebase/thumbnail_upload';
import { showToast } from '../../Components/toast';

const UploadVideo = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [videos, setVideos] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading status
  const navigate = useNavigate();

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleGenerateThumbnails = async (videoFile) => {
    try {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(videoFile);
      video.width = 320;
      video.height = 240;
      video.autoplay = false;
      video.muted = true;

      const canvas = document.createElement("canvas");
      canvas.width = 320;
      canvas.height = 240;
      const ctx = canvas.getContext("2d");

      await new Promise((resolve) => {
        video.addEventListener("canplaythrough", resolve);
      });
      video.play();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailBase64 = canvas.toDataURL("image/jpeg", 0.8);

      const thumbnailUrl = await uploadThumbnailToFirebase(thumbnailBase64);
      setThumbnail(thumbnailUrl);
    } catch (error) {
      console.error("Error generating thumbnails:", error);
    }
  };

  const handleNextClick = async () => {
    // Trim title and description to remove extra spaces
    const trimmedTitle = title.trim();
    const trimmedDescription = description.trim();

    // Check if all fields are filled
    if (videos.length === 0 || !trimmedTitle || !trimmedDescription || !thumbnail) {
    showToast('Please add the video, title, and description.')
      return;
    }

    setLoading(true); // Set loading to true when the button is clicked
    const video_url = videos[0];
    try {
      const thumb_nail_url = thumbnail;
      const res = await post_video(trimmedTitle, trimmedDescription, video_url, thumb_nail_url);
      if (res.status === 201) {
        navigate('/uploaded_video');
      } else {
        console.error('Failed to upload video. Please try again.');
      }
    } catch (error) {
      console.error('Error during video upload:', error);
    } finally {
      setLoading(false); // Set loading to false when the upload is complete
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
        <div className="flex items-center space-x-2">
          <h1 className="text-black font-semibold sm:text-lg lg:text-xl font-poppins">Upload Video</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="mt-2">
            <VideoUpload
              files={videos}
              setFiles={setVideos}
              onUploadComplete={handleGenerateThumbnails}
              svgImagePath="Assets/grey_plus.svg"
              bgColor=""
              borderRadius="8px"
              width="80px"
              height="120px"
            />
          </div>
          <div className="mt-4">
            <p className="font-semibold text-sm lg:text-lg">Title</p>
            <InputBox
              placeholder="Title"
              borderRadius="2px"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="mt-4">
            <p className="font-semibold text-sm lg:text-lg">Description</p>
            <TextArea
              placeholder="Description"
              value={description}
              onChange={handleDescriptionChange}
              maxLength={150}
            />
          </div>
          <div className="mt-20">
            <Button
              text="Next"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleNextClick}
              loading={loading} // Pass the loading state to the Button component
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;

