// import React from 'react';

// const ChatList = ({ messages, users, onSelectMessage, selectedMessageId, currentUser }) => {
//   if (!Array.isArray(users) || !Array.isArray(messages) || !currentUser) {
//     return null;
//   }

//   const filteredMessages = messages.filter(msg => msg.participants.includes(currentUser.id));

//   return (
//     <div>
//       {filteredMessages.length === 0 ? (
//         <p>No chats available</p>
//       ) : (
//         filteredMessages.map((message) => {
//           const userId = message.participants.find(participant => participant !== currentUser.id);
//           const user = users.find(u => u.id === userId);

//           if (!user) {
//             console.log(`No user found for userId: ${userId}`);
//             return null;
//           }

//           const isImportant = currentUser.id === message.senderUid
//             ? message.isImportantForSender
//             : message.isImportantForReceiver;

//           return (
//             <div
//               key={user.id}
//               onClick={() => onSelectMessage(user.id)}
//               className={`flex items-center space-x-4 p-2 hover:bg-gray-200 rounded cursor-pointer w-full ${
//                 selectedMessageId === user.id ? 'bg-gray-200' : ''
//               }`}
//             >
//               <img
//                 src={user.userImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'}
//                 alt={user.userName}
//                 className="w-10 h-10 rounded-full"
//               />
//               <div className="flex-grow">
//                 <h3 className="font-bold">{user.userName}</h3>
//                 <p>{message.lastMessage || 'No messages yet'}</p>
//                 {/* Removed the last seen display */}
//               </div>
//               {isImportant && (
//                 <span className="text-red-500 text-xs">★</span>
//               )}
//               {!message.lastMessageSeen && message.lastMessageUid !== currentUser.id && (
//                 <span className=""></span>
//               )}
//             </div>
//           );
//         })
//       )}
//     </div>
//   );
// };

// export default ChatList;
import React from 'react';

const ChatList = ({ messages, currentUserUid, onSelectMessage, selectedMessageId }) => {
  if (!Array.isArray(messages) || !currentUserUid) {
    return null;
  }

  return (
    <div className="p-4"> {/* Add padding to the container for spacing */}
      {messages.length === 0 ? (
        <p>No chats available</p>
      ) : (
        messages.map((message) => {
          const otherUserId = message.participants.find(uid => uid !== currentUserUid);
          const isSelected = selectedMessageId === message.id;

          const displayName = message.receiverUid === currentUserUid ? message.senderName : message.receiverName;
          const displayImage = message.receiverUid === currentUserUid ? message.senderImage : message.receiverImage;

          // Trim the last message to 30 characters, append '...' if the message is longer
          const trimmedLastMessage = message.lastMessage.length > 30 
            ? `${message.lastMessage.substring(0, 30)}...`
            : message.lastMessage;

          // Determine if the message is important for the current user
          const isImportant = currentUserUid === message.senderUid
            ? message.isImportantForSender
            : message.isImportantForReceiver;

          return (
            <div
              key={message.id}
              onClick={() => onSelectMessage(message)}
              className={`flex items-center font-poppins space-x-4 p-4 mb-4 bg-gray-100 rounded-lg shadow-md cursor-pointer w-full ${isSelected ? 'bg-gray-200' : ''}`}
              style={{ marginBottom: '10px' }} // Add margin for spacing between items
            >
              <img
                src={displayImage || ''}
                alt={displayName}
                className="w-12 h-12 rounded-full"
              />
              <div className="flex-grow">
                <h3 className="font-semibold text-[16px]">{displayName}</h3>
                <p className="text-gray-600 text-sm">{trimmedLastMessage}</p>
              </div>

              {/* Render a star icon if the message is important */}
              {isImportant && (
                <div>
                  <img
                    src="/Assets/important_star.svg" // Make sure to add a path to your star icon
                    alt="Important"
                    className="w-4 h-4"
                  />
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ChatList;

