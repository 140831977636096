import React, { useState } from 'react';
import Discover from '../../Components/discover_screen';
import Explore from '../../Components/explore_screen';
import Sidebar from '../../Components/main_navbar';
import { FaSpinner } from 'react-icons/fa'; // Import spinner from react-icons
import { post_company_bookmark,delete_company_bookmark } from '../../../Controller/bookmarks';

const DiscoverExplore = () => {
  const [loading, setLoading] = useState(false); // Centralized loading state

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins relative">
      {loading && ( // Conditionally render the spinner in the center of the page
        <div className="absolute inset-0 flex justify-center items-center z-50 bg-opacity-50 bg-black">
          <FaSpinner className="animate-spin text-[#DB0000]" size="20px" />
        </div>
      )}

      <Sidebar />
      <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
        <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Discover</h1>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 h-full w-full overflow-y-auto overflow-x-hidden">
        <div className="lg:h-1/4 w-full md:h-1/3 sm:h-1/4 mx-1">
          <Discover setLoading={setLoading} /> {/* Pass setLoading to Discover */}
        </div>
        <div className="lg:h-full lg:w-full lg:mt-32 mt-2 mx-1">
          <h2 className="text-xl text-[#4A4A4A] font-semibold p-4">Explore</h2>
          <Explore setLoading={setLoading} /> {/* Pass setLoading to Explore */}
        </div>
      </div>
    </div>
  );
};

export default DiscoverExplore;
