import React, { useEffect, useState } from "react";
import EswafHeaderLogo from "../../Components/trade_role_header_logo";
import EswafFooterLogo from "../../Components/trade_role_footer";
import { useNavigate } from "react-router-dom";
import '../../Components/hexagon_body.css'; 
import Sidebar from "../../Components/main_navbar";
import VerificationStatus from "../../Components/verification_status";
import { get_verified } from "../../../Controller/company_details";
import ReportContainer from "../../Components/report";

const smallScreenUrls = {
  logistics: './logisticsmobile.svg',
  finance: './finance_small.svg',
  start_up: './startupmobile.png',
  legal: './legal_mobile.svg',
  jewelry: './jewelry_small.svg',
  investor: './investormobile.png',
  seller: './sellermobile.png',
  market_place: './marketplacemobile.png',
  projects: './projectsmobile.png',
  buyer: './buyermobile35827.svg'
};

const largeScreenUrls = {
  logistics: './new-logistics.svg',
  finance: './Finance.svg',
  start_up: './new-start-up.svg',
  legal: './new-legal.svg',
  jewelry: './jewelry.svg',
  investor: './new-investor.svg',
  seller: './new-seller.svg',
  market_place: './new-market.svg',
  projects: './new-projects.svg',
  buyer: './new-buyer.svg'
};

const MainHome = () => {
  const [imageUrls, setImageUrls] = useState(largeScreenUrls);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [remarks, setRemarks] = useState(''); // New state for remarks
  const [loading, setLoading] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Popup visible state initially false
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageUrls(smallScreenUrls);
      } else {
        setImageUrls(largeScreenUrls);
      }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkVerificationStatus = async () => {
      try {
        const response = await get_verified();
        console.log("API Response:", response); // Log the full response
  
        if (response.status === 200) {
          const data = response.data.data; // Extract data once
          const status = data.verification_status;
          const remarksFromResponse = data.remarks; // Get remarks from response

          // Set remarks in state and store in localStorage
          setRemarks(remarksFromResponse || '');
          localStorage.setItem('remarks', remarksFromResponse || '');

          console.log("Verification Status:", status);
          console.log("Remarks:", remarksFromResponse); // Log remarks for debugging

          const {
            name,
            manager,
            website,
            authorization_document,
            location,
            trading_license,
            trading_register,
            tenancy_contract
          } = data;

          // Function to check if the field's status is "VERIFICATION_COMPLETED"
          const storeIfVerified = (key, field) => {
            if (field && field.status === "VERIFICATION_COMPLETED") {
              console.log(`Storing ${key} in localStorage, value: ${field.value}`);
              localStorage.setItem(key, field.value || '');
            } else {
              console.log(`Not storing ${key}, status: ${field?.status}`);
            }
          };
  
          // Check verification status for each field and store only if verified
          localStorage.setItem('verificationStatus', status);
          storeIfVerified('companyName', name);                 // Check for name
          storeIfVerified('manager', manager);                  // Check for manager
          storeIfVerified('website', website);                  // Check for website
          storeIfVerified('authorization_document', authorization_document); // Check for authorization_document
          storeIfVerified('location', location);                // Check for location
          storeIfVerified('trading_license', trading_license);  // Check for trading_license
          storeIfVerified('trading_register', trading_register); // Check for trading_register
          storeIfVerified('tenancy_contract', tenancy_contract); // Check for tenancy_contract
  
          // Update the verificationStatus state
          setVerificationStatus(status);

          // Automatically show the popup if the verification is pending or rejected
          if (status === "VERIFICATION_PENDING" || status === "VERIFICATION_REJECTED") {
            setIsPopupVisible(true);
          }
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      } finally {
        setLoading(false);
      }
    };
  
    checkVerificationStatus();
  }, []);
  
  const handleNavigation = (path, categoryId) => {
    if (verificationStatus === "VERIFICATION_COMPLETED") {
      if (path === '/buyer' || path === '/investor') {
        const selectedTradeRole = path === '/buyer' ? 'Buyer' : 'Investor';
        navigate('/post_request', { 
          state: { 
            selectedTradeRole: selectedTradeRole,
            categoryId: categoryId,
            fromHome: true // Add this flag
          } 
        });
      } else {
        navigate(`/${categoryId}`, { state: { selectedTradeRole: '' } });
      }
    } else {
      setIsPopupVisible(true);
    }
  };

  const closeVerificationStatusPopup = () => {
    setIsPopupVisible(false);  // Close the verification status popup
  };

  if (loading) {
    return null; // Show a loading spinner or message if needed
  }

  return (
    <>
      <div> 
      <Sidebar isHomePage={true} handleNavigation={handleNavigation} />
        <EswafHeaderLogo />
        
        <div className="hex-grid-container-wrapper pt-12 pr-7 ">
          <div className='hex-grid-container'>
            <div className='hex-item logistics' onClick={() => handleNavigation('/logistics',1)}>
              <img src={imageUrls.logistics} alt="logistics" />
            </div>
            <div className='hex-item finance' onClick={() => handleNavigation('/financial_institutions',3)}>
              <img src={imageUrls.finance} alt="financialinstitutions" />
            </div>
            <div className='hex-item start_up' onClick={() => handleNavigation('/start_up',4)}>
              <img src={imageUrls.start_up} alt="startup" />
            </div>
            <div className='hex-item legal' onClick={() => handleNavigation('/legal',2)}>
              <img src={imageUrls.legal} alt="legal" />
            </div>
            <div className='hex-item jewelry' onClick={() => handleNavigation('/precious_stones_metals',5)}>
              <img src={imageUrls.jewelry} alt="preciousstones" />
            </div>
            <div className='hex-item investor' onClick={() => handleNavigation('/investor',7)}>
              <img src={imageUrls.investor} alt="invest" />
            </div>
            <div className='hex-item seller' onClick={() => handleNavigation('/seller',6)}>
              <img src={imageUrls.seller} alt="seller" />
            </div>
            <div className='hex-item market_place' onClick={() => handleNavigation('/marketplace',9)}>
              <img src={imageUrls.market_place} alt="marketplace" />
            </div>
            <div className='hex-item projects' onClick={() => handleNavigation('/projects',10)}>
              <img src={imageUrls.projects} alt="projects" />
            </div>
            <div className='hex-item buyer' onClick={() => handleNavigation('/buyer',8)}>
              <img src={imageUrls.buyer} alt="buyer" />
            </div>
          </div>
        </div>
      </div>
      
  
      
      {/* Popup is visible when the verification is pending or rejected */}
      {isPopupVisible && (
        <VerificationStatus
          verificationStatus={verificationStatus}
          onClose={closeVerificationStatusPopup}
        />
      )}
    </>
  );
};

export default MainHome;
