import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileImageContainer from '../../Components/profile_image_container.jsx';
import Button from '../../Components/button.jsx';
import Sidebar from '../../Components/main_navbar.jsx';
import { getOtherProfileById } from '../../../Controller/other_profile.js';
import StarRating from '../../Components/review_star.jsx';
import TabButton from '../../Components/profile_tab.jsx';
import { WebLinkModal, BottomSheet } from '../../Components/web_link.jsx';
import ConfirmationPopup from '../../Components/pop_up';
import { showToast } from '../../Components/toast.jsx';
import ImageButton from '../../Components/image_text_button.jsx';
import { post_company_bookmark,delete_company_bookmark } from '../../../Controller/bookmarks.js';
//addednew 
import { collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase_service';
import { setDoc,getDoc,doc } from 'firebase/firestore';
import { checkIfChatExists,createNewChat } from '../../../services/chatservice.js';

const OtherProfileVisitorsView = ({ editable }) => {
  const { company_id } = useParams(); // Extract company_id from the URL
  const navigate = useNavigate();
  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [category, setCategory] = useState('');
  const [locationText, setLocationText] = useState('');
  const [bio, setBio] = useState('');
  const [webLinks, setWebLinks] = useState([]);
  const [review, setReview] = useState(0);
  const [catalogue, setCatalogue] = useState(null);
  const [website, setWebsite] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false); // Track if the user is followed or not
  const [loadingFollowUp, setLoadingFollowUp] = useState(false); 

  // Fetch profile data based on company_id
  useEffect(() => {
    if (company_id) {
      const fetchData = async () => {
        try {
          const response = await getOtherProfileById(company_id); // Fetch profile data by company_id
          console.log(response)
          if (response.status === 200) {
            const data = response.data.data;
            setStoreName(data.name);
            setCategory(data.category);
            setLocationText(data.address);
            setSelectedProfileImage(data.profile_image_url);
            setReview(data.review);
            setBio(data.bio);
            setWebLinks(data.web_links || []);
            setCatalogue(data.catalogue);
            setWebsite(data.website);

            setIsFollowed(data.is_bookmarked);
  
            // Store the uid in localStorage
            if (data.uid) {
              localStorage.setItem('uid', data.uid);
              localStorage.setItem('other_profile_name', data.name);
              localStorage.setItem('other_profile_image', data.profile_image_url); // Store uid to pass it when navigating
            }
          } else {
            console.error("Failed to fetch company data:", response.status);
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };
  
      fetchData();
    } else {
      console.error("company_id is undefined.");
    }
  }, [company_id]);

  // General function to navigate and pass uid or company_id based on the destination
  const navigateWithParam = (route, useCompanyId = false) => {
    if (useCompanyId) {
      // Pass company_id for the Documents route
      navigate(`${route}/${company_id}`);
    } else {
      const uid = localStorage.getItem('uid'); // Retrieve the uid from localStorage
      if (uid) {
        navigate(`${route}/${uid}`); // Pass the uid as a URL parameter for other cases
      } else {
        console.error('UID not found in local storage');
      }
    }
  };
  const handleDotsClick = () => {
    setIsDotsMenuOpen(!isDotsMenuOpen); // Toggle the dots menu
  };

  const handleViewCatalogue = () => {
    if (catalogue) {
      window.open(catalogue, '_blank');
    }
  };

  const handleAddCatalogue = () => {
    navigateWithParam('/catalog'); // Navigate to catalog page with uid
  };

  const handleWebLinkClick = () => {
    if (window.innerWidth >= 1024) {
      setIsModalOpen(true);
    } else {
      setIsBottomSheetOpen(true);
    }
  };

  const handleShareProfileClick = () => {
    const verificationStatus = localStorage.getItem('verificationStatus');
    const profileUrl = window.location.href; // Get the current URL
  
    if (verificationStatus === 'VERIFICATION_PENDING') {
      setPopupMessage('Your profile is under verification. Please wait.'); 
      setShowPopup(true);
    } else {
      // Copy the URL to the clipboard
      navigator.clipboard.writeText(profileUrl).then(() => {
        // Show a success message using the existing showToast function
        showToast('Profile URL copied to clipboard!', 'success');
      }).catch((error) => {
        console.error('Failed to copy URL:', error);
        showToast('Failed to copy URL. Please try again.', 'error');
      });
    }
  };
  
  const handleViewReviewsClick = () => {
    const uid = localStorage.getItem('uid'); // Retrieve the uid from localStorage
    if (uid) {
      navigate(`/reviews_page/${uid}`); // Navigate to the reviews page with uid
    } else {
      console.error('UID not found in local storage');
    }
  };
  const handleReportClick = () => {
    const uid = localStorage.getItem('uid'); // Retrieve uid from localStorage
    if (uid) {
      navigate(`/company_report/${uid}`); // Navigate to the report page with uid
    } else {
      console.error('UID not found in local storage');
    }
  };
  const handleFollowUpClick = async () => {
    setLoadingFollowUp(true); // Set loading state
  
    const uid = localStorage.getItem('uid'); // Retrieve uid from localStorage
  
    try {
      let response;
      if (isFollowed) {
        // If already followed, call the DELETE API (unfollow)
        response = await delete_company_bookmark(uid);
        if (response.status === 202) {
          setIsFollowed(false); // Successfully unfollowed
          showToast('Unfollowed successfully!', 'success');
        }
      } else {
        // If not followed, call the POST API (follow)
        response = await post_company_bookmark(uid);
        if (response.status === 201) {
          setIsFollowed(true); // Successfully followed
          // showToast('Followed up successfully!', 'success');
        }
      }
    } catch (error) {
      console.error('Error in follow-up/unfollow:', error);
      // showToast('An error occurred. Please try again.', 'error');
    } finally {
      setLoadingFollowUp(false); // Stop loading state
    }
  };

  const handleMessageClick = async () => {
    const currentUserUid = localStorage.getItem('company_uid');
    const otherProfileUid = localStorage.getItem('uid'); // Get the other profile's UID from localStorage
    const otherProfileName = localStorage.getItem('other_profile_name');
    const otherProfileImage = localStorage.getItem('other_profile_image');
  
    if (!otherProfileUid || !currentUserUid) {
      console.error('User UID or other profile UID is missing from localStorage.');
      showToast('Unable to start chat. Please try refreshing the page.', 'error');
      return;
    }
  
    try {
      // Check if a chat already exists
      const existingChat = await checkIfChatExists(otherProfileUid);
      
      if (existingChat) {
        // If chat exists, navigate to the existing chat
        navigate(`/direct_message/chat/${existingChat.chatId}`);
      } else {
        // If no chat exists, create a new one
        const newChatId = await createNewChat(otherProfileUid, otherProfileName, otherProfileImage);
        navigate(`/direct_message/chat/${newChatId}`);
      }
    } catch (error) {
      console.error('Error handling message click:', error);
      showToast('Failed to open chat. Please try again.', 'error');
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <div className="flex flex-col w-full h-full">
        <div
          className="relative  w-full lg:h-72 h-[272px]"
          style={{
            backgroundImage: `url(/Assets/cover_photo.svg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        > 
            <h1 className="absolute text-[#263238] font-bold font-gotham-bold lg:text-2xl text-xl p-4 z-10">
            Profile
          </h1>

          {/* Add dots container */}
          <div className="absolute top-6 right-10 lg:right-72 z-20">
            <div className="cursor-pointer" onClick={handleDotsClick}>
              <img src="/Assets/dots.svg" alt="Menu Dots" className='w-4 h-5' />
            </div>
            {/* Dots menu for report */}
            {isDotsMenuOpen && (
              <div className="absolute right-0 mt-2 w-32 bg-white rounded shadow-lg">
                <p
                  className="text-sm p-2 cursor-pointer hover:bg-gray-100"
                  onClick={handleReportClick} 
                >
                  Report
                </p>
              </div>
            )}
          </div>


          {/* Profile Image Container */}
          <div className="absolute inset-x-0 lg:mt-20 mt-16 flex justify-center lg:w-4/5">
            <ProfileImageContainer
              editable={editable}
              profileImage={selectedProfileImage}
              onSaveProfileImage={setSelectedProfileImage}
            />
          </div>
          <div className="flex flex-col items-center lg:w-4/5 lg:mt-48 mt-40 lg:mx-0 mx-5">
            <h2 className="lg:text-lg text-[15px] font-normal font-techna">{storeName}</h2>
            <p className="lg:text-sm font-normal font-gotham-Book text-[13px]">{locationText}</p>
            <div className="flex items-center space-x-2">
            <StarRating review={review} />
            {/* view reviews added */}
          
            <span
              className="text-xs lg:text-sm  text-[#DB0000] cursor-pointer font-gotham-extralight font-extralight underline"  
              onClick={handleViewReviewsClick}
            >
              View Reviews
            </span>
          </div>
          </div>
        </div>

        <div className="mt-2 text-left mx-2 lg:mx-8">
        <h2 className="text-sm lg:text-lg font-semibold mb-1 font-gotham text-[#263238]">About</h2>
        <p 
    className="text-[13px] lg:text-[16px] mb-1 text-[#6B6B6B] lg:w-4/5 w-full lg:mx-0 font-gotham"  // Set width to 75% and center the text
    style={{
      wordWrap: 'break-word', // Ensure long words break correctly
    }}>
    {bio}
  </p>
          <h3
            className="flex items-center lg:text-xs text-xs font-normal mb-1 cursor-pointer text-[#DB0000] font-gotham"
            onClick={handleWebLinkClick}
          >
            <img
              src="/Assets/web_link.svg"
              alt="Link Icon"
              className="lg:w-5 lg:h-5 mr-2 w-4 h-4"
            />
            Web Links
          </h3>
          <ul className="list-disc ml-4 text-sm">
            {webLinks.map((link, index) => (
              <li key={index}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Row of follow-up, message, and share buttons */}
        <div className="flex  lg:w-4/5 justify-center lg:space-x-2 space-x-1 mt-1  mx-4">
        <ImageButton
  onClick={handleFollowUpClick}
  imageSrc={isFollowed ? '/Assets/unfollow_new.svg' : '/Assets/follow-up.svg'} // Change icon dynamically
  buttonText={isFollowed ? 'Unfollow' : 'Follow up'} // Change text dynamically
/>

<ImageButton
          onClick={handleMessageClick} // This will handle the message initiation
          imageSrc="/Assets/message-new.svg"
          buttonText="Message"
        />

          <ImageButton
            onClick={handleShareProfileClick}
            imageSrc="/Assets/share-new.svg"
            buttonText="Share"
          />
        </div>

        {/* Product Catalogue Section */}
        {catalogue && (
          <div className="w-full lg:w-4/5 flex justify-between items-center lg:mt-1 mt-2">
            <div className="w-1/2 text-black font-semibold lg:ml-8 ml-2 text-sm lg:text-lg">
              Product Catalogue
            </div>
            <div className="w-1/2 flex justify-end">
              <div className='lg:w-1/3 w-1/2 h-1/5 mb-2 shadow-lg mr-4'>
                <Button
                  text="View"
                  backgroundColor="bg-[#ffffff]"
                  textColor="text-[#263238]"
                  onClick={handleViewCatalogue}
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full lg:w-4/5 relative p-2 lg:ml-4 lg:mt-3">
          <div className="grid grid-cols-2 lg:grid-cols-2 lg:gap-4 gap-5">
            <TabButton
              icon="/Assets/profile_management.svg"
              label="Management"
              path={() => navigateWithParam('/view-management')}
            />
            <TabButton
              icon="/Assets/profile_docs.svg"
              label="Documents"
              path={() => navigateWithParam('/company_docs_view', true)}  // Pass company_id for Documents
            />
            <TabButton
              icon="/Assets/profile_complaints.svg"
              label="Complaints"
              path="/add_complaints"

            />
            <TabButton
              icon="/Assets/profile_feedback.svg"
              label="Feedback"
              path={() => navigateWithParam('/feedback/owner')}
            />
          </div>

          {/* Centered white circle */}
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="relative lg:w-40 lg:h-40 h-25 w-25 bg-white rounded-full flex items-center justify-center">
              <div className="grid grid-cols-2 lg:gap-3 gap-2 lg:p-3 p-1">
                <img
                  src="/Assets/photos.svg"
                  alt="Photos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/view_photos')}
                />
                <img
                  src="/Assets/videos.svg"
                  alt="Videos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/uploaded_video',true)}
                />
                <img
                  src="/Assets/product.png"
                  alt="Products"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/product_service_list')}
                />
                <img
                  src="/Assets/verified-tick.svg"
                  alt="Verification"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/profile-verification')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for large screens */}
      <WebLinkModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} link={website} />

      {/* Bottom Sheet for small screens */}
      <BottomSheet isOpen={isBottomSheetOpen} onClose={() => setIsBottomSheetOpen(false)} link={website} />

      {/* Confirmation Popup */}
      <ConfirmationPopup onClose={() => setShowPopup(false)} show={showPopup} />
    </div>
  );
};

export default OtherProfileVisitorsView;