import React, { useState } from 'react';
import uploadImage from '../../Controller/firebase/firebase_upload_image';
import LoaderSpinner from './loader_spinner'; // Assuming you have a spinner component here

const Photoupload = ({
  files,
  setFiles,
  svgImagePath,
  editIconPath,
  bgColor,
  borderRadius,
  width,
  height,
  type = 'image',
}) => {
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setLoading(true);

    try {
      // Upload all selected files to Firebase and retrieve their URLs
      const uploadedFileData = await Promise.all(
        uploadedFiles.map(async (file) => {
          const downloadURL = await uploadImage(file);
          return { file, url: downloadURL };
        })
      );

      setFiles(uploadedFileData); // Update state with the uploaded file(s)
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="relative flex items-center justify-center"
      style={{
        backgroundColor: bgColor || '#C1C1C1',
        borderRadius: borderRadius || '8px',
        width: width || '130px',
        height: height || '190px',
      }}
    >
      {loading ? (
        <LoaderSpinner visible={true} /> // Show spinner while uploading
      ) : files.length > 0 ? (
        <div className="relative w-full h-full">
          {/* Display uploaded image */}
          {files.map((file, index) => (
            <img
              key={index}
              src={file.url}
              alt="upload"
              className="object-cover w-full h-full"
              style={{
                borderRadius: borderRadius || '8px',
                backgroundColor: bgColor || '#E6E6E6',
              }}
            />
          ))}

          {/* Show edit icon at the center for updating */}
          <label
            htmlFor="file-upload"
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            style={{ background: 'rgba(0,0,0,0.5)' }} // Semi-transparent overlay
          >
            <img src={editIconPath} alt="Edit" className="w-6 h-6" />
          </label>
          <input
            type="file"
            accept={type + '/*'}
            onChange={handleFileUpload}
            className="hidden"
            id="file-upload"
          />
        </div>
      ) : (
        <label
          htmlFor="file-upload"
          className="cursor-pointer flex flex-col items-center justify-center"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: bgColor || '#E6E6E6',
            borderRadius: borderRadius || '8px',
          }}
        >
          <img src={svgImagePath} alt="Upload" className="w-6 h-6 mb-2" />
         
          <input
            type="file"
            accept={type + '/*'}
            onChange={handleFileUpload}
            className="hidden"
            id="file-upload"
          />
        </label>
      )}
    </div>
  );
};

export default Photoupload;
