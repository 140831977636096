
import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import CompanyPhotos from '../../Components/company_photos';
import ShowcaseGrid from '../../Components/showcase';
import { useNavigate } from 'react-router-dom';
import VerificationPhotos from '../../Components/verification_photos';

const ViewPhotos = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate('');
    };
  
  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
    <Sidebar />
    <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
      <div className="flex items-center space-x-2">
        
       <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Post</h1>  
        </div>
      </div>
    <div className="border-b border-black mt-4 md:mt-[25px]"></div>
   

    <div className=' overflow-auto'>
    <div className="lg:w-2/5 mb-8">
          <CompanyPhotos />
        </div>
      <div className=" w-full md:w-3/4 ">
        <ShowcaseGrid />
      </div>
    </div>
  </div>
  );
};

export default ViewPhotos;

// import React from 'react';
// import Sidebar from '../../Components/main_navbar';
// import { useNavigate } from 'react-router-dom';

// const ViewPhotos = () => {
//   const navigate = useNavigate();
//   const videosAvailable = false; // Change this to true if there are videos available

//   const handleBackClick = () => {
//     navigate('');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-poppins">
//       <Sidebar />
//       <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
//         <div className="flex items-center space-x-2">
          
//           <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Post</h1>
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex flex-grow items-center justify-center lg:ml-8 mx-auto">
//         {videosAvailable ? (
//           <div className="flex lg:w-1/3 sm:w-full md:w-1/2">
//             {/* Replace with your video component */}
//           </div>
//         ) : (
//           <div className="flex  w-full h-full bg-[#F2F3F3]">
//             <h2 className="text-center mt-7 text-gray-500 text-lg md:text-xl lg:text-2xl">No Photos uploaded</h2>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ViewPhotos;
