import { api } from './api'; // Assuming you're using axios or another service for API calls
import { POST_NOTIFICATION_PREFERENCE_URL } from './urls';

const update_notification_preference = async (notify) => {
    try {
        // Pass notify as a query parameter in the URL
        const res = await api.post(`${POST_NOTIFICATION_PREFERENCE_URL}?notify=${notify}`);
        console.log("Notification Preference API Response:", res);
        return res;
    } catch (error) {
        console.error("Error updating notification preferences:", error);
        throw error;
    }
};


export { 
  
    update_notification_preference // <-- Export the new function
  };
