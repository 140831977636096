import React, { useState } from 'react';
import { FaEllipsisV, FaEnvelope } from 'react-icons/fa'; // Import three-dot icon and message icon
import { useNavigate } from 'react-router-dom';
import { categoryMapping } from './category';
import { checkIfChatExists,createNewChat } from '../../services/chatservice';

const PostDetails = ({ post, onPostDeleted, isMyPosts }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  if (!post) {
    return <div>No post details available</div>; // Safeguard against null post
  }

  const handleDelete = async () => {
    try {
      await onPostDeleted(post.id); // Call the delete function passed from PostList
      setShowMenu(false); // Hide the menu after deletion
    } catch (error) {
      console.error('Failed to delete post:', error);
    }
  };

  const handleNavigateToProfile = () => {
    if (post.company_id) {
      navigate(`/profile2/${post.company_id}`); // Navigate to the profile page with the company_uid
    }
  };

  const handleSendMessage = async () => {
    const company_uid = post.company_uid; // Assuming company_uid is available in the post
    const currentUserUid = localStorage.getItem('company_uid'); // The current logged-in user's UID

    if (!company_uid || !currentUserUid) {
      console.error('User UID or company UID is missing.');
      return;
    }

    try {
      // Check if a chat already exists between the current user and the company
      const existingChat = await checkIfChatExists(company_uid);

      if (existingChat) {
        // If the chat exists, navigate to the chat window
        navigate(`/direct_message/chat/${existingChat.chatId}`);
      } else {
        // If no chat exists, create a new one and navigate to it
        const newChatId = await createNewChat(company_uid);
        navigate(`/direct_message/chat/${newChatId}`);
      }
    } catch (error) {
      console.error('Error handling message click:', error);
    }
  };
  return (
    <div className="bg-white rounded-lg shadow-lg w-full mb-4 flex flex-col items-center">
      {/* Top section with three-dot menu and Message button */}
      <div className="relative bg-gray-100 rounded-t-lg p-4 w-full flex items-center justify-between">
        {/* Left section: Profile Image and Name */}
        <div
          className={`flex items-center ${!isMyPosts ? 'cursor-pointer' : ''}`}
          onClick={!isMyPosts ? handleNavigateToProfile : null} // Navigate to profile when not own post
        >
          <img
            src={post.profile_image_url || '/profile.webp'}
            alt="Company Logo"
            className="rounded-full w-[40px] h-[40px] mr-3"
          />
          <div>
            <h2 className="font-semibold text-lg">{post.company_name}</h2>
          </div>
        </div>

        {/* Right section: Message icon for other posts */}
        <div className="flex items-center">
          {!isMyPosts && (
            <FaEnvelope
              onClick={handleSendMessage}
              className="cursor-pointer text-gray-500 text-2xl ml-3" // Message icon styles
              title="Message"
            />
          )}

          {isMyPosts && (
            <>
              <FaEllipsisV
                className="cursor-pointer text-gray-500 text-2xl ml-3"
                onClick={() => setShowMenu(!showMenu)}
              />
              {showMenu && (
                <div className="absolute top-8 right-4 w-24 bg-white shadow-lg rounded-lg z-10">
                  <div
                    className="cursor-pointer text-sm p-2 hover:bg-gray-200"
                    onClick={handleDelete}
                  >
                    Delete
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Post details content */}
      <div className="w-full p-4">
        <div className="mt-4">
          {/* Product Name */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Product Name</span>
            <div className="text-sm font-normal text-gray-900">{post.name}</div>
          </div>

          {/* Category / Trade Role */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Trade Role</span>
            <div className="text-sm font-normal text-gray-900">
              {categoryMapping[post.category] || 'Unknown Category'} {/* Map category ID to name */}
            </div>
          </div>

          {/* Quantity */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Quantity</span>
            <div className="text-sm font-normal text-gray-900">{post.quantity}</div>
          </div>

          {/* Detailed Requirement */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Detailed Requirement</span>
            <div className="text-sm font-normal text-gray-900">{post.details || 'N/A'}</div>
          </div>
        </div>

        {/* PDF Document Section */}
        {post.detail_document && (
          <div className="mt-6 flex items-center justify-between bg-gray-100 p-4 rounded-lg">
            <div className="flex items-center">
              <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-3" />
              <span className="text-sm font-medium text-gray-700">Document</span>
            </div>
            <a
              href={post.detail_document}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-medium text-red-600 underline"
            >
              View
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostDetails;
