
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchMediaImages, deleteMediaById } from '../../Controller/company_products'; // Import deleteMediaById
import DeletePopup from './delete_pop_up';
import { FaSpinner } from 'react-icons/fa'; // Import the spinner

const ShowcaseGrid = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { uid } = useParams();
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [showDeleteOption, setShowDeleteOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);

  const fetchImages = async (uidToUse) => {
    const skip = 0;
    const limit = 10;

    try {
      const response = await fetchMediaImages(uidToUse, skip, limit);
      console.log('Images:', response);
      if (response?.data?.length > 0) {
        setItems(response.data);
      } else {
        console.error('Unexpected API response structure:', response);
        setItems([]);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching media images:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAndFetchProfileImages = async () => {
      const company_uid = localStorage.getItem('company_uid');
      const status = localStorage.getItem('verificationStatus'); 
      setVerificationStatus(status);

      if (uid) {
        setIsMyProfile(false);
        fetchImages(uid);
      } else if (company_uid) {
        setIsMyProfile(true);
        fetchImages(company_uid);
      } else {
        console.error('No uid in URL and company_uid not found in localStorage');
        setLoading(false);
      }
    };

    checkAndFetchProfileImages();
  }, [uid]);

  const handleUploadClick = () => {
    navigate('/add_product');
  };

  const handleDotClick = (index) => {
    setShowDeleteOption(index === showDeleteOption ? null : index);
  };

  const handleDeleteClick = (id) => {
    setSelectedPhotoId(id);
    setShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteMediaById(selectedPhotoId);
      if (response.status === 200) {
        console.log(response);
        setItems((prevItems) => prevItems.filter(item => item.id !== selectedPhotoId));
        setShowPopup(false);
        setShowDeleteOption(null);
      } else {
        console.error('Error deleting media:', response);
      }
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedPhotoId(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <FaSpinner className="animate-spin text-gray-500" size={20} /> {/* Centered spinner */}
      </div>
    );
  }
  // Function to truncate title
  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`; // Trim and add ellipses
    }
    return title;
  };

  return (
    <div className="container mx-auto p-4 font-poppins">
      <div className="flex justify-between items-center mb-4">
        <h2 className="lg:text-lg md:text-lg font-semibold">Product Showcase</h2>
        {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING' && (
          <img
            src="/Assets/review.svg"
            alt="Upload"
            className="cursor-pointer h-5 w-5"
            onClick={handleUploadClick}
          />
        )}
      </div>
      
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2">
        {Array.isArray(items) && items.length > 0 ? (
          items.map((item, index) => (
            <div key={index} className="relative">
              <Link to={`/detailed_view/${item.id}`} state={{ item }}>
                <div className="border rounded-lg shadow-lg">
                  {item.image_url && item.image_url.length > 0 ? (
                    <img
                      src={item.image_url[0]}
                      alt={`Showcase ${index}`}
                      className="w-full h-40 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-full h-40 flex items-center justify-center bg-gray-200">
                      No Image Available
                    </div>
                  )}
                  <p className="text-center text-sm rounded" style={{
                    background: 'linear-gradient(0deg, #000000 58.85%, rgba(255, 255, 255, 0) 100%)',
                    color: 'white', padding: '5px'
                  }}>
                  {truncateTitle(item.title, 25)} {/* Truncate title to a maximum of 25 characters */}
                  </p>
                </div>
              </Link>
              {isMyProfile && (
                <>
                  <img
                    src="/Assets/dots.svg"
                    alt="More Options"
                    className="absolute top-2 right-2 w-3 h-3 cursor-pointer"
                    onClick={() => handleDotClick(index)}
                  />
                  {showDeleteOption === index && (
                    <div
                      className="absolute top-8 right-2 bg-white text-red-600 p-2 rounded-lg shadow-md cursor-pointer"
                      onClick={() => handleDeleteClick(item.id)}
                    >
                      Delete
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500 w-full lg:mt-10">No Photos to show!</div>
        )}
      </div>

      {showPopup && (
        <DeletePopup
          message="Are you sure you want to delete this item?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default ShowcaseGrid;




