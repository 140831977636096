import React, { useState, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; 
import CompanyCard from '../../Components/comapny_card';
import CompanyHeader from '../../Components/company_header';
import Sidebar from '../../Components/main_navbar';
import { get_sub_categories_api } from '../../../Controller/sub_categories_home';
import { useParams } from 'react-router-dom';
import { categoryMapping } from '../../Components/category';

// Libraries required for Google Maps API
const libraries = ['places']; // Include 'places' library for autocomplete

const CategoryPage = ({ hideSidebar = false }) => {
  const { categoryId } = useParams();
  const [search, setSearch] = useState(''); // Search key (search_key)
  const [locationSearch, setLocationSearch] = useState(''); // Location search input
  const [lat, setLat] = useState(''); // Latitude
  const [lon, setLon] = useState(''); // Longitude
  const [companies, setCompanies] = useState([]); 
  const [loading, setLoading] = useState(false); 
  const [currentPage, setCurrentPage] = useState(0); // Pagination
  const navigate = useNavigate(); 
  const [debouncedSearch, setDebouncedSearch] = useState(search); // Debounced search state

  // Load Google Maps JavaScript API
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Use API key from .env file
    libraries, // Load the places library
  });

  // Handle input change for location search
  const handleLocationSelect = (latitude, longitude) => {
    setLat(latitude);
    setLon(longitude);
  };

  const fetchCompanies = async (page) => {
    setLoading(true);
    const skip = page * 5; // Assuming you are loading 5 companies at a time

    try {
      const response = await get_sub_categories_api(categoryId, skip, 5, lat, lon, debouncedSearch);
      
      // Filter out any duplicate companies
      const newCompanies = response.data.filter(company => !companies.some(c => c.id === company.id));

      if (page === 0) {
        setCompanies(newCompanies); // First page, replace the company list
      } else {
        setCompanies(prevCompanies => [...prevCompanies, ...newCompanies]); // Append new companies for pagination
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };

  // Debounce the search query
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search); // Update the debounced search after 300ms
    }, 300); // Set the debounce delay

    return () => clearTimeout(timer); // Cleanup the timeout if the user types again before the delay
  }, [search]); // Trigger when the search changes

  // Fetch companies based on the debounced search
  useEffect(() => {
    setCompanies([]); // Reset the companies list
    setCurrentPage(0); // Reset pagination
    fetchCompanies(0); // Fetch companies based on the debounced search
  }, [categoryId, lat, lon, debouncedSearch]); // Run only when debouncedSearch changes

  // Function to load the next set of companies (pagination)
  const loadMoreCompanies = () => {
    if (!loading) {
      setCurrentPage(prevPage => {
        const nextPage = prevPage + 1;
        fetchCompanies(nextPage);
        return nextPage;
      });
    }
  };

  // Check if Google Maps API has loaded
  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div className="h-full lg:w-full flex flex-col bg-[#FBFCF8]">
      <h1 className="text-2xl font-semibold leading-tight" style={{ color: '#171717', marginTop: '2rem', marginLeft: '2rem' }}>
        {categoryMapping[categoryId] || 'Category'} {/* Display category label */}
      </h1>
      
      <CompanyHeader setSearch={setSearch} toggleMobileMenu={() => {}} hideLogo hideFilterIcon onLocationSelect={handleLocationSelect} />

      {/* Location input and suggestions */}
      <div className="flex flex-col md:flex-row w-full mt-4 md:mt-0 overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col lg:w-4/5 w-full p-2 space-y-6 mr-16 overflow-y-auto">
          <div className="space-y-6 mt-4  md:px-8 flex-1 overflow-x-hidden">
            <div className="flex flex-col items-center md:items-center md:w-1/2 md:mx-auto overflow-y-auto overflow-x-hidden">
              {companies.length === 0 && !loading ? (
                 <div className="flex justify-center items-center mt-40    ">
                 <p className="text-center font-poppins text-gray-500">No companies to show!.</p>
               </div>
              ) : (
                companies.map(company => (
                  <div
                    key={company.id}
                    className="w-full md:w-full p-2"
                    onClick={() => navigate(`/profile2/${company.id}`)}
                  >
                    <CompanyCard company={company} />
                  </div>
                ))
              )}

              {loading && (
                <div className="flex justify-center my-4">
                  <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!hideSidebar && (
        <div className="md:block w-64 p-4">
          <Sidebar />
        </div>
      )}
    </div>
  );
};

export default CategoryPage;
