// import React, { useState, useEffect, useRef } from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import SharePopup from '../../Components/share_alert';
// import VerifiedPopup from '../../Components/pop_up';
// import Sidebar from '../../Components/main_navbar';
// import { get_all_User_video } from '../../../Controller/upload_videos';
// import { FaSpinner } from 'react-icons/fa'; // Importing the spinner icon
// import { post_company_bookmark,delete_company_bookmark } from '../../../Controller/bookmarks';

// const Video = () => {
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const videoUrl = queryParams.get('video');

//     const [videos, setVideos] = useState([]); // State to store videos
//     const [skip, setSkip] = useState(0); // For pagination
//     const limit = 5; // Limit to 5 videos per fetch
//     const [loading, setLoading] = useState(false); // Loading state for spinner
//     const [hasMore, setHasMore] = useState(true); // To know if there are more videos to load
//     const [showSharePopup, setShowSharePopup] = useState(false);
//     const [showVerifiedPopup, setShowVerifiedPopup] = useState(false);
//     const [showReportOptions, setShowReportOptions] = useState(false);
//     const [reportSelected, setReportSelected] = useState(false);

//     const videoContainerRef = useRef(null); // Ref for scrolling

//     // Fetch videos from API with pagination
//     const fetchVideos = async () => {
//         if (loading || !hasMore) return; // Prevent fetching if already loading or no more videos
//         setLoading(true);

//         console.log("Fetching videos with skip:", skip, "and limit:", limit); // Log skip and limit
//         try {
//             const res = await get_all_User_video(skip, limit); // API call
//             console.log("API Response:", res); // Log API response

//             if (res.status === 200 && res.data.data.length > 0) {
//                 const newVideos = res.data.data;

//                 // Use a functional update to ensure we append new videos correctly
//                 setVideos((prevVideos) => [...prevVideos, ...newVideos]);


//                 // Check if less than limit videos are returned, indicating no more videos
//                 if (newVideos.length < limit) {
//                     setHasMore(false); // No more videos to load
//                 }
//             } else {
//                 setHasMore(false); // No more videos to load
//             }
//         } catch (error) {
//             console.error('Error fetching videos:', error);
//         } finally {
//             setLoading(false);
//             console.log('Total videos after fetching:', videos.length); // Debugging total videos
//         }
//     };

//     // Load the first set of videos on component mount
//     useEffect(() => {
//         fetchVideos(); // Fetch the first set of videos on mount
//     }, [skip]); // Triggered every time skip changes

//     // Infinite scroll: load more when user scrolls near the bottom
//     const handleScroll = () => {
//         if (videoContainerRef.current) {
//             const { scrollTop, scrollHeight, clientHeight } = videoContainerRef.current;
//             // When user scrolls close to the bottom
//             if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && hasMore) {
//                 console.log('Reached bottom, fetching more videos...');
//                 setSkip((prevSkip) => prevSkip + limit); // Increment skip for the next batch
//             }
//         }
//     };

//     const handleBookmarkClick = async (video, index) => {
//         try {
//             if (video.bookmark) {
//                 // Call delete bookmark API
//                 const response = await delete_company_bookmark(video.company_uid);
//                 if (response.status === 202) {
//                     console.log('Bookmark deleted successfully');
//                     updateVideoBookmark(index, false); // Update the bookmark state
//                 }
//             } else {
//                 // Call post bookmark API
//                 const response = await post_company_bookmark(video.company_uid);
//                 if (response.status === 201) {
//                     console.log('Bookmarked successfully');
//                     updateVideoBookmark(index, true); // Update the bookmark state
//                 }
//             }
//         } catch (error) {
//             console.error('Error updating bookmark:', error);
//         }
//     };

//     const updateVideoBookmark = (index, isBookmarked) => {
//         setVideos((prevVideos) => {
//             const updatedVideos = [...prevVideos];
//             updatedVideos[index] = { ...updatedVideos[index], bookmark: isBookmarked };
//             return updatedVideos;
//         });
//     };

//     useEffect(() => {
//         if (videoContainerRef.current) {
//             videoContainerRef.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (videoContainerRef.current) {
//                 videoContainerRef.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [loading, hasMore]); // Re-run when loading or hasMore changes

//     const togglePlayPause = (videoRef) => {
//         if (videoRef.paused) {
//             videoRef.play();
//         } else {
//             videoRef.pause();
//         }
//     };

//     const handleShareClick = () => setShowSharePopup(true);
//     const handleCloseSharePopup = () => setShowSharePopup(false);
//     const handleVerifiedClick = () => setShowVerifiedPopup(true);
//     const handleCloseVerifiedPopup = () => setShowVerifiedPopup(false);
//     const toggleReportOptions = () => setShowReportOptions(!showReportOptions);
//     const handleReportClick = () => setReportSelected(!reportSelected);

//     return (
//         <div className="flex w-full h-full bg-black">
//             <Sidebar />
//             <div className="flex-1 flex flex-col overflow-y-auto" ref={videoContainerRef}>
//                 {/* Discover Section */}
//                 <div className="sticky top-0 w-full bg-black z-10 p-4">
//                     {/* Sticky header to stay on top */}
//                     <div className="flex items-center">
//                         <div className="w-14 h-15 bg-white rounded-md p-1">
//                             <Link to="/discover-page">
//                                 <img src="/discover-box.svg" alt="Discover" className="w-12 h-12" />
//                             </Link>
//                         </div>
//                         <h1 className="text-white font-bold text-2xl ml-4">Discover</h1>
//                     </div>
//                     <div className="border-b border-gray-900 mt-2"></div> {/* Border below Discover */}
//                 </div>

//                 {/* Display all videos below the Discover heading */}
//                 <div className="p-2 flex flex-col items-center space-y-4">
//                     {videos.map((video, index) => (
//                         <div key={index} className="relative">
//                             <video
//                                 src={video.video_url}
//                                 className="rounded-lg"
//                                 style={{ width: '350px', height: '540px', objectFit: 'cover' }}
//                                 onClick={(e) => togglePlayPause(e.target)}
//                             />
//                             {/* Displaying icons */}
//                             <div className="absolute top-24 mt-56 right-2 flex flex-col space-y-7">
//                                 <img
//                                     src={video.bookmark ? "/Assets/bookmarked_follow.svg" : "/Assets/bookmarked_unfollow.svg"} // Conditional image based on bookmark state
//                                     alt="Bookmark"
//                                     className="w-6 h-6 cursor-pointer"
//                                     onClick={() => handleBookmarkClick(video, index)}
//                                 />
//                                 <img 
//                                     src="/follow.svg" 
//                                     alt="Follow" 
//                                     className="w-6 h-6" 
//                                 />
//                                 <img 
//                                     src="/share.svg" 
//                                     alt="Share" 
//                                     className="w-6 h-6 cursor-pointer" 
//                                     onClick={handleShareClick} 
//                                 />
//                             </div>

//                             {/* Show Report options when report icon is clicked */}
//                             {showReportOptions && (
//                                 <div className="absolute top-24 mt-56 right-10 w-40 bg-gray-800 p-2 rounded-md">
//                                     <p 
//                                         className={`text-sm cursor-pointer p-2 rounded-md ${reportSelected ? 'text-red-500' : 'text-white'} hover:text-red-500 hover:bg-gray-700`} 
//                                         onClick={handleReportClick}
//                                     >
//                                         Report
//                                     </p>
//                                     <p className="text-sm text-white cursor-pointer p-2 rounded-md hover:text-red-500 hover:bg-gray-700">Follow</p>
//                                 </div>
//                             )}

//                             {/* Use default image if logo not available */}
//                             <div className="absolute inset-x-0 top-10 bottom-0 w-full bg-opacity-70 p-3 rounded-b-lg mt-[320px]">
//                                 <div className="flex items-center space-x-2 mb-1">
//                                     <img 
//                                         src={video.logo || './profile.webp'} 
//                                         alt="ID" 
//                                         className="w-7 h-7 rounded-full" 
//                                     />
//                                     <p className="text-white">{video.title}</p>
//                                     {video.verified && (
//                                         <img
//                                             src="/tick.svg"
//                                             alt="Verified"
//                                             className="w-4 h-4 cursor-pointer"
//                                             onClick={handleVerifiedClick}
//                                         />
//                                     )}
//                                 </div>
//                                 <p className="text-sm text-white pr-5">
//                                     {video.description.length > 100
//                                         ? `${video.description.substring(0, 100)}...`
//                                         : video.description}
//                                 </p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//                 {/* Show loading spinner */}
//                 {loading && (
//     <div className="flex justify-center items-center mt-4 text-white h-screen">
//         <FaSpinner className="animate-spin" size="2em" />
//     </div>
// )}

//                 {showSharePopup && <SharePopup onClose={handleCloseSharePopup} />}
//                 {showVerifiedPopup && <VerifiedPopup onClose={handleCloseVerifiedPopup} />}
//             </div>
//         </div>
//     );
// };

// export default Video;
// import React, { useState, useEffect, useRef } from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import SharePopup from '../../Components/share_alert';
// import VerifiedPopup from '../../Components/pop_up';
// import Sidebar from '../../Components/main_navbar';
// import { get_all_User_video, get_user_video_api } from '../../../Controller/upload_videos'; // Import your API functions
// import { FaSpinner } from 'react-icons/fa'; // Importing the spinner icon
// import { post_company_bookmark, delete_company_bookmark } from '../../../Controller/bookmarks';
// import { useNavigate } from 'react-router-dom';
// import { showToast } from '../../Components/toast';

// const Video = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const videoUrl = queryParams.get('video');

//     const { state } = location; 
//     const selectedVideoId = state?.videoId; // Retrieve videoId from state if navigating from Discover

//     const [videos, setVideos] = useState([]); // State to store videos
//     const [skip, setSkip] = useState(0); // For pagination
//     const limit = 5; // Limit to 5 videos per fetch
//     const [loading, setLoading] = useState(false); // Loading state for spinner
//     const [hasMore, setHasMore] = useState(true); // To know if there are more videos to load
//     const [showSharePopup, setShowSharePopup] = useState(false);
//     const [showVerifiedPopup, setShowVerifiedPopup] = useState(false);
//     const [showReportOptions, setShowReportOptions] = useState(false);
//     const [reportSelected, setReportSelected] = useState(false);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State for the selected video

//     const videoContainerRef = useRef(null); // Ref for scrolling

//     // Fetch the selected video if videoId is present
//     useEffect(() => {
//         if (selectedVideoId) {
//             const fetchSelectedVideo = async () => {
//                 try {
//                     const fetchedVideo = await get_user_video_api(selectedVideoId); // Fetch the video details by ID
//                     setSelectedVideo(fetchedVideo);
//                 } catch (error) {
//                     console.error('Error fetching the selected video:', error);
//                 }
//             };
//             fetchSelectedVideo();
//         }
//     }, [selectedVideoId]);

//     // Fetch videos from API with pagination
//     const fetchVideos = async () => {
//         if (loading || !hasMore) return; // Prevent fetching if already loading or no more videos
//         setLoading(true);

//         try {
//             const res = await get_all_User_video(skip, limit); // API call
//             console.log("videos",res)
//             if (res.status === 200 && res.data.data.length > 0) {
//                 const newVideos = res.data.data;

//                 setVideos((prevVideos) => [...prevVideos, ...newVideos]);

//                 // Check if less than limit videos are returned, indicating no more videos
//                 if (newVideos.length < limit) {
//                     setHasMore(false); // No more videos to load
//                 }
//             } else {
//                 setHasMore(false); // No more videos to load
//             }
//         } catch (error) {
//             console.error('Error fetching videos:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Load the first set of videos on component mount
//     useEffect(() => {
//         fetchVideos(); // Fetch the first set of videos on mount
//     }, [skip]); // Triggered every time skip changes

//     // Infinite scroll: load more when user scrolls near the bottom
//     const handleScroll = () => {
//         if (videoContainerRef.current) {
//             const { scrollTop, scrollHeight, clientHeight } = videoContainerRef.current;
//             if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && hasMore) {
//                 setSkip((prevSkip) => prevSkip + limit); // Increment skip for the next batch
//             }
//         }
//     };

//     useEffect(() => {
//         if (videoContainerRef.current) {
//             videoContainerRef.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (videoContainerRef.current) {
//                 videoContainerRef.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [loading, hasMore]); 

//     const handleBookmarkClick = async (video, index) => {
//         try {
//             if (video.bookmark) {
//                 const response = await delete_company_bookmark(video.company_uid);
//                 if (response.status === 202) {
//                     updateVideoBookmark(index, false);
//                 }
//             } else {
//                 const response = await post_company_bookmark(video.company_uid);
//                 if (response.status === 201) {
//                     updateVideoBookmark(index, true);
//                 }
//             }
//         } catch (error) {
//             console.error('Error updating bookmark:', error);
//         }
//     };

//     const updateVideoBookmark = (index, isBookmarked) => {
//         setVideos((prevVideos) => {
//             const updatedVideos = [...prevVideos];
//             updatedVideos[index] = { ...updatedVideos[index], bookmark: isBookmarked };
//             return updatedVideos;
//         });
//     };

//     const togglePlayPause = (videoRef) => {
//         if (videoRef.paused) {
//             videoRef.play();
//         } else {
//             videoRef.pause();
//         }
//     };

//     const handleShareClick = (company_uid) => {
//         console.log("Share icon clicked for company UID:", company_uid); // Log the company UID
//         // Navigate to direct_message page with company_uid
//         if (company_uid) {
//             navigate(`/direct_message/${company_uid}`);
//             console.log("Navigating to:", `/direct_message?company_uid=${company_uid}`); // Log the navigation URL
//         } else {
//             console.error("No company UID provided for sharing."); // Log error if company_uid is undefined
//         }
//     };
//     const handleCopyUrlToClipboard = (videoId) => {
//         const videoUrl = `${window.location.origin}/videos/${videoId}`; // Construct the URL with video ID
//         navigator.clipboard.writeText(videoUrl).then(() => {
//             console.log(`Copied to clipboard: ${videoUrl}`);
//             showToast('Video URL copied to clipboard!', 'success'); // Show success toast
//         }).catch((error) => {
//             console.error('Failed to copy the URL to clipboard:', error);
           
//         });
//     };
//     const handleCloseSharePopup = () => setShowSharePopup(false);
//     const handleVerifiedClick = () => setShowVerifiedPopup(true);
//     const handleCloseVerifiedPopup = () => setShowVerifiedPopup(false);
//     const toggleReportOptions = () => setShowReportOptions(!showReportOptions);
//     const handleReportClick = () => setReportSelected(!reportSelected);

//     return (
//         <div className="flex w-full h-full bg-black">
//         <Sidebar />
//         <div className="flex-1 flex flex-col overflow-y-auto" ref={videoContainerRef}>
//             {/* Discover Section */}
//             <div className="sticky top-0 w-full bg-black z-10 p-4">
//                 <div className="flex items-center">
//                     <div className="w-14 h-15 bg-white rounded-md p-1">
//                         <Link to="/discover-page">
//                             <img src="/discover-box.svg" alt="Discover" className="w-12 h-12" />
//                         </Link>
//                     </div>
//                     <h1 className="text-white font-bold text-2xl ml-4">Discover</h1>
//                 </div>
//                 <div className="border-b border-gray-900 mt-2"></div>
//             </div>

//             {/* Display selected video first if present */}
//             {selectedVideo && (
//                     <div className="p-2 flex flex-col items-center space-y-4 mb-4 relative ">
//                         <div className="relative" style={{ width: '350px', height: '540px' }}>
//                             <video
//                                 src={selectedVideo.video_url}
//                                 className="rounded-lg w-full h-full object-cover"
//                                 onClick={(e) => togglePlayPause(e.target)}
//                             />
//                             <div className="absolute inset-x-0 bottom-0 p-3 bg-gradient-to-t from-black to-transparent">
//                                 <div className="flex items-start justify-between">
//                                     {/* Left side for title and description */}
//                                     <div className="flex items-start space-x-2">
//                                         <img 
//                                             src={selectedVideo.logo || './profile.webp'} 
//                                             alt="ID" 
//                                             className="w-7 h-7 rounded-full mt-1"
//                                         />
//                                         <div className="text-white">
//                                             <p className="font-bold text-lg">{selectedVideo.title}</p>
//                                             <p className="text-sm text-gray-300">
//                                                 {selectedVideo.description.length > 100
//                                                     ? `${selectedVideo.description.substring(0, 100)}...`
//                                                     : selectedVideo.description}
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* Right side for icons (bookmark, follow, share) */}
//                             <div className="absolute right-3 bottom-20 flex flex-col space-y-4">
//                                 <img
//                                     src={selectedVideo.bookmark ? "/Assets/bookmarked_follow.svg" : "/Assets/bookmarked_unfollow.svg"}
//                                     alt="Bookmark"
//                                     className="w-6 h-6 cursor-pointer"
//                                     onClick={() => handleBookmarkClick(selectedVideo, 0)}
//                                 />
//                                 <img src="/follow.svg" alt="Follow" className="w-6 h-6" />
//                                 <img 
//                                     src="/share.svg" 
//                                     alt="Share" 
//                                     className="w-6 h-6 cursor-pointer"
//                                     onClick={() => handleShareClick(selectedVideo.company_uid)}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 )}


//             {/* Display all videos below the Discover heading */}
//             <div className="p-2 flex flex-col items-center space-y-4">
//                     {videos.map((video, index) => (
//                         <div key={index} className="relative" style={{ width: '350px', height: '540px' }}>
//                             <video
//                                 src={video.video_url}
//                                 className="rounded-lg w-full h-full object-cover"
//                                 onClick={(e) => togglePlayPause(e.target)}
//                             />
//                             <div className="absolute inset-x-0 bottom-0 p-3 bg-gradient-to-t from-black to-transparent">
//                                 {/* Left side for title and description */}
//                                 <div className="flex items-start space-x-2">
//                                     <img 
//                                         src={video.logo || './profile.webp'} 
//                                         alt="ID" 
//                                         className="w-7 h-7 rounded-full mt-1"
//                                     />
//                                     <div className="text-white">
//                                         <p className="font-bold text-lg">{video.title}</p>
//                                         <p className="text-sm text-gray-300">
//                                             {video.description.length > 100
//                                                 ? `${video.description.substring(0, 100)}...`
//                                                 : video.description}
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* Right side for icons (bookmark, follow, share) */}
//                             <div className="absolute right-3 bottom-20 flex flex-col space-y-4">
//                                 <img
//                                     src={video.bookmark ? "/Assets/bookmarked_follow.svg" : "/Assets/bookmarked_unfollow.svg"}
//                                     alt="Bookmark"
//                                     className="w-6 h-6 cursor-pointer"
//                                     onClick={() => handleBookmarkClick(video, index)}
//                                 />
//                               <img src="/follow.svg" alt="Follow" className="w-6 h-6 cursor-pointer" 
//                 onClick={() => handleCopyUrlToClipboard(video.id)} // Call the copy function on click
//             />
//                                 <img 
//                                     src="/share.svg" 
//                                     alt="Share" 
//                                     className="w-6 h-6 cursor-pointer" 
//                                     onClick={() => handleShareClick(video.company_uid)} 
//                                 />
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//             {loading && (
//                 <div className="flex justify-center items-center mt-4 text-white h-screen">
//                     <FaSpinner className="animate-spin" size="2em" />
//                 </div>
//             )}

//             {showSharePopup && <SharePopup onClose={handleCloseSharePopup} />}
//             {showVerifiedPopup && <VerifiedPopup onClose={handleCloseVerifiedPopup} />}
//         </div>
//     </div>
// );
// };



// export default Video;
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SharePopup from '../../Components/share_alert';
import VerifiedPopup from '../../Components/pop_up';
import Sidebar from '../../Components/main_navbar';
import { get_all_User_video, get_user_video_api } from '../../../Controller/upload_videos'; 
import { FaSpinner } from 'react-icons/fa'; 
import { post_company_bookmark, delete_company_bookmark } from '../../../Controller/bookmarks';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import { checkIfChatExists,createNewChat } from '../../../services/chatservice';

const Video = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const videoUrl = queryParams.get('video');

    const { state } = location;
    const selectedVideoId = state?.videoId; 

    const [videos, setVideos] = useState([]); 
    const [skip, setSkip] = useState(0); 
    const limit = 5; 
    const [loading, setLoading] = useState(false); 
    const [hasMore, setHasMore] = useState(true); 
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showVerifiedPopup, setShowVerifiedPopup] = useState(false);
    const [showReportOptions, setShowReportOptions] = useState(false);
    const [reportSelected, setReportSelected] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null); 

    const videoRefs = useRef([]); // Ref for each video
    const videoContainerRef = useRef(null); 

    // Fetch the selected video if videoId is present
    useEffect(() => {
        if (selectedVideoId) {
            const fetchSelectedVideo = async () => {
                try {
                    const fetchedVideo = await get_user_video_api(selectedVideoId);
                    console.log(fetchedVideo)
                    setSelectedVideo(fetchedVideo);
                } catch (error) {
                    console.error('Error fetching the selected video:', error);
                }
            };
            fetchSelectedVideo();
        }
    }, [selectedVideoId]);

    // Fetch videos from API with pagination
    const fetchVideos = async () => {
        if (loading || !hasMore) return; 
        setLoading(true);

        try {
            const res = await get_all_User_video(skip, limit);
            console.log('all videos', res)
            if (res.status === 200 && res.data.data.length > 0) {
                const newVideos = res.data.data;
                setVideos((prevVideos) => [...prevVideos, ...newVideos]);

                if (newVideos.length < limit) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            setLoading(false);
        }
    };

    // Load the first set of videos on component mount
    useEffect(() => {
        fetchVideos(); 
    }, [skip]); 

    // Infinite scroll: load more when user scrolls near the bottom
    const handleScroll = () => {
        if (videoContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = videoContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && hasMore) {
                setSkip((prevSkip) => prevSkip + limit); 
            }
        }
    };

    useEffect(() => {
        if (videoContainerRef.current) {
            videoContainerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (videoContainerRef.current) {
                videoContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading, hasMore]);

    // Intersection Observer for auto-playing videos
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Video will play when 50% visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const videoElement = entry.target;
                if (entry.isIntersecting) {
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            });
        }, observerOptions);

        videoRefs.current.forEach((video) => {
            if (video) observer.observe(video);
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) observer.unobserve(video);
            });
        };
    }, [videos]);

    const handleBookmarkClick = async (video, index) => {
        try {
            if (video.bookmark) {
                const response = await delete_company_bookmark(video.company_uid);
                if (response.status === 202) {
                    updateVideoBookmark(index, false);
                }
            } else {
                const response = await post_company_bookmark(video.company_uid);
                if (response.status === 201) {
                    updateVideoBookmark(index, true);
                }
            }
        } catch (error) {
            console.error('Error updating bookmark:', error);
        }
    };

    const updateVideoBookmark = (index, isBookmarked) => {
        setVideos((prevVideos) => {
            const updatedVideos = [...prevVideos];
            updatedVideos[index] = { ...updatedVideos[index], bookmark: isBookmarked };
            return updatedVideos;
        });
    };

    const togglePlayPause = (videoRef) => {
        if (videoRef.paused) {
            videoRef.play();
        } else {
            videoRef.pause();
        }
    };
// When clicking the share button for a selected video
const handleShareClick = async (company_uid) => {
    if (!company_uid) {
        console.error("No company UID provided for sharing.");
        return;
    }

    try {
        // Check if a chat with this company_uid already exists
        const chatExists = await checkIfChatExists(company_uid); // Use company_uid here as the other profile ID
        
        if (chatExists) {
            // If the chat exists, navigate to the existing chat window
            navigate(`/direct_message/chat/${chatExists.chatId}`);
        } else {
            // If no chat exists, create a new chat
            const newChatId = await createNewChat(company_uid); // Use company_uid as the other profile ID
            if (newChatId) {
                navigate(`/direct_message/chat/${newChatId}`); // Navigate to the new chat window
            } else {
                console.error('Failed to create a new chat.');
            }
        }
    } catch (error) {
        console.error('Error handling share click:', error);
    }
};


    // const handleCopyUrlToClipboard = (videoId) => {
    //     const videoUrl = `${window.location.origin}/videos/${videoId}`;
    //     navigator.clipboard.writeText(videoUrl).then(() => {
    //         showToast('Video URL copied to clipboard!', 'success');
    //     }).catch((error) => {
    //         console.error('Failed to copy the URL to clipboard:', error);
    //     });
    // };
    const handleCopyUrlToClipboard = (videoId) => {
        // Change the URL to match the actual route used in your app
        const videoUrl = `${window.location.origin}/explore-all-video?video=${videoId}`;
        navigator.clipboard.writeText(videoUrl).then(() => {
            showToast('Video URL copied to clipboard!', 'success');
        }).catch((error) => {
            console.error('Failed to copy the URL to clipboard:', error);
        });
    };
    
    const handleCloseSharePopup = () => setShowSharePopup(false);
    const handleVerifiedClick = () => setShowVerifiedPopup(true);
    const handleCloseVerifiedPopup = () => setShowVerifiedPopup(false);
    const toggleReportOptions = () => setShowReportOptions(!showReportOptions);
    const handleReportClick = () => setReportSelected(!reportSelected);

    const handleProfileClick = (company_id) => {
        if (company_id) {
            navigate(`/profile2/${company_id}`);
        } else {
            console.error("No company UID provided for navigation.");
        }
    };

    return (
        <div className="flex w-full h-full bg-black">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-y-auto" ref={videoContainerRef}>
                <div className="sticky top-0 w-full bg-black z-10 p-4">
                    <div className="flex items-center">
                        <div className="w-14 h-15 bg-white rounded-md p-1">
                            <Link to="/discover-page">
                                <img src="/discover-box.svg" alt="Discover" className="w-12 h-12" />
                            </Link>
                        </div>
                        <h1 className="text-white font-bold text-2xl ml-4">Discover</h1>
                    </div>
                    <div className="border-b border-gray-900 mt-2"></div>
                </div>

                {/* Display selected video first if present */}
                {selectedVideo && (
                    <div className="p-2 flex flex-col items-center space-y-4 mb-4 relative">
                        <div className="relative" style={{ width: '350px', height: '85vh' }}>
                            <video
                                src={selectedVideo.video_url}
                                className="rounded-lg w-full h-full object-cover"
                                onClick={(e) => togglePlayPause(e.target)}
                                ref={(el) => (videoRefs.current[0] = el)} // Add ref to the video
                            />
                            <div className="absolute inset-x-0 bottom-0 p-3 bg-gradient-to-t from-black to-transparent">
                                <div className="flex items-start justify-between">
                                    <div className="flex items-start space-x-2">
                                        <img 
                                            src={selectedVideo.logo || './profile.webp'} 
                                            alt="ID" 
                                            className="w-7 h-7 rounded-full mt-1 cursor-pointer"
                                            onClick={() => handleProfileClick(selectedVideo.company_uid)} // Navigate on click
                                        />
                                        <div className="text-white">
                                        <p className="text-lg font-normal ">{selectedVideo.company_name}</p>
                                            <p className="font-bold text-lg">{selectedVideo.title}</p>
                                            <p className="text-sm text-gray-300">
                                                {selectedVideo.description.length > 100
                                                    ? `${selectedVideo.description.substring(0, 100)}...`
                                                    : selectedVideo.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute right-3 bottom-20 flex flex-col space-y-4">
                                <img
                                    src={selectedVideo.bookmark ? "/Assets/bookmarked_follow.svg" : "/Assets/bookmarked_unfollow.svg"}
                                    alt="Bookmark"
                                    className="w-6 h-6 cursor-pointer"
                                    onClick={() => handleBookmarkClick(selectedVideo, 0)}
                                />
                                <img src="/follow.svg" alt="Follow" className="w-6 h-6" />
                                <img 
                                    src="/share.svg" 
                                    alt="Share" 
                                    className="w-6 h-6 cursor-pointer"
                                    onClick={() => handleShareClick(selectedVideo.company_uid)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="p-1 flex flex-col items-center space-y-4">
                    {videos.map((video, index) => (
                        <div key={index} className="relative" style={{ width: '350px', height: '85vh' }}>
                            <video
                                src={video.video_url}
                                className="rounded-lg w-full h-full object-cover"
                                onClick={(e) => togglePlayPause(e.target)}
                                ref={(el) => (videoRefs.current[index + 1] = el)} // Add ref to the video
                            />
                            <div className="absolute inset-x-0 bottom-0 p-3 bg-gradient-to-t from-black to-transparent">
                                <div className="flex items-start space-x-2">
                                    <img 
                                        src={video.logo || './profile.webp'} 
                                        alt="ID" 
                                        className="w-7 h-7 rounded-full mt-1 cursor-pointer"
                                        onClick={() => handleProfileClick(video.company_id)} // Navigate on click
                                    />
                                    <div className="text-white">
                                    <p className="text-lg font-normal ">{video.company_name}</p> {/* Display company name */}
                                        <p className="font-bold text-sm">{video.title}</p>
                                        <p className="text-sm text-gray-300">
                                            {video.description.length > 100
                                                ? `${video.description.substring(0, 100)}...`
                                                : video.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute right-3 bottom-20 flex flex-col space-y-4">
                                <img
                                    src={video.bookmark ? "/Assets/bookmarked_follow.svg" : "/Assets/bookmarked_unfollow.svg"}
                                    alt="Bookmark"
                                    className="w-6 h-6 cursor-pointer"
                                    onClick={() => handleBookmarkClick(video, index)}
                                />
                                <img src="/follow.svg" alt="Follow" className="w-6 h-6 cursor-pointer" 
                                    onClick={() => handleCopyUrlToClipboard(video.id)} 
                                />
                                <img 
                                    src="/share.svg" 
                                    alt="Share" 
                                    className="w-6 h-6 cursor-pointer" 
                                    onClick={() => handleShareClick(video.company_uid)} 
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {loading && (
                    <div className="flex justify-center items-center mt-4 text-white h-screen">
                        <FaSpinner className="animate-spin" size="2em" />
                    </div>
                )}

                {showSharePopup && <SharePopup onClose={handleCloseSharePopup} />}
                {showVerifiedPopup && <VerifiedPopup onClose={handleCloseVerifiedPopup} />}
            </div>
        </div>
    );
};

export default Video;
