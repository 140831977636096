// import React, { useState, useRef, useEffect } from 'react';

// function LogoUploader({ imageUrl, onUpload, isEditable }) {
//   const [logo, setLogo] = useState(imageUrl || null);
//   const fileInputRef = useRef(null);

//   useEffect(() => {
//     setLogo(imageUrl);
//   }, [imageUrl]);

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setLogo(reader.result);
//         if (onUpload) {
//           onUpload(file); // Call the onUpload callback with the file
//         }
//       };
//       reader.readAsDataURL(file);
//     }
//   };

  
//   return (
//     <div className="flex flex-col items-center">
//       <div
//         className="  bg-gray-300 rounded-full flex items-center justify-center relative cursor-pointer"
        
//         style={{ width: '120px', height: '120px' }}
//       >
//         {logo ? (
//           <img
//             src={logo}
//             alt="Company Logo"
//             className="w-full h-full rounded-full object-cover"
//           />
//         ) : (
//           <img
//             src="Assets/grey_plus.svg"
//             alt="Plus Icon"
//             className="w-6 h-6"
//           />
//         )}
//         {isEditable && (
//           <img
//             src="Assets/edit.svg"
//             alt="Edit Icon"
//             className="w-6 h-6 absolute"
//           />
//         )}
//         <input
//           type="file"
//           ref={fileInputRef}
//           accept="image/*"
//           className="absolute w-full h-full opacity-0 cursor-pointer"
//           onChange={handleImageUpload}
//         />
//       </div>
//     </div>
//   );
// }

// export default LogoUploader;
import React, { useState, useRef } from 'react';
import { FaEdit, FaSpinner } from 'react-icons/fa';

function LogoUploader({ logo, onUpload, isEditable = true, error, onError }) {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          if (onUpload) {
            await onUpload(file, reader.result);
          }
          setIsLoading(false);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Error uploading image:', error);
        if (onError) {
          onError('Failed to upload image.');
        }
        setIsLoading(false);
      }
    }
  };

  const triggerFileInput = (e) => {
    // Guard to ensure this function only triggers on a user event
    if (e?.type !== 'click') {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    console.log("File input triggered by user interaction"); // Log when this is triggered by user interaction
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageError = () => {
    if (onError) {
      onError("Failed to load image.");
    }
  };

  console.log("LogoUploader rendered"); // Log when the component renders

  return (
    <div className="flex flex-col items-center">
      <div
        className="bg-gray-300 rounded-full flex items-center justify-center relative overflow-hidden"
        style={{ width: '120px', height: '120px' }}
      >
        {isLoading ? (
          <FaSpinner className="text-white w-8 h-8 animate-spin" />
        ) : logo ? (
          <>
            <img
              src={logo}
              alt="Company Logo"
              className="w-full h-full rounded-full object-cover"
              onError={handleImageError}
            />
            {isEditable && (
              <div 
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full cursor-pointer"
                onClick={triggerFileInput} // Ensure this is only called on user interaction
              >
                <img
                 src="/Assets/edit.svg"
                 alt="Edit"
                 className="w-5 h-5 lg:w-6 lg:h-6 cursor-pointer"
                
               />
              </div>
            )}
          </>
        ) : (
          <>
            {!isLoading && (
              <img
                src="/Assets/grey_plus.svg"
                alt="Plus Icon"
                className="absolute w-6 h-6 cursor-pointer"
                onClick={isEditable ? triggerFileInput : undefined} // Ensure this is only called on user interaction
              />
            )}
          </>
        )}
        {isEditable && (
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageUpload}
          />
        )}
      </div>
      {/* {error && <p className="text-red-500 text-[10px] mt-2">{error}</p>} */}
    </div>
  );
}

export default LogoUploader;
