// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { db } from '../../../firebase_service';
// import Sidebar from '../../Components/main_navbar';
// import ChatWindow from '../../Components/chatmsg_ex'; // Import the ChatWindow component

// const ImportantMessages = () => {
//   const [importantMessages, setImportantMessages] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [selectedMessage, setSelectedMessage] = useState(null); // State for selected message
//   const navigate = useNavigate();

//   useEffect(() => {
//     const userId = localStorage.getItem('userId');
//     if (userId) {
//       setCurrentUser({ id: userId });
//     } else {
//       navigate('/login');
//     }
//   }, [navigate]);

//   useEffect(() => {
//     if (!currentUser) return;

//     const q = query(
//       collection(db, 'chats'),
//       where('participants', 'array-contains', currentUser.id),
//       where(`isImportantFor${currentUser.id === 'senderUid' ? 'Sender' : 'Receiver'}`, '==', true)
//     );

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setImportantMessages(messages);
//     });

//     return () => unsubscribe();
//   }, [currentUser]);

//   const handleSelectMessage = (messageId) => {
//     const selectedMessage = importantMessages.find(msg => msg.id === messageId);
//     setSelectedMessage(selectedMessage); // Set the selected message
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
//     <Sidebar />
//     <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//       <div className="w-full flex justify-start items-center space-x-2">
//         {/* <img
//           src="/Assets/arrow_back.svg"
//           alt="Back"
//           className="cursor-pointer mr-2 h-6 w-6"
//           onClick={() => navigate(-1)}
//         /> */}
//         <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Important Message</h1>
//       </div>
//     </div>
//     <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex w-full h-full">
//         <div className="w-full lg:w-1/4 h-full bg-[#F9F9F9] border-r">
//           {importantMessages.map((message) => (
//             <div
//               key={message.id}
//               className="p-4 hover:bg-gray-100 cursor-pointer rounded-lg shadow-sm bg-[#E7E7E7]"
//               onClick={() => handleSelectMessage(message.id)}
//             >
//               <div className="flex items-center p-4">
//               <img 
//   src={currentUser.id === message.senderUid 
//     ? message.receiverImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60' // Fallback URL
//     : message.senderImage || 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'} 
//   alt={currentUser.id === message.senderUid ? message.receiverName : message.senderName} 
//   className="h-12 w-12 rounded-full mr-4" 
// />

//                 <div className="flex-grow">
//                   <h2 className="font-semibold">
//                     {currentUser.id === message.senderUid ? message.receiverName : message.senderName}
//                   </h2>
//                   <p className="text-sm text-gray-600">{message.lastMessage}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Chat window on the right */}
//         <div className= "w-full hidden lg:flex lg:w-2/4 h-full">
//           {selectedMessage && (
//             <ChatWindow
//               message={selectedMessage}
//               selectedUser={currentUser.id === selectedMessage.senderUid ? { userName: selectedMessage.receiverName, userImage: selectedMessage.receiverImage } : { userName: selectedMessage.senderName, userImage: selectedMessage.senderImage }}
//               currentUser={currentUser}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ImportantMessages;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase_service';
import Sidebar from '../../Components/main_navbar';
import ChatWindow from '../../Components/chatmsg_ex';

const ImportantMessages = () => {
  const [importantMessages, setImportantMessages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Add state to check screen size
  const navigate = useNavigate();

  useEffect(() => {
    const companyUid = localStorage.getItem('company_uid');
    if (companyUid) {
      setCurrentUser({ id: companyUid });
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    if (!currentUser) return;

    const q1 = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', currentUser.id),
      where('isImportantForSender', '==', true)
    );

    const q2 = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', currentUser.id),
      where('isImportantForReceiver', '==', true)
    );

    const unsubscribe1 = onSnapshot(q1, handleSnapshotUpdate);
    const unsubscribe2 = onSnapshot(q2, handleSnapshotUpdate);

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [currentUser]);

  const handleSnapshotUpdate = (snapshot) => {
    const updatedMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setImportantMessages((prevMessages) => {
      const newMessages = [...prevMessages, ...updatedMessages];
      return Array.from(new Set(newMessages.map((msg) => msg.id)))
        .map((id) => newMessages.find((msg) => msg.id === id))
        .filter((msg) => msg.isImportantForSender || msg.isImportantForReceiver);
    });
  };

  const handleSelectMessage = (messageId) => {
    const selectedMessage = importantMessages.find(msg => msg.id === messageId);
    setSelectedMessage(selectedMessage);
  };

  // Handle resizing of the window to detect if it is mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add this function to handle back button click
  const handleBackClick = () => {
    setSelectedMessage(null);
  };

  // Function to trim long messages
  const trimMessage = (message, length = 30) => {
    return message && message.length > length ? `${message.substring(0, length)}...` : message;
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />

      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          {/* Display Back Arrow in mobile view */}
          {isMobile && selectedMessage && (
            <img
              src="/Assets/arrow_back.svg"
              alt="Back"
              className="cursor-pointer h-6 w-6 mr-2"
              onClick={handleBackClick}
            />
          )}
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Important Messages</h1>
        </div>
        <div className='mr-7'></div>
      </div>

      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      <div className="flex w-full h-full">
        {/* Chat List for mobile and desktop */}
        {(isMobile && !selectedMessage) || !isMobile ? (
          <div className={`w-full lg:w-1/4 h-full bg-[#F9F9F9] border-r`}>
            {importantMessages.length === 0 ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                No important chats available
              </div>
            ) : (
              importantMessages.map((message) => (
                <div
                  key={message.id}
                  className="flex items-center p-4 mb-4 bg-gray-100 rounded-lg shadow-md cursor-pointer w-full hover:bg-gray-200 transition-colors"
                  onClick={() => handleSelectMessage(message.id)}
                >
                  <img
                    src={currentUser.id === message.senderUid ? message.receiverImage : message.senderImage}
                    alt={currentUser.id === message.senderUid ? message.receiverName : message.senderName}
                    className="h-12 w-12 rounded-full mr-4"
                  />
                  <div className="flex-grow">
                    <h2 className="font-semibold text-lg">
                      {currentUser.id === message.senderUid ? message.receiverName : message.senderName}
                    </h2>
                    {/* Trim long messages here */}
                    <p className="text-sm text-gray-600">{trimMessage(message.lastMessage)}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        ) : null}

        {/* Chat Window for selected message */}
        {(isMobile && selectedMessage) || (!isMobile && selectedMessage) ? (
          <div className={`w-full ${isMobile ? '' : 'hidden lg:flex lg:w-[58%]'} h-full`}>
            <ChatWindow
              message={selectedMessage}
              selectedUser={currentUser.id === selectedMessage.senderUid
                ? { userName: selectedMessage.receiverName, userImage: selectedMessage.receiverImage }
                : { userName: selectedMessage.senderName, userImage: selectedMessage.senderImage }
              }
              currentUserUid={currentUser.id}
              onImportanceToggle={() => {}} // Add your handler here if necessary
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ImportantMessages;


