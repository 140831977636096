// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import SettingsSidebar from "./settings";

// const Sidebar = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [isMoreExpanded, setIsMoreExpanded] = useState(false);
//     const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);

//     const toggleSidebar = () => {
//         setIsOpen(!isOpen);
//     };

//     const toggleMore = () => {
//         setIsMoreExpanded(!isMoreExpanded);
//     };

//     const openSettingsSidebar = () => {
//         setShowSettingsSidebar(true);
//     };

//     const closeSettingsSidebar = () => {
//         setShowSettingsSidebar(false);
//     };

//     return (
//         <div className="flex font-poppins font-normal">
//             {!showSettingsSidebar && (
//                 <button
//                     className={`fixed top-4 right-4 md:hidden text-3xl z-50 ${isOpen ? 'hidden' : 'block'}`}
//                     onClick={toggleSidebar}
//                 >
//                     &#9776;
//                 </button>
//             )}
//             {!showSettingsSidebar && (
//                 <nav
//                     className={`fixed top-0 right-0 w-64 h-full bg-[#171717] text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:translate-x-0 z-40 flex flex-col overflow-y-auto font-normal`}
//                 >
//                     <button
//                         className="absolute top-4 left-4 text-3xl md:hidden"
//                         onClick={toggleSidebar}
//                     >
//                         &times;
//                     </button>
//                     <div className="mt-16 space-y-4 flex-grow font-normal">
//                         <Link to="/search-page" className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" lg:text-lg text-sm font-normal">Search</span>
//                             <img src="/Assets/searchnew.svg" alt="Search Icon" className="w-6 h-6 ml-auto" />
//                         </Link>
//                         <Link to="/home" className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" lg:text-lg text-sm">Home</span>
//                             <img src="/Assets/home.svg" alt="Home Icon" className="w-6 h-6 ml-auto" />
//                         </Link>
//                         <Link to="/video_player?video=http%3A%2F%2Fcommondatastorage.googleapis.com%2Fgtv-videos-bucket%2Fsample%2FBigBuckBunny.mp4" className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" lg:text-lg text-sm">Explore</span>
//                             <img src="/Assets/explore.svg" alt="Explore Icon" className="w-6 h-6 ml-auto" />
//                         </Link>
//                         <Link to="/post_request" className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" lg:text-lg text-sm">Post Request</span>
//                             <img src="/Assets/post_request.svg" alt="Post Request Icon" className="w-6 h-6 ml-auto" />
//                         </Link>
//                         <Link to="/inbox_page" className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" lg:text-lg text-sm">Message</span>
//                             <img src="/Assets/message.svg" alt="Message Icon" className="w-6 h-6 ml-auto" />
//                         </Link>
//                         <Link to="/profile1" className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" lg:text-lg text-sm">Profile</span>
//                             <img src="/Assets/profile.svg" alt="Profile Icon" className="w-6 h-6 ml-auto" />
//                         </Link>
//                         <div className='ml-4 mr-4'>
//                         {isMoreExpanded && (
//                             <div className="flex flex-col bg-[#F0F0F0] text-[#000000] w-full mt-10 rounded-lg p-2">
//                                 <button onClick={openSettingsSidebar} className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
//                                     <span className="lg:text-lg text-sm ">Settings</span>
//                                     <img src="/Assets/setting_line.svg" alt="Settings Icon" className="w-6 h-6 ml-auto" />
//                                 </button>
//                                 <Link to="/report_page" className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
//                                     <span className="lg:text-lg text-sm">Report</span>
//                                     <img src="/Assets/filedock.svg" alt="Report Icon" className="w-6 h-6 ml-auto" />
//                                 </Link>
//                             </div>
//                         )}
//                     </div>
//                      </div>
//                     <div className="mt-auto mb-2 w-full">
//                         <button onClick={toggleMore} className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className=" sm:text-lg md:text-xl">More</span>
//                             <img src="/Assets/more.svg" alt="More Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                     </div>
//                 </nav>
//             )}
//             {showSettingsSidebar && <SettingsSidebar closeSettings={closeSettingsSidebar} />}
//         </div>
//     );
// };

// export default Sidebar;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import SettingsSidebar from "./settings";
// import ConfirmationPopup from './pop_up';

// const Sidebar = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [isMoreExpanded, setIsMoreExpanded] = useState(false);
//     const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
//     const [showPopup, setShowPopup] = useState(false);
//     const [verificationStatus, setVerificationStatus] = useState(null);
//     const navigate = useNavigate();

//     // Retrieve verification status from localStorage when component mounts
//     useEffect(() => {
//         const status = localStorage.getItem('verificationStatus');
//         setVerificationStatus(status);
//     }, []);

//     const toggleSidebar = () => {
//         setIsOpen(!isOpen);
//     };

//     const toggleMore = () => {
//         setShowPopup(true);
//     };

//     const openSettingsSidebar = () => {
//         setShowSettingsSidebar(true);
//     };

//     const closeSettingsSidebar = () => {
//         setShowSettingsSidebar(false);
//     };

//     // Show alert for all verification statuses and prevent navigation
//     const handleNavigation = () => {
//         // Always show popup and prevent navigation
//         setShowPopup(true);
//     };

//     const closePopup = () => {
        
//         setShowPopup(false);
//     };

//     return (
//         <div className="flex font-poppins font-normal">
//             {!showSettingsSidebar && (
//                 <button
//                     className={`fixed top-4 right-4 md:hidden text-3xl z-50 ${isOpen ? 'hidden' : 'block'}`}
//                     onClick={toggleSidebar}
//                 >
//                     &#9776;
//                 </button>
//             )}
//             {!showSettingsSidebar && (
//                 <nav
//                     className={`fixed top-0 right-0 w-64 h-full bg-[#171717] text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:translate-x-0 z-40 flex flex-col overflow-y-auto font-normal`}
//                 >
//                     <button
//                         className="absolute top-4 left-4 text-3xl md:hidden"
//                         onClick={toggleSidebar}
//                     >
//                         &times;
//                     </button>
//                     <div className="mt-16 space-y-4 flex-grow font-normal">
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={handleNavigation}>
//                             <span className="sm:text-lg md:text-xl font-normal">Search</span>
//                             <img src="/Assets/searchnew.svg" alt="Search Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={handleNavigation}>
//                             <span className="sm:text-lg md:text-xl">Home</span>
//                             <img src="/Assets/home.svg" alt="Home Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={handleNavigation}>
//                             <span className="sm:text-lg md:text-xl">Explore</span>
//                             <img src="/Assets/explore.svg" alt="Explore Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={handleNavigation}>
//                             <span className="sm:text-lg md:text-xl">Post Request</span>
//                             <img src="/Assets/post_request.svg" alt="Post Request Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={handleNavigation}>
//                             <span className="sm:text-lg md:text-xl">Message</span>
//                             <img src="/Assets/message.svg" alt="Message Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={handleNavigation}>
//                             <span className="sm:text-lg md:text-xl">Profile</span>
//                             <img src="/Assets/profile.svg" alt="Profile Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <div className='ml-4 mr-4'>
//                             {isMoreExpanded && (
//                                 <div className="flex flex-col bg-[#F0F0F0] text-[#000000] w-full mt-10 rounded-lg p-2">
//                                     <button onClick={openSettingsSidebar} className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
//                                         <span className="sm:text-lg md:text-xl">Settings</span>
//                                         <img src="/Assets/setting_line.svg" alt="Settings Icon" className="w-6 h-6 ml-auto" />
//                                     </button>
//                                     <button className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
//                                         <span className="sm:text-lg md:text-xl">Report</span>
//                                         <img src="/Assets/filedock.svg" alt="Report Icon" className="w-6 h-6 ml-auto" />
//                                     </button>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                     <div className="mt-auto mb-2 w-full">
//                         <button onClick={toggleMore} className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className="sm:text-lg md:text-xl">More</span>
//                             <img src="/Assets/more.svg" alt="More Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                     </div>
//                 </nav>
//             )}
//             {showSettingsSidebar && <SettingsSidebar closeSettings={closeSettingsSidebar} />}
//             {showPopup && (
//                 <ConfirmationPopup
//                     onClose={closePopup}
//                     show={showPopup}
//                 />
//             )}
//         </div>
//     );
// };

// export default Sidebar;
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import SettingsSidebar from "./settings";
// import ConfirmationPopup from './pop_up';

// const Sidebar = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [isMoreExpanded, setIsMoreExpanded] = useState(false);
//     const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
//     const [showPopup, setShowPopup] = useState(false);
//     const [verificationStatus, setVerificationStatus] = useState(null);
//     const navigate = useNavigate();

//     // Retrieve verification status from localStorage when component mounts
//     useEffect(() => {
//         const status = localStorage.getItem('verificationStatus');
//         setVerificationStatus(status);
//     }, []);

//     const toggleSidebar = () => {
//         setIsOpen(!isOpen);
//     };

//     const toggleMore = () => {
//         if (verificationStatus === 'VERIFICATION_COMPLETED') {
//             // Open the settings sidebar directly if verification is completed
//             setShowSettingsSidebar(true);
//         } else {
//             // Show popup if verification is not completed
//             setShowPopup(true);
//         }
//     };

//     const openSettingsSidebar = () => {
//         setShowSettingsSidebar(true);
//     };

//     const closeSettingsSidebar = () => {
//         setShowSettingsSidebar(false);
//     };

//     // Show alert for verification statuses other than 'VERIFICATION_COMPLETED' and prevent navigation
//     const handleNavigation = (path) => {
//         if (verificationStatus === 'VERIFICATION_COMPLETED') {
//             // Navigate directly if verification is completed
//             navigate(path);
//         } else {
//             // Show popup if verification is not completed
//             setShowPopup(true);
//         }
//     };

//     const closePopup = () => {
//         setShowPopup(false);
//     };

//     return (
//         <div className="flex font-poppins font-normal">
//             {!showSettingsSidebar && (
//                 <button
//                     className={`fixed top-4 right-4 md:hidden text-3xl z-50 ${isOpen ? 'hidden' : 'block'}`}
//                     onClick={toggleSidebar}
//                 >
//                     &#9776;
//                 </button>
//             )}
//             {!showSettingsSidebar && (
//                 <nav
//                     className={`fixed top-0 right-0 w-64 h-full bg-[#171717] text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:translate-x-0 z-40 flex flex-col overflow-y-auto font-normal`}
//                 >
//                     <button
//                         className="absolute top-4 left-4 text-3xl md:hidden"
//                         onClick={toggleSidebar}
//                     >
//                         &times;
//                     </button>
//                     <div className="mt-16 space-y-4 flex-grow font-normal">
                        //  <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={() => handleNavigation('/search')}>
                        //     <span className="sm:text-lg md:text-xl font-normal">Search</span>
                        //     <img src="/Assets/searchnew.svg" alt="Search Icon" className="w-6 h-6 ml-auto" />
                        // </button> 
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={() => handleNavigation('/home')}>
//                             <span className="sm:text-lg md:text-xl">Home</span>
//                             <img src="/Assets/home.svg" alt="Home Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         {/* <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={() => handleNavigation('/explore')}>
//                             <span className="sm:text-lg md:text-xl">Explore</span>
//                             <img src="/Assets/explore.svg" alt="Explore Icon" className="w-6 h-6 ml-auto" />
//                         </button> */}
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={() => handleNavigation('/post-request')}>
//                             <span className="sm:text-lg md:text-xl">Post Request</span>
//                             <img src="/Assets/post_request.svg" alt="Post Request Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={() => handleNavigation('/inbox_page')}>
//                             <span className="sm:text-lg md:text-xl">Message</span>
//                             <img src="/Assets/message.svg" alt="Message Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg" onClick={() => handleNavigation('/profile')}>
//                             <span className="sm:text-lg md:text-xl">Profile</span>
//                             <img src="/Assets/profile.svg" alt="Profile Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                         <div className='ml-4 mr-4'>
//                             {isMoreExpanded && (
                                // <div className="flex flex-col bg-[#F0F0F0] text-[#000000] w-full mt-10 rounded-lg p-2">
                                //     <button onClick={openSettingsSidebar} className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
                                //         <span className="sm:text-lg md:text-xl">Settings</span>
                                //         <img src="/Assets/setting_line.svg" alt="Settings Icon" className="w-6 h-6 ml-auto" />
                                //     </button>
                                //     <button className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
                                //         <span className="sm:text-lg md:text-xl">Report</span>
                                //         <img src="/Assets/filedock.svg" alt="Report Icon" className="w-6 h-6 ml-auto" />
                                //     </button>
                                // </div>
//                             )}
//                         </div>
//                     </div>
//                     <div className="mt-auto mb-2 w-full">
//                         <button onClick={toggleMore} className="flex items-center py-4 px-6 w-full hover:bg-[#232323] rounded-lg">
//                             <span className="sm:text-lg md:text-xl">More</span>
//                             <img src="/Assets/more.svg" alt="More Icon" className="w-6 h-6 ml-auto" />
//                         </button>
//                     </div>
//                 </nav>
//             )}
//             {showSettingsSidebar && <SettingsSidebar closeSettings={closeSettingsSidebar} />}
//             {showPopup && (
//                 <ConfirmationPopup
//                     onClose={closePopup}
//                     show={showPopup}
//                 />
//             )}
//         </div>
//     );
// };

// export default Sidebar;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsSidebar from "./settings";
import ConfirmationPopup from './pop_up';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMoreExpanded, setIsMoreExpanded] = useState(false);
    const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const navigate = useNavigate();

    // Retrieve verification status from localStorage when component mounts
    useEffect(() => {
        const status = localStorage.getItem('verificationStatus');
        console.log('Verification Status:', status); // Debugging log
        setVerificationStatus(status);
    }, []);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const openSettingsSidebar = () => {
        setShowSettingsSidebar(true); // Open the settings sidebar when clicking on Settings
    };

    const closeSettingsSidebar = () => {
        setShowSettingsSidebar(false);
    };

    const toggleMore = () => {
        // Toggle the expanded state of the "More" section
        setIsMoreExpanded(!isMoreExpanded);
    };
    
    const handleNavigation = (path) => {
        if (verificationStatus === 'VERIFICATION_COMPLETED') {
            navigate(path); // Allow navigation directly if verification is completed
        } else if (verificationStatus === 'VERIFICATION_PENDING' || verificationStatus === 'VERIFICATION_REJECTED') {
            if (path === '/explore-all-video' || path === '/inbox_page' || path === '/posts') {
                setShowPopup(true); // Show popup when clicking on restricted buttons
            } else {
                navigate(path); // Allow navigation for other paths like home, profile, etc.
            }
        } else {
            setShowPopup(true); // Show popup if verification is not completed, pending, or rejected
        }
    };
    

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="flex font-poppins font-normal">
            {!showSettingsSidebar && (
                <button
                    className={`fixed top-4 right-4 md:hidden text-2xl z-50 ${isOpen ? 'hidden' : 'block'}`}
                    onClick={toggleSidebar}
                >
                    &#9776;
                </button>
            )}
            {!showSettingsSidebar && (
                <nav
                    className={`fixed top-0 right-0 w-64 h-full bg-[#171717] text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:translate-x-0 z-40 flex flex-col overflow-y-auto font-normal`}
                >
                    <button
                        className="absolute top-4 left-4 text-3xl md:hidden"
                        onClick={toggleSidebar}
                    >
                        &times;
                    </button>
                    <div className="mt-16 space-y-4 flex-grow font-normal">
                    <button className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group" onClick={() => handleNavigation('/search-page')}>
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">Search</span>
                            <img src="/Assets/searchnew.svg" alt="Search Icon" className="w-6 h-6 ml-auto  nav-icon" />
                        </button>
                        <button 
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group "
                            onClick={() => handleNavigation('/home')}
                        >
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">Home</span>
                            <img 
                                src="/Assets/home.svg" 
                                alt="Home Icon" 
                                className="w-6 h-6 ml-auto nav-icon" 
                            />
                        </button>

                        <button 
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group" 
                            onClick={() => handleNavigation('/explore-all-video')}
                        >
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">Explore</span>
                            <img src="/Assets/explore.svg" alt="Explore Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>

                        <button 
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group " 
                            onClick={() => handleNavigation('/posts')}
                        >
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">Posts</span>
                            <img src="/Assets/post_request.svg" alt="Post Request Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>

                        <button 
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group "
                            onClick={() => handleNavigation('/inbox_page')}
                        >
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">Message</span>
                            <img 
                                src="/Assets/message.svg" 
                                alt="Message Icon" 
                                className="w-6 h-6 ml-auto nav-icon" 
                            />
                        </button>

                        <button 
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group "
                            onClick={() => handleNavigation('/profile1')}
                        >
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">Profile</span>
                            <img 
                                src="/Assets/profile.svg" 
                                alt="Profile Icon" 
                                className="w-6 h-6 ml-auto nav-icon" 
                            />
                        </button>
                        

                        <div className='ml-4 mr-4'>
                            {isMoreExpanded && (
                                <div className="flex flex-col bg-[#F0F0F0] text-[#000000] w-full mt-14 rounded-lg p-2">
                                    <button onClick={openSettingsSidebar} className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg ">
                                        <span className="lg:text-lg text-sm">Settings</span>
                                        <img src="/Assets/setting_line.svg" alt="Settings Icon" className="w-6 h-6 ml-auto" />
                                    </button>
                                    {/* <button 
                                        className="flex items-center py-2 px-4 w-full hover:bg-[#9D9D9D] hover:rounded-lg "
                                        onClick={() => navigate('/report_page')}
                                    >
                                        <span className="lg:text-lg text-sm">Report</span>
                                        <img src="/Assets/filedock.svg" alt="Report Icon" className="w-6 h-6 ml-auto" />
                                    </button> */}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-auto mb-2 w-full">
                        <button 
                            onClick={toggleMore} 
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group "
                        >
                            <span className="lg:text-lg text-sm hover:text-[#DB0000]">More</span>
                            <img 
                                src="/Assets/more.svg" 
                                alt="More Icon" 
                                className="w-6 h-6 ml-auto nav-icon" 
                            />
                        </button>
                    </div>
                </nav>
            )}
            {showSettingsSidebar && <SettingsSidebar closeSettings={closeSettingsSidebar} />}
            {showPopup && (
                <ConfirmationPopup
                    onClose={closePopup}
                    show={showPopup}
                />
            )}

            <style jsx>{`
                .hover-group:hover .nav-icon {
                    filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(5735%) hue-rotate(357deg) brightness(96%) contrast(115%);
                }
            `}</style>
        </div>
    );
};

export default Sidebar;

