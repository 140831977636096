import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './button';

const EditPopup = ({ show, heading, message, icon }) => {
    const navigate = useNavigate();

    const handleContinueClick = () => {
        navigate('/profile1'); // Navigate to /profile1 when continue is clicked
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins">
            <div className="bg-white rounded-lg p-6 w-80 text-center relative">
                <div className="flex justify-center mb-4">
                    <img src={icon || '/pop-up.svg'} alt="Info" className="w-12 h-12" />
                </div>
                <h2 className="text-black font-semibold mb-2">{heading}</h2>
                <p className="text-black mb-4">{message}</p>

                {/* Continue Button */}
                <button
                    className=" bg-[#DB0000] text-[#FFFFFF] px-4 py-2 rounded-lg mt-4 font-poppins "
                    onClick={handleContinueClick}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default EditPopup;
