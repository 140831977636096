// import React, { useRef, useState, useEffect } from 'react';
// import { showToast } from './toast'; // Import toast for error handling
// import UploadDocuments from '../../Controller/firebase/firebase_documents';
// // Import the PDF image

// const DocumentUpload = ({ label, imgSrc, disabled, existingFile }) => {
//   const [isUploading, setIsUploading] = useState(false);
//   const [uploadedFileName, setUploadedFileName] = useState(''); // Initialize with an empty string
//   const fileInputRef = useRef(null);

//   // Function to render the file name from the URL
//   const renderFileName = (fileURL) => {
//     try {
//       const decodedURL = decodeURIComponent(fileURL); // Decode the URL
//       const fileName = decodedURL.split('/').pop().split('?')[0]; // Extract the file name
//       return fileName;
//     } catch (error) {
//       console.error("Error decoding file URL:", error);
//       return "Unknown file";
//     }
//   };

//   // Set the file name when the component mounts if there is an existing file
//   useEffect(() => {
//     if (existingFile) {
//       setUploadedFileName(renderFileName(existingFile));
//     }
//   }, [existingFile]); // This will run only when `existingFile` changes

//   // Handles the file input trigger
//   const handleImageClick = () => {
//     if (!disabled && !existingFile) {
//       fileInputRef.current.click();
//     }
//   };

//   // Handles file change and upload
//   const handleFileChangeInternal = async (e) => {
//     const file = e.target.files[0];
//     const fileSizeLimit = 25 * 1024 * 1024; // 25 MB size limit

//     if (file) {
//       if (file.size <= fileSizeLimit && file.type === 'application/pdf') {
//         setIsUploading(true);
//         setUploadedFileName(file.name); // Set the file name after selection

//         try {
//           const downloadURL = await UploadDocuments(file); // UploadDocuments now returns the download URL
//           showToast("File uploaded successfully!", "success");
//           localStorage.setItem(label, downloadURL); // Store the file URL in localStorage
//         } catch (error) {
//           console.error("Error uploading file:", error);
//           showToast("Error uploading file. Please try again.", "error");
//           setUploadedFileName(''); // Clear the file name if the upload fails
//         } finally {
//           setIsUploading(false);
//         }
//       } else {
//         showToast("Please upload a PDF document under 25MB.", "error");
//       }
//     }
//   };

//   return (
//     <div className="flex flex-col mb-4">
//       <label className="text-[#989898] mb-2" style={{ fontFamily: 'Gotham', fontWeight: 400, fontSize: '14px' }}>
//         {label}
//       </label>
//       <div
//         className={`bg-[#FAFAFA] border border-gray-300 p-8 rounded-lg text-center ${disabled || existingFile ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
//         style={{ borderStyle: 'dashed' }}
//         onClick={handleImageClick}
//       >
//         <div className="flex justify-center mb-4">
//           {isUploading ? (
//             <div className="flex flex-col items-center">
//               <img src='/Assets/pdf.svg' alt="Uploading PDF..." className="animate-spin h-10 w-10" />
//             </div>
//           ) : existingFile ? (
//             // Case when file is found in localStorage - show PDF image and file name
//             <div className="flex flex-col items-center">
//               <a href={existingFile} target="_blank" rel="noopener noreferrer" className="text-[#DB0000] font-bold flex items-center">
//                 <img src='Assets/pdf.svg' alt="PDF Icon" className="mr-2 h-5 w-5" /> {uploadedFileName}
//               </a>
//             </div>
//           ) : uploadedFileName ? (
//             // When no file is found in localStorage but the user uploads a file - show PDF image and file name
//             <div className="flex flex-col items-center">
//               <a href="#" target="_blank" rel="noopener noreferrer" className="text-[#DB0000] font-bold flex items-center">
//                 <img src='/Assets/pdf.svg' alt="PDF Icon" className="mr-2 h-5 w-5" /> {uploadedFileName}
//               </a>
//             </div>
//           ) : (
//             // Default drag and drop UI
//             <img src='/Assets/icon-up.svg'alt="" className="h-10 w-10" />
//           )}
//         </div>

//         {/* Only show Drag & Drop options if there is no existing file */}
//         {!existingFile && (
//           <>
//             <p className="text-sm" style={{ fontFamily: 'Gotham', fontWeight: 400, color: '#2D3748' }}>
//               Drag & Drop your document here
//             </p>

//             <input
//               type="file"
//               ref={fileInputRef}
//               onChange={handleFileChangeInternal}
//               style={{ display: 'none' }}
//               accept="application/pdf" // Accept only PDF files
//               disabled={disabled}
//             />

//             <p className="text-sm mt-2" style={{ fontFamily: 'Gotham', fontWeight: 400, color: '#A0AEC0' }}>
//               Single file size limit 25MB. File type: PDF
//             </p>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default DocumentUpload;
import React, { useRef, useState, useEffect } from 'react';
import { showToast } from './toast'; // Import toast for error handling
import UploadDocuments from '../../Controller/firebase/firebase_documents';
import { FaSpinner } from 'react-icons/fa';
const DocumentUpload = ({ label, imgSrc, disabled, existingFile }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(''); // Initialize with an empty string
  const fileInputRef = useRef(null);

  // Function to render the file name from the URL
  const renderFileName = (fileURL) => {
    try {
      const decodedURL = decodeURIComponent(fileURL); // Decode the URL
      const fileName = decodedURL.split('/').pop().split('?')[0]; // Extract the file name
      return fileName;
    } catch (error) {
      console.error("Error decoding file URL:", error);
      return "Unknown file";
    }
  };

  // Set the file name when the component mounts if there is an existing file
  useEffect(() => {
    if (existingFile) {
      setUploadedFileName(renderFileName(existingFile));
    }
  }, [existingFile]); // This will run only when existingFile changes

  // Handles the file input trigger
  const handleImageClick = () => {
    if (!disabled) {
      fileInputRef.current.click();
    }
  };

  // Handles file change and upload
  const handleFileChangeInternal = async (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 25 * 1024 * 1024; // 25 MB size limit

    if (file) {
      if (file.size <= fileSizeLimit && file.type === 'application/pdf') {
        setIsUploading(true);
        setUploadedFileName(file.name); // Set the file name after selection

        try {
          const downloadURL = await UploadDocuments(file); // UploadDocuments now returns the download URL
          showToast("File uploaded successfully!", "success");
          localStorage.setItem(label, downloadURL); // Store the file URL in localStorage
          setUploadedFileName(renderFileName(downloadURL)); // Update the displayed file name
        } catch (error) {
          console.error("Error uploading file:", error);
          showToast("Error uploading file. Please try again.", "error");
          setUploadedFileName(''); // Clear the file name if the upload fails
        } finally {
          setIsUploading(false);
        }
      } else {
        showToast("Please upload a PDF document under 25MB.", "error");
      }
    }
  };

  return (
    <div className="flex flex-col mb-4">
      <label className="text-[#989898] mb-2" style={{ fontFamily: 'Gotham', fontWeight: 400, fontSize: '14px' }}>
        {label}
      </label>
      <div
        className={`bg-[#FAFAFA] border border-gray-300 p-8 rounded-lg text-center ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        style={{ borderStyle: 'dashed' }}
        onClick={handleImageClick}
      >
        <div className="flex justify-center mb-4">
          {isUploading ? (
            <div className="flex flex-col items-center">
                  <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                  </div>
          ) : existingFile || uploadedFileName ? (
            // Display the PDF file or uploaded file name with the link to view it
            <div className="flex flex-col items-center">
              <a href={existingFile || "#"} target="_blank" rel="noopener noreferrer" className="text-[#DB0000] font-bold flex items-center">
                <img src='Assets/pdf.svg' alt="PDF Icon" className="mr-2 h-5 w-5" /> {uploadedFileName || renderFileName(existingFile)}
              </a>
            </div>
          ) : (
            // Default drag and drop UI
            <img src='/Assets/icon-up.svg' alt="Upload" className="h-10 w-10" />
          )}
        </div>

        {/* Only show Drag & Drop options if not uploading */}
        {!isUploading && (
          <>
            <p className="text-sm" style={{ fontFamily: 'Gotham', fontWeight: 400, color: '#2D3748' }}>
              Drag & Drop your document here
            </p>

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChangeInternal}
              style={{ display: 'none' }}
              accept="application/pdf" // Accept only PDF files
              disabled={disabled}
            />

            <p className="text-sm mt-2" style={{ fontFamily: 'Gotham', fontWeight: 400, color: '#A0AEC0' }}>
              Single file size limit 25MB. File type: PDF
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;
