// import React from 'react';
// import Steps from '../../Components/company_list_tile';
// import Sidebar from '../../Components/main_navbar';
// const CompanyPhotosPage = () => {
//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins ">
//     <Sidebar />
//     <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//     <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Photos</h1>
//     </div>
//     <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//         {/* <div className="flex flex-col items-center mx-auto justify-center p-4 w-4/5 space-y-7 lg:ml-7 pl-7"> */}
//         <div className="flex flex-col mx-2 items-center mt-10 lg:ml-7 lg:w-4/5  overflow-auto">
        
//           <div className="max-w-lg w-full space-y-5 mt-8">
//           <Steps 
//             text={<p className="lg:text-lg text-sm font-semibold font-poppins">Photo of the Company Signboard from Outside</p>}
//             destination="/photo-signboard"
//             backgroundColor="#D9D9D9" // Custom background color
//             hoverColor="#E0E0E0" // Custom hover color
//           />
//             <Steps 
//               text={
//                 <>
//                   <p className="lg:text-lg text-sm font-semibold">Interior Photo of the Company Premises</p>
                 
//                 </>
//               }
//               destination="/photo-premises"
//               backgroundColor="#D9D9D9" // Custom background color
//               hoverColor="#E0E0E0" // Custom hover color
//             />
//             <Steps 
//               text={
//                 <>
//                   <p className="lg:text-lg text-sm font-semibold">Group Photo of the Company Crew</p>
                
//                 </>
//               }
//               destination="/photo-crew"
//               backgroundColor="#D9D9D9" // Custom background color
//               hoverColor="#E0E0E0" // Custom hover color
//             />
//             <Steps 
//               text={
//                 <>
//                   <p className="lg:text-lg text-sm font-semibold">Photo of the CEO in the Office</p>
                 
//                 </>
//               }
//               destination="/photo-ceo"
//               backgroundColor="#D9D9D9" // Custom background color
//               hoverColor="#E0E0E0" // Custom hover color
//             />
//           </div>
//         </div>
//       </div>
    
//   );
// };

// export default CompanyPhotosPage;
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Steps from '../../Components/company_list_tile';
import Sidebar from '../../Components/main_navbar';

const CompanyPhotosPage = () => {
  const location = useLocation();
  const [existingPhotos, setExistingPhotos] = useState([]);

  useEffect(() => {
    if (location.state?.photos) {
      setExistingPhotos(location.state.photos);
      console.log('Received photos:', location.state.photos);
      localStorage.setItem('existingPhotos', JSON.stringify(location.state.photos)); // Save to localStorage
    }
  }, [location.state]);
  

  // Function to find verification status for a specific type
  const getPhotoStatusForType = (type) => {
    const photo = existingPhotos.find(p => p.verification_type === type);
    return photo ? `Edit ${type} Photo` : `Add ${type} Photo`;
  };

  const steps = [
    {
     
      text: "Photo of the Company Signboard from Outside",
      destination: {
        pathname: "/photo-signboard",
        state: {
          existingData: existingPhotos.find(p => p.verification_type === "outside"),
          verificationType: "outside"
        }
      }
    },
    {

      text: "Interior Photo of the Company Premises",
      destination: {
        pathname: "/photo-premises",
        state: {
          existingData: existingPhotos.find(p => p.verification_type === "inside"),
          verificationType: "inside"
        }
      }
    },
    {
   
      text: "Group Photo of the Company Crew",
      destination: {
        pathname: "/photo-crew",
        state: {
          existingData: existingPhotos.find(p => p.verification_type === "crew"),
          verificationType: "crew"
        }
      }
    },
    {
     
      text: "Photo of the CEO in the Office",
      destination: {
        pathname: "/photo-ceo",
        state: {
          existingData: existingPhotos.find(p => p.verification_type === "ceo"),
          verificationType: "ceo"
        }
      }
    }
  ];

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
        <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">
          Company Photos
        </h1>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex flex-col mx-2 items-center mt-10 lg:ml-7 lg:w-4/5 overflow-auto">
        <div className="max-w-lg w-full space-y-5 mt-8">
          {steps.map((step, index) => (
           <Steps
           key={index}
           number={step.number}
           text={step.text}
           destination={{
             pathname: step.destination.pathname,
             state: step.destination.state // Ensure this is not empty or undefined
           }}
           backgroundColor="#D9D9D9"
           hoverColor="#E0E0E0"
         />
         
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyPhotosPage;