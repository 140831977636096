import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main_navbar';
import ComplaintCard from '../../Components/complaint_card';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import InputBox from '../../Components/input_box';
import AddDocument from '../../Components/add_doc';
import ReviewImageUpload from '../../Components/review_image_add';
import LoaderSpinner from '../../Components/loader_spinner'; // Loader Spinner
import { useNavigate } from 'react-router-dom';
import { uploadComplaint, getComplaintsAgainstCompany } from '../../../Controller/complaints';
import { showToast } from '../../Components/toast';
import { Toast } from '../../Components/toast';
import ImagePopup from '../../Components/image_modal';
const AddComplaints = () => {
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [complaints, setComplaints] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileDetail, setShowMobileDetail] = useState(false);
  const [authorizationFile, setAuthorizationFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalComplaints, setTotalComplaints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [imagePopupVisible, setImagePopupVisible] = useState(false); // Image popup visibility
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Image URL for detailed view

  const company_uid = localStorage.getItem('uid'); // Retrieve company_uid from local storage
  const complaintsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchComplaints();
  }, [currentPage]);

  const fetchComplaints = async () => {
    try {
      setLoading(currentPage === 1); // Show loading spinner if it's the first page
      setLoadingMore(currentPage > 1); // Show loading spinner for more pages

      const skip = (currentPage - 1) * complaintsPerPage;
      const res = await getComplaintsAgainstCompany(company_uid, skip, complaintsPerPage);
      console.log('get response',res)

      const complaintsArray = res.data.data; // Adjust based on the correct structure
      if (Array.isArray(complaintsArray)) {
        const mappedComplaints = complaintsArray.map((item) => ({
          complaint_by_name: item.complaint_by_name,
          complaint_to: item.complaint_to,
          product_name: item.product_name,
          title: item.title,
          description: item.description,
          image_url: item.image_url && item.image_url.length > 0 ? item.image_url[0] : '/profile.webp',
          confirmation_document: item.confirmation_document,
          date: new Date(item.created_on).toLocaleDateString(),
          complaint_by_profile_image: item.complaint_by_profile_image || '/profile.webp',
        }));

        setComplaints(prevComplaints => [...prevComplaints, ...mappedComplaints]);
        setTotalComplaints(res.data.total || complaintsArray.length);
      } else {
        console.error('Expected an array for complaints, but got:', complaintsArray);
      }
    } catch (error) {
      console.error('Error fetching complaints:', error);    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleViewComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setShowForm(false);
    if (isMobile) {
      setShowMobileDetail(true);
    }
  };

  const handleReportComplaint = () => {
    setSelectedComplaint(null);
    setShowForm(true);
    if (isMobile) {
      setShowMobileDetail(true);
    }
  };

  const handleFileUpload = (file) => {
    setAuthorizationFile(file);
  };

  const handleFormSubmit = async (newComplaint) => {
    try {
        setFormSubmitting(true); // Start loading spinner on form submission
        const complaint_to = company_uid;
        
        const res = await uploadComplaint(
            complaint_to,
            newComplaint.productName,
            newComplaint.title,
            newComplaint.description,
            authorizationFile ? authorizationFile.name : '',
            newComplaint.images
        );

        console.log(res)

        if (res.status === 201) {
            const createdComplaint = res.data.data;
            
            const complaint = {
                complaint_by_name: createdComplaint.complaint_by_name, // Correct name
                complaint_to_name: createdComplaint.complaint_to_name, // Correct name for complaint_to
                product_name: createdComplaint.product_name,
                title: createdComplaint.title,
                description: createdComplaint.description,
                image_url: createdComplaint.image_url.length > 0
                    ? createdComplaint.image_url[0]  // Complaint image, take the first in the array
                    : '/profile.webp', // Fallback image in case no image is provided
                confirmation_document: createdComplaint.confirmation_document,
                date: new Date(createdComplaint.created_on).toLocaleDateString(),
                complaint_by_profile_image: createdComplaint.complaint_by_profile_image || '/profile.webp', // Fallback to default
                complaint_to_profile_image: createdComplaint.complaint_to_profile_image || '/profile.webp', // Fallback to default
            };

            setComplaints((prevComplaints) => [...prevComplaints, complaint]);
            setShowForm(false);
            setShowMobileDetail(false);
            setSelectedComplaint(complaint);
            showToast('Complaint submitted successfully.', 'success');
        } else {
            console.error(`Error: Received status code ${res.status}`);
            showToast('Error submitting complaint.', 'error');
        }
    } catch (error) {
        console.error('Error submitting complaint:', error);
        showToast('Error submitting complaint.', 'error');
    } finally {
        setFormSubmitting(false); // Stop loading spinner after form submission
    }
};

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleImageClick = (imageUrl) => {
    if (imageUrl) {
        setSelectedImageUrl(imageUrl); // Set the image URL for the detailed view
        setImagePopupVisible(true); // Make the image popup visible
    } else {
        showToast('Image not available.', 'error'); // Show a toast if no image is found
    }
};


const handleDocumentView = (documentUrl) => {
  // Assuming `documentUrl` is the correct URL for the document
  if (documentUrl) {
      window.open(documentUrl, '_blank'); // Open the document in a new tab
  } else {
      showToast('Document is not available.', 'error'); // Show a toast if no document is found
  }
};


  return (
    <div className="flex flex-col md:flex-row w-full h-full bg-[#F2F3F3] font-poppins overflow-y-auto">
      <Toast /> {/* Toast notifications */}
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
          <div className="w-full flex justify-start items-center space-x-2">
           
            <h1 className="text-black font-bold text-xl">Complaints</h1>
          </div>
        </div>
        <div className="border-b border-black mt-4 md:mt-[25px]"></div>
        <div className="flex flex-col md:flex-row h-full">
          <div className={`${isMobile ? 'w-full' : 'md:w-1/3'} border-r border-gray-400`}>
            <div className="flex flex-col items-center p-3 md:p-9 ">
              <Button
                text="Report Your Complaint"
                backgroundColor="bg-white"
                textColor="text-red-600"
                borderColor="border-red-600"
                onClick={handleReportComplaint}
              />
              {loading ? (
                <LoaderSpinner visible={true} />
              ) : (
                <>
                  {complaints.length > 0 ? (
                    complaints.map((complaint, index) => (
                      <ComplaintCard
                        key={index}
                        complaint={complaint}
                        onView={handleViewComplaint}
                      />
                    ))
                  ) : (
                    <div className="text-center text-black mt-4">There are No Complaints Reported</div>
                  )}
                  {loadingMore && <LoaderSpinner visible={true} />}
                </>
              )}
            </div>
          </div>
          {selectedComplaint && !showForm && (!isMobile || (isMobile && showMobileDetail)) && (
            <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-7`}>
              {isMobile && (
                <button
                  onClick={() => setShowMobileDetail(false)}
                  className="absolute top-2 right-2 text-2xl"
                >
                  &times;
                </button>
              )}
              <div className="bg-gray-200 rounded-lg p-4 shadow-md w-full mb-4">
                <div className="flex">
                  <img
                    src={selectedComplaint.complaint_by_profile_image || selectedComplaint.reply_profile_image}
                    alt="Profile"
                    className="rounded-full w-12 h-12 mr-4"
                  />
                  <div>
                    <div className="font-bold">{selectedComplaint.complaint_by_name}</div>
                    <div className="text-gray-500 text-sm">{selectedComplaint.product_name}</div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="font-bold text-sm">{selectedComplaint.title}</div>
                  <div className="text-gray-600 font-bold mt-2 text-sm">Product Name</div>
                  <div className="mt-2 text-sm">{selectedComplaint.description}</div>
                  <img
                    src={selectedComplaint.image_url || ''}
                    alt="Complaint"
                    className="w-16 h-16 rounded-lg mt-4 cursor-pointer"
                    onClick={() => handleImageClick(selectedComplaint.image_url)}
                  />
                  {selectedComplaint.confirmation_document && (
                    <div className="mt-4 flex items-center justify-between bg-gray-300 hover:bg-gray-200 p-6 rounded-lg cursor-pointer">
                      <div className="flex items-center">
                        <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-10 h-10 mr-2" />
                        <span className="text-gray-600">Document</span>
                      </div>
                      <button
                        onClick={() => handleDocumentView(selectedComplaint.confirmation_document)}
                        className="text-red-600 underline"
                      >
                        View
                      </button>
                    </div>
                  )}                </div>
              </div>
            </div>
          )}
          {showForm && (
            <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-7`}>
              {isMobile && (
                <button
                  onClick={() => setShowMobileDetail(false)}
                  className="absolute top-2 right-2 text-2xl"
                >
                  &times;
                </button>
              )}
              <ComplaintSubmissionForm
                onSubmit={handleFormSubmit}
                handleFileUpload={handleFileUpload}
                formSubmitting={formSubmitting}
              />
               {imagePopupVisible && selectedImageUrl && (
                <ImagePopup
                  imageUrl={selectedImageUrl}
                  onClose={() => setImagePopupVisible(false)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ComplaintSubmissionForm = ({ onSubmit, handleFileUpload, formSubmitting }) => {
  const [productName, setProductName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});  // State for tracking errors

  const validateForm = () => {
    const newErrors = {};
    if (!productName.trim()) {
      newErrors.productName = 'Please enter the product or service name!';
    }
    if (!title.trim()) {
      newErrors.title = 'Please enter the subject of the complaint!';
    }
    if (!description.trim() || description.trim().length < 10) {
      newErrors.description = 'Please explain the issue that you are facing!';
    }
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit({
        productName,
        title,
        description,
      });

      // Clear the form after submission
      setProductName('');
      setTitle('');
      setDescription('');
    } else {
      showToast('Please fix the errors and submit again.', 'error');  // Show toast if validation fails
    }
  };


  return (
    <div className="p-4 space-y-6 mt-4">
    {/* Product Name Field */}
    <div className="mb-4">
      <label className="text-black font-medium block mb-1">Product/Service Name</label>
      <InputBox
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        placeholder="Enter the product or service name"
        maxLength={100}
      />
      {/* Show error message if validation fails */}
      {errors.productName && (
        <p className="text-red-600 text-xs mt-1">{errors.productName}</p>
      )}
    </div>

    {/* Complaint Subject Field */}
    <div className="mb-4">
      <label className="text-black font-medium block mb-1">Complaint Subject</label>
      <InputBox
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter the subject of the complaint"
        maxLength={120}
      />
      {/* Show error message if validation fails */}
      {errors.title && (
        <p className="text-red-600 text-xs mt-1">{errors.title}</p>
      )}
    </div>

    {/* Description Field */}
    <div className="mb-4">
      <label className="text-black font-medium block mb-1">Description</label>
      <TextArea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Describe the issue that you've faced"
        maxLength={200}
      />
      {/* Show error message if validation fails */}
      {errors.description && (
        <p className="text-red-600 text-xs mt-1">{errors.description}</p>
      )}
    </div>

    {/* Document Upload */}
    <div className="mb-4">
      <label className="text-black font-medium block mb-1">Document</label>
      <AddDocument
        label="Document"
        onFileUpload={handleFileUpload}
        accept="application/pdf"
      />
    </div>

    {/* Submit Button */}
    <Button
      text={formSubmitting ? <LoaderSpinner visible={true} height={15} /> : "Submit"}
      backgroundColor="bg-[#DB0000]"  // Red background color
      textColor="text-white"
      borderColor="border-red-600"
      onClick={handleSubmit}
      disabled={formSubmitting}
    />
  </div>
);
};



export default AddComplaints;