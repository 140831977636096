import React from 'react';

const DeletePopup = ({ message, onConfirm, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 ">
            <div className="bg-white rounded-lg p-6 w-80 text-center mx-2">
                <div className="flex justify-center mb-4">
                    <img src="/pop-up.svg" alt="Info" className="w-12 h-12" />
                </div>
                <p className="text-black mb-4">{message}</p>
                <div className="flex justify-center space-x-2">
                    <button 
                        className="border border-red-600 text-red-600 py-2 px-6 rounded"
                        onClick={onConfirm}
                    >
                        Yes
                    </button>
                    <button 
                        className="bg-red-600 text-white py-2 px-6 rounded"
                        onClick={onClose}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;
