import React from 'react';
import { FaTrashAlt, FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';



const FeedbackCard = ({ feedback, onView, isSubmitted, showEllipsis }) => {
  const navigate = useNavigate();

  if (!feedback) return null;

  // Normalize feedback data structure
  const handleProfileClick = () => {
    const companyId = isSubmitted 
      ? feedback.feedbacking_company_id 
      : (feedback.feedbacker_company_id || feedback.company_uid);  // Added fallback to company_uid

    if (companyId) {
      navigate(`/profile2/${companyId}`);
    } else {
      console.log('No company ID available for navigation');
    }
  };

  // Normalize the data structure for both new and existing feedbacks
  const displayName = isSubmitted 
    ? (feedback.feedbacking_company_name || feedback.name) 
    : (feedback.feedbacker_company_name || feedback.name || 'Anonymous');

  const profileImage = isSubmitted 
    ? (feedback.feedbacking_profile_image || feedback.profilePicUrl) 
    : (feedback.feedbacker_profile_image || feedback.profilePicUrl || '/defaultProfileImage.png');

  const truncatedDescription = feedback.description && feedback.description.length > 40
    ? feedback.description.substring(0, 40) + '...'
    : feedback.description || 'No description available';
  
  const feedbackDate = feedback.created_on ? new Date(feedback.created_on) : null;
  const formattedDate = feedbackDate && !isNaN(feedbackDate)
    ? feedbackDate.toLocaleDateString('en-GB')
    : 'No Date';

  // Check if company ID exists before making elements clickable
  const hasCompanyId = !!(isSubmitted ? feedback.feedbacking_company_id : feedback.feedbacker_company_id);

  return (
    <div className="bg-gray-200 rounded-lg w-11/12 mx-auto p-3 flex flex-col justify-between mb-4 shadow-md w-full font-poppins">
      <div className="flex justify-between items-center w-full">
        <img
          src={profileImage}
          alt="Profile"
          className={`rounded-full w-14 h-14 mr-4 ${hasCompanyId ? 'cursor-pointer' : ''}`}
          onClick={hasCompanyId ? handleProfileClick : undefined}
        />

        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center w-full">
            <div 
              className={`font-semibold text-sm lg:text-base ${hasCompanyId ? 'cursor-pointer' : ''}`}
              onClick={hasCompanyId ? handleProfileClick : undefined}
            >
              {displayName}
            </div>
            <div className="text-gray-500 text-sm">
              {formattedDate}
            </div>
          </div>

          <div className="text-sm mt-1 w-full font-base" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
            {truncatedDescription}
          </div>
        </div>
      </div>

      <div className="flex justify-end items-end w-full mt-2 text-sm">
        <a 
          href="#" 
          className="text-[#6D6D6D] font-bold underline self-end" 
          onClick={(e) => {
            e.preventDefault();
            if (typeof onView === 'function') onView(feedback);
          }}
        >
          more...
        </a>
      </div>
    </div>
  );
}


export default FeedbackCard;
