import { UPDATE_LOCATION_URL, UPDATE_DOCUMENTS_URL, UPDATE_DOCUMENTS_RESUBMIT_URL,UPDATE_MEDIA_URL, UPDATE_ADVANCED_URL, GET_COMPANY_URL,GET_COMPANY_DOCUMENTS_URL,UPDATE_COMPANY_NAME_URL ,POST_LINKEDIN_VERIFICATION,POST_COMPANY_DETAILS, POST_MANAGER,POST_DOCUMENTS, GET_VERIFICATION,ADD_PROFILE_IMAGE, UPDATE_CATALOGUE_URL,GET_DOCUMENTS_BY_COMPANY_URL,GET_COMPANY_ABOUT_API} from "./urls";
import { api } from "./api";

const get_company_api = async () => {
    try {
        const res = await api.get(GET_COMPANY_URL);
        console.log("Company API Response:", res);
        return res;
    } catch (error) {
        console.error("Error fetching company details:", error);
        throw error;
    }
};

const update_profile_image = async (profile_image_url) => {
    try {
        const res = await api.put(ADD_PROFILE_IMAGE, {
            profile_image_url: profile_image_url
        });
        return res;
    } catch (error) {
        console.error("Error updating profile image:", error);
        throw error;
    }
};

const update_location_api = async (payload) => {
    try {
        const res = await api.put(UPDATE_LOCATION_URL, payload);
        return res;
    } catch (error) {
        console.error("Error updating location:", error);
        throw error;
    }
};

 const update_media_api = async (payload) => {
    try {
        console.log("Updating media with payload:", payload);
        const res = await api.put(UPDATE_MEDIA_URL, payload);
        console.log("Media API Response:", res);
        return res; // Return the complete response
    } catch (error) {
        console.error("Error updating media:", error);
        throw error;
    }
};


const update_advanced_api = async (payload) => {
    try {
        const res = await api.put(UPDATE_ADVANCED_URL, payload)
        
        return res.data;
    } catch (error) {
        console.error("Error updating advanced info:", error);
        throw error;
    }
};

const update_documents_api = async (payload) => {
    try {
        const res = await api.put(UPDATE_DOCUMENTS_URL, payload);
        return res;
    } catch (error) {
        console.error("Error updating documents:", error);
        throw error;
    }
};

const update_catalogue_api = async (payload) => {
    try {
        const res = await api.put(UPDATE_CATALOGUE_URL, payload);
        return res;
    } catch (error) {
        console.error("Error updating documents:", error);
        throw error;
    }
};



const update_company_name_url = async (payload) => {
    try {
        const res = await api.put(UPDATE_COMPANY_NAME_URL, payload);
        return res;
    } catch (error) {
        console.error("Error updating documents:", error);
        throw error;
    }
};
const getCompanyDocuments = async (id) => {
    try {
    const res = await api.get(GET_COMPANY_DOCUMENTS_URL(id));
      console.log(res.status)
      return res; 
    } catch (error) {
      console.error(`Error fetching company documents:${error}`);
    }
    
};
//new api after change
const post_linkedin_verification = async (code,linked_in) => {
    try {
        const res = await api.post(POST_LINKEDIN_VERIFICATION, {
            code: code,
            linked_in:linked_in
        });
        return res;
    } catch (error) {
        console.error("Error fetching companies:", error);
        throw error;
    }
};

const post_company_details = async (bio, website, address, latitude, longitude, custom_address) => {
    const res = await api.post(POST_COMPANY_DETAILS, {
      bio: bio,
      website: website,
      address: address,
      latitude: latitude,
      longitude: longitude,
      custom_address: custom_address // Add custom address to the payload
    });
    return res; 
  };
  
  const post_manager = async (manager, linkedInUrl, authorization_document) => {
    const res = await api.post(POST_MANAGER, {
      manager: manager,
      linkedin_url: linkedInUrl,
      authorization_document: authorization_document
    });
    return res; 
  };
  
  const post_documents = async (tenancy_contract,trading_register,trading_license) => {
    const res = await api.post(POST_DOCUMENTS, {
      tenancy_contract:tenancy_contract,
      trading_register:trading_register,
      trading_license:trading_license



    });
    return res; 
  };
  const get_verified = async () => {
    try {
        const res = await api.get(GET_VERIFICATION);
        return res;
    } catch (error) {
        console.error("Error fetching companies:", error);
        throw error;
    }
};

// const get_company_about = async (company_uid) => {
//     try {
//       const res = await api.get(GET_COMPANY_ABOUT_API(company_uid));
//       console.log("Company About API Response:", res);
//       return res;
//     } catch (error) {
//       console.error("Error fetching company about details:", error);
//       throw error;
//     }
//   };
const update_company_details = async (
    name,
    website,
    address,
    latitude,
    longitude,
    manager,
    authorization_document,
    trading_license,
    trading_register,
    tenancy_contract,
    type 
  ) => {
    // Construct the URL with the type query parameter
    const url = `${UPDATE_DOCUMENTS_RESUBMIT_URL}?type=${type}`;
  
    // Make the API request
    const res = await api.put(url, {
      website: website,
      address: address,
      latitude: latitude,
      longitude: longitude,
      manager: manager,
      authorization_document: authorization_document,
      trading_license: trading_license,
      trading_register: trading_register,
      tenancy_contract: tenancy_contract
    });
  
    return res;
  };
  
  const get_documents_api = async (documentId) => {
    try {
        const res = await api.get(GET_DOCUMENTS_BY_COMPANY_URL(documentId));  // Pass the documentId in the URL
        console.log("Documents API Response:", res);
        return res;
    } catch (error) {
        console.error("Error fetching document details:", error);
        throw error;
    }
};
const get_company_about = async (company_uid) => {
    try {
      const res = await api.get(GET_COMPANY_ABOUT_API(company_uid));
      console.log("Company About API Response:", res);
      return res;
    } catch (error) {
      console.error("Error fetching company about details:", error);
      throw error;
    }
  };
  
  
export  {get_company_api,update_advanced_api,update_documents_api,update_catalogue_api,update_location_api,update_media_api,getCompanyDocuments,update_company_name_url,post_linkedin_verification,post_company_details,post_manager,post_documents, get_verified,update_company_details,update_profile_image,get_documents_api,get_company_about}