//  import React from 'react';
//  import Image2 from '../../Components/login_image2.jsx';
//  import PasswordInputField from '../../Components/password-input-field.jsx';
//  import Button from '../../Components/button.jsx';
//  import { Link, useNavigate } from 'react-router-dom';
//  import { useState } from 'react';
//  import InputBox from '../../Components/input_box.jsx';
//  import { ACCESS_TOKEN } from '../../../Controller/Constants/constants.js';
//  import { REFRESH_TOKEN } from '../../../Controller/Constants/constants.js';
// import { check_email } from '../../../Controller/auth.js';
// import { showToast } from '../../Components/toast.jsx';
// import LoaderSpinner from '../../Components/loader_spinner.jsx';
// import { handleSignUp } from '../../../Controller/sign_up_controller.js';
// import { Toast } from '../../Components/toast.jsx';



// const SignUp = () => {
//   const [company, setCompany] = useState('');
//   const [email, setEmail] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [showEmailMessage, setShowEmailMessage] = useState(false);
//   const [emailMessage, setEmailMessage] = useState('');
//   const [showCompanyMessage, setShowCompanyMessage] = useState(false);
//   const [companyMessage, setCompanyMessage] = useState('');
//   const navigate = useNavigate();

//   const handleCompanyChange = (e) => {
//     setCompany(e.target.value);
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const onSignUpClick = async (e) => {
//     e.preventDefault();
//     await handleSignUp(company, email, navigate, setLoading, setShowEmailMessage, setEmailMessage, setShowCompanyMessage, setCompanyMessage);
//   };

//   return (
//     <div className="bg-[#DB0000] h-full flex flex-col md:flex-row p-6 overflow-y-auto">
//       <Toast />
//       <div className="w-full md:w-1/2 flex flex-col justify-center items-center mb-6 md:mb-0">
//         <div className="mt-16 h-24 w-24">
//           <img src="./image1.svg" alt="image1" />
//         </div>
//         <div className="mt-1">
//           <Image2 imgSrc="./sign-up.svg" />
//         </div>
//       </div>
//       <div className="w-full md:w-1/2 flex justify-center items-center p-2">
//         <div className="bg-white p-8 rounded-lg shadow-lg" style={{ width: '34rem', height: '23rem' }}>
//           <h1 className="text-2xl font-techna font-normal mb-4 text-[#171717]">Sign up</h1>
//           <form className="flex flex-col space-y-5" onSubmit={onSignUpClick}>
//             <InputBox
//               type="text"
//               placeholder="Company Name"
//               value={company}
//               onChange={handleCompanyChange}
//               borderRadius="4px"
//               backgroundColor="#F2F2F2"
//               className="placeholder-gray-700 font-helvetica h-full"
//               padding="10px"
//             />
//             {showCompanyMessage && (
//               <div className="text-[#DB0000] text-sm font-techna font-normal ml-2">
//                 {companyMessage}
//               </div>
//             )}
//             <InputBox
//               type="text"
//               placeholder="Email"
//               value={email}
//               onChange={handleEmailChange}
//               borderRadius="4px"
//               className="placeholder-gray-700 font-techna h-full"
//               padding="10px"
//             />
//             {showEmailMessage && (
//               <div className="text-[#DB0000] text-sm font-techna ml-2">
//                 {emailMessage}
//               </div>
//             )}
//             <div className="flex justify-center mt-4 w-full">
//               <div className="w-2/3 mt-3">
//                 <Button
//                   text="Sign up"
//                   backgroundColor="bg-custom-button-color"
//                   textColor="text-white"
//                   className="w-full md:w-3/4 md:ml-4"
//                   type="submit"
//                   loading={loading}
//                 />
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;
import React, { useState, useEffect } from 'react';
import CenteredImageContainer from '../../Components/black_container.jsx';
import InputBox from '../../Components/input_box.jsx';
import Button from '../../Components/button.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { handleSignUp } from '../../../Controller/sign_up_controller.js';
import { Toast } from '../../Components/toast.jsx';

const SignUp = () => {
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [showCompanyMessage, setShowCompanyMessage] = useState(false);
  const [companyMessage, setCompanyMessage] = useState('');
  const navigate = useNavigate();
  
  // Track component mount state
  useEffect(() => {
    let isMounted = true;

    return () => {
      isMounted = false; // Clean up on unmount
    };
  }, []);

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSignUpClick = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Start loading state
    try {
      await handleSignUp(company, email, navigate, setLoading, setShowEmailMessage, setEmailMessage, setShowCompanyMessage, setCompanyMessage);
    } catch (error) {
      console.error("Sign-up error:", error);
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
    <Toast />
    <div
      className="hidden lg:block w-full lg:w-1/2 h-full  relative"
      // style={{
      //   backgroundImage: `url('/Assets/hexagon_bg.svg')`, // Add the hexagon image path
      //   backgroundPosition: ' right', // Adjust for correct positioning
      //   backgroundSize: 'auto', // Ensure scaling fits well
      //   backgroundRepeat: 'no-repeat',
      // }}
    > 
    <div className='w-4/5 h-full'> 
      <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
      </div>
    </div>
      {/* Right side with signup form */}
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4  font-poppins">
      <div className=" p-6  w-full max-w-2/5">
          <div className="flex items-center mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <h1 className=" text-xl lg:text-xl font-normal  mb-2 text-[#9F9F9F]">LET'S GET STARTED</h1>
          <h2 className="text-xl lg:text-3xl font-semibold text-[#0D0907] mb-6">
           Sign up to 
           <img 
           src="Assets/text_eswaf.svg" // Replace with your correct image path
           alt="Eswaf Logo"
           className="inline-block ml-3  "
          // Adjust the size and spacing accordingly
           />
           </h2>

           <form className="flex flex-col space-y-6" onSubmit={onSignUpClick}>
      
            {/* Company Name Label and Input */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="company" className="text-sm font-medium text-[#545454]">
                 Legal Company Name
              </label>
              <InputBox
                id="company"
                type="text"
                placeholder="Company Name"
                value={company}
                onChange={handleCompanyChange}
                padding="10px"
              />
              {showCompanyMessage && (
                <div className="text-[#DB0000] text-[12px] font-poppins  ml-2">
                  {companyMessage}
                </div>
              )}
            </div>

            {/* Email Label and Input */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-[#545454]">
                Email
              </label>
              <InputBox
                id="email"
                type="email"
                placeholder="Enter your Business email"
                value={email}
                onChange={handleEmailChange}
                padding="10px"
              />
              {showEmailMessage && (
                <div className="text-[#DB0000] text-[12px] font-poppins ml-2">
                  {emailMessage}
                </div>
              )}
            </div>

            <div className='mt-10'>
              <Button
                text="Sign up"
                loading={loading}
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                fontWeight="font-bold"
                className="w-full"
                type="submit"
              />
            </div>
          </form>
          <div className="mt-4 text-center">
            <span className="text-[#171717] text-sm ">Already have an account? </span>
            <Link to="/" className="text-[#DB0000] font-semibold text-sm">Log In</Link>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default SignUp;
