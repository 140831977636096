// import React, { useState, useEffect } from 'react';
// import InputBox from '../../Components/input_box';
// import TextArea from '../../Components/text_area';
// import Sidebar from '../../Components/main_navbar';
// import '../../Components/multi_select.css'; 
// import { PostRequestUpload } from '../../../Controller/post_request';
// import FilePicker from '../../Components/file_picker';
// import Button from '../../Components/button'; 
// import { useNavigate, useLocation } from 'react-router-dom';
// import { showToast } from '../../Components/toast'; // Import the showToast function
// import { categoryMapping } from '../../Components/category';

// const PostRequest = () => {
//   const [productName, setProductName] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [tradeRollType, setTradeRollType] = useState('');
//   const [detailedRequirement, setDetailedRequirement] = useState('');
//   const [uploadedFileURL, setUploadedFileURL] = useState(null); 
//   const [isChecked, setIsChecked] = useState(false);
//   const [loading, setLoading] = useState(false); 
//   const [validationErrors, setValidationErrors] = useState({}); // State for validation errors
//   const navigate = useNavigate(); 
//   const location = useLocation(); 

 

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };

//   const handleFileChange = (label, url) => {
//     setUploadedFileURL(url); 
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!productName.trim()) errors.productName = 'Enter product name!';
//     if (!quantity.trim()) errors.quantity = 'Enter the required quantity!';
//     if (isNaN(quantity) || !Number.isInteger(Number(quantity))) {
//       errors.quantity = 'Quantity must be a valid integer!';
//     }
//     if (!tradeRollType.trim()) errors.tradeRollType = 'Choose the trade role type!';
//     if (!detailedRequirement.trim()) errors.detailedRequirement = 'Provide more details regarding your requirement!';
    
//     // Add validation for uploadedFileURL
//     if (!uploadedFileURL) {
//       errors.uploadedFileURL = 'Please upload a PDF file';
//       showToast('Please upload a PDF file', 'error');
//     }
  
//     setValidationErrors(errors);
//     return Object.keys(errors).length === 0;
//   };
  
//   const handleSubmit = async (event) => {
//     if (event) event.preventDefault(); // Ensure preventDefault is called
//     setLoading(true);
  
//     if (!validateForm()) {
//       setLoading(false);
//       return;
//     }
    
//     const tradeRollTypeId = Object.keys(categoryMapping).find(
//       key => categoryMapping[key] === tradeRollType
//     );
  
//     try {
//       const response = await PostRequestUpload(
//         productName,
//         quantity,
//         localStorage.getItem('categoryId'), 
//         tradeRollType,
//         detailedRequirement,
//         uploadedFileURL,
//         isChecked
//       );
  
//       console.log('Post request response:', response);
  
//       if (response.status === 201) {
//         const newPostData = response.data;
//         navigate('/posts', { state: { newPostData } });
//       } else {
//         console.error('Failed to submit item. Response:', response);
//       }
//     } catch (error) {
//       console.error('Error submitting item:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // useEffect(() => {
//   //   const storedCategory = localStorage.getItem('category'); 
//   //   if (storedCategory) {
//   //     setTradeRollType(storedCategory);
//   //   }
  
//   //   if (location.state?.selectedTradeRole) {
//   //     setTradeRollType(location.state.selectedTradeRole);
  
//   //     // Set read-only mode only if coming from MainHome with 'Buyer' role
//   //     if (location.state.selectedTradeRole === 'Buyer') {
//   //       setIsBuyerRole(true);
//   //     }
//   //   } else {
//   //     setIsBuyerRole(false); // Reset if not coming from MainHome
//   //   }
//   // }, [location.state]);
//   // useEffect(() => {
    
//   //   // Retrieve categoryId from location state instead of localStorage
//   //   if (location.state?.categoryId) {
//   //     setTradeRollType(location.state.categoryId); // Set the categoryId
//   //   }
  
//   //   if (location.state?.selectedTradeRole) {
//   //     setTradeRollType(location.state.selectedTradeRole); // Set the display name for the label
//   //   }
//   // }, [location.state]);

//   useEffect(() => {
//   if (location.state) {
//     const { selectedTradeRole, categoryId, formData } = location.state;
    
//     if (formData) {
//       setProductName(formData.productName || '');
//       setQuantity(formData.quantity || '');
//       setDetailedRequirement(formData.detailedRequirement || '');
//       setUploadedFileURL(formData.uploadedFileURL || null);
//       setIsChecked(formData.isChecked || false);
//     }

//     if (selectedTradeRole) {
//       setTradeRollType(selectedTradeRole);
//     }

//   }
// }, [location.state]);

//   const [isBuyerRole, setIsBuyerRole] = useState(false);

//   // const handleTradeRollTypeClick = () => {
//   //   navigate('/hex-grid', {
//   //     state: {
//   //       selectedTradeRole: tradeRollType,
//   //       productName,
//   //       quantity,
//   //       detailedRequirement,
//   //       uploadedFileURL,
//   //       isChecked
//   //     }
//   //   });
//   // };
//   const handleTradeRollTypeClick = () => {
//     // Navigate to hex-grid page with current form data
//     navigate('/hex-grid', {
//       state: {
//         formData: {
//           productName,
//           quantity,
//           detailedRequirement,
//           uploadedFileURL,
//           isChecked
//         }
//       }
//     });
//   };
  
//   // Handle input to only allow numeric values in the quantity field
//   const handleQuantityChange = (e) => {
//     const input = e.target.value;
//     if (/^\d*$/.test(input)) { // This regex only allows digits (no decimals or letters)
//       setQuantity(input);
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto">
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-black font-semibold text-xl">Post Request</h1>
//         </div>
//       </div>
//       <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
//       <Sidebar />
//       <div className="lg:w-4/5 mx-5 lg:ml-5 justify-center">
//         <div className="flex-1 p-2 max-w-2xl mx-auto mt-5 overflow-y-auto">
//           <form onSubmit={handleSubmit}>
//             <div className="mb-2">
//               <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Product Name/Direct Company/Service Requested</label>
//               <InputBox 
//                 borderRadius='1px'
//                 value={productName}
//                 onChange={(e) => setProductName(e.target.value)}
//                 maxLength={30}
//               />
//               {validationErrors.productName && (
//                 <p className="text-red-500 text-xs">{validationErrors.productName}</p>
//               )}
//             </div>
//             <div className="mb-2">
//               <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Required Quantity / More details requested service</label>
//               <InputBox 
//                 borderRadius='1px'
//                 value={quantity}
//                 onChange={handleQuantityChange} // Ensure only numeric values are entered
//               />
//               {validationErrors.quantity && (
//                 <p className="text-red-500 text-xs">{validationErrors.quantity}</p>
//               )}
//             </div>
//             <div className="mb-2">
//               <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Select Type of Trade Roll</label>
//               <InputBox 
//                 borderRadius='1px'
//                 value={tradeRollType}
//                 readOnly={isBuyerRole} // Only read-only if coming from MainHome as 'Buyer'
//                 onClick={!isBuyerRole ? handleTradeRollTypeClick : undefined} // Allow clicking to change if not read-only
//               />
//               {validationErrors.tradeRollType && (
//                 <p className="text-red-500 text-xs">{validationErrors.tradeRollType}</p>
//               )}
//             </div>

//             <div className="mb-1 relative">
//               <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Detailed Requirement</label>
//               <TextArea
//                 value={detailedRequirement}
//                 onChange={(e) => setDetailedRequirement(e.target.value)}
//                 placeholder="Describe your detailed requirements here..."
//                 className="w-full p-3 border border-[#A2A2A2] rounded-lg h-40"
//                 maxLength={250}
//               />
//               {validationErrors.detailedRequirement && (
//                 <p className="text-red-500 text-xs">{validationErrors.detailedRequirement}</p>
//               )}
//             </div>

//             <label className="block text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Requirement pdf</label>
//             <FilePicker 
//               file={uploadedFileURL}
//               onFileChange={handleFileChange}
//               width="w-full"
//               height="h-20"
//             />
//             {validationErrors.uploadedFileURL && (
//               <p className="text-red-500 text-xs">{validationErrors.uploadedFileURL}</p>
//             )}

//             <div className="flex flex-wrap items-start mt-12">
//               <input 
//                 type="checkbox" 
//                 value="International" 
//                 onChange={handleCheckboxChange}
//                 className="form-checkbox text-red-500"
//                 style={{
//                   minWidth: '1.5rem',
//                   minHeight: '1.5rem',
//                   width: '1.5rem',
//                   height: '1.5rem',
//                   flexShrink: 0,
//                   marginTop: '0.1rem'
//                 }}
//               />
//               <label className="ml-2 lg:text-[14px] text-[12px] text-[#4A4A4A] flex-1">
//                 Agree to receive notifications and messages against this request.
//               </label>
//             </div>

//             <div className="text-center w-full mt-7">
//               <Button
//                 onClick={(event) => handleSubmit(event)} // Ensure the event object is passed
//                 text="Post"
//                 borderColor="border-transparent"
//                 backgroundColor="bg-[#DB0000]"
//                 textColor="text-white"
//                 loading={loading} // Pass the loading state
//               />
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PostRequest;

import React, { useState, useEffect } from 'react';
import InputBox from '../../Components/input_box';
import TextArea from '../../Components/text_area';
import Sidebar from '../../Components/main_navbar';
import '../../Components/multi_select.css'; 
import { PostRequestUpload } from '../../../Controller/post_request';
import FilePicker from '../../Components/file_picker';
import Button from '../../Components/button'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { showToast } from '../../Components/toast'; // Import the showToast function
import { categoryMapping } from '../../Components/category';




const PostRequest = () => {
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [tradeRollType, setTradeRollType] = useState('');
  const [detailedRequirement, setDetailedRequirement] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState(null); 
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate(); 
  const location = useLocation(); 
  const [categoryId, setCategoryId] = useState(null);  // To store the categoryId
  const [isFromHome, setIsFromHome] = useState(false);
  // Handle navigation from the Hexgrid and prepopulate fields

  

  useEffect(() => {
    if (location.state) {
      const { selectedTradeRole, categoryId, formData, fromHome } = location.state;

      if (formData) {
        setProductName(formData.productName || '');
        setQuantity(formData.quantity || '');
        setDetailedRequirement(formData.detailedRequirement || '');
        setUploadedFileURL(formData.uploadedFileURL || null);
        setIsChecked(formData.isChecked || false);
      }

      if (selectedTradeRole) {
        setTradeRollType(selectedTradeRole);
      }

      if (categoryId) {
        setCategoryId(categoryId);
      }

      setIsFromHome(fromHome || false);
    }
  }, [location.state]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileChange = (label, url) => {
    setUploadedFileURL(url); 
  };

  const validateForm = () => {
    const errors = {};
    if (!productName.trim()) errors.productName = 'Enter product name!';
    if (!quantity.trim()) errors.quantity = 'Enter the required quantity!';
    if (isNaN(quantity) || !Number.isInteger(Number(quantity))) {
      errors.quantity = 'Quantity must be a valid integer!';
    }
    if (!tradeRollType.trim()) errors.tradeRollType = 'Choose the trade role type!';
    if (!detailedRequirement.trim()) errors.detailedRequirement = 'Provide more details regarding your requirement!';
    
    if (!uploadedFileURL) {
      errors.uploadedFileURL = 'Please upload a PDF file';
      // showToast('Please upload a PDF file', 'error');
    }
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      const response = await PostRequestUpload(
        productName,
        quantity,
        categoryId,
        tradeRollType,
        detailedRequirement,
        uploadedFileURL,
        isChecked
      );

      console.log('Post request response:', response);

      if (response.status === 201) {
        const newPostData = response.data;
        navigate('/posts', { state: { newPostData } });
      } else {
        console.error('Failed to submit item. Response:', response);
      }
    } catch (error) {
      console.error('Error submitting item:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleTradeRollTypeClick = () => {
    if (!isFromHome) {
      navigate('/hex-grid', {
        state: {
          formData: {
            productName,
            quantity,
            detailedRequirement,
            uploadedFileURL,
            isChecked
          }
        }
      });
    }
  };
  const handleQuantityChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setQuantity(input);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto">
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold text-xl">Post Request</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <Sidebar />
      <div className="lg:w-4/5 mx-5 lg:ml-5 justify-center">
        <div className="flex-1 p-2 max-w-2xl mx-auto mt-5 overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Product or Service Name</label>
              <InputBox 
                borderRadius='1px'
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                maxLength={30}
              />
              {validationErrors.productName && (
                <p className="text-red-500 text-xs">{validationErrors.productName}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]"> Required Quantity</label>
              <InputBox 
                borderRadius='1px'
                value={quantity}
                onChange={handleQuantityChange} 
              />
              {validationErrors.quantity && (
                <p className="text-red-500 text-xs">{validationErrors.quantity}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Select the type of trade role</label>
              <InputBox 
            borderRadius='1px'
            value={tradeRollType}
            readOnly={isFromHome}
            onClick={handleTradeRollTypeClick}
          />
              {validationErrors.tradeRollType && (
                <p className="text-red-500 text-xs">{validationErrors.tradeRollType}</p>
              )}
            </div>

            <div className="mb-1 relative">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]"> Requirement in detail</label>
              <TextArea
                value={detailedRequirement}
                onChange={(e) => setDetailedRequirement(e.target.value)}
                placeholder="Describe your detailed requirements here..."
                className="w-full p-3 border border-[#A2A2A2] rounded-lg h-40"
                maxLength={250}
              />
              {validationErrors.detailedRequirement && (
                <p className="text-red-500 text-xs">{validationErrors.detailedRequirement}</p>
              )}
            </div>

            <label className="block text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Requirement pdf</label>
            <FilePicker 
              file={uploadedFileURL}
              onFileChange={handleFileChange}
              width="w-full"
              height="h-20"
            />
            {validationErrors.uploadedFileURL && (
              <p className="text-red-500 text-xs">{validationErrors.uploadedFileURL}</p>
            )}

            <div className="flex flex-wrap items-start mt-12">
              <input 
                type="checkbox" 
                value="International" 
                onChange={handleCheckboxChange}
                className="form-checkbox text-red-500"
                style={{
                  minWidth: '1.5rem',
                  minHeight: '1.5rem',
                  width: '1.5rem',
                  height: '1.5rem',
                  flexShrink: 0,
                  marginTop: '0.1rem'
                }}
              />
              <label className="ml-2 lg:text-[14px] text-[12px] text-[#4A4A4A] flex-1">
                Agree to receive notifications and messages against this request.
              </label>
            </div>

            <div className="text-center w-full mt-7">
              <Button
                onClick={(event) => handleSubmit(event)} 
                text="Post"
                borderColor="border-transparent"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                loading={loading} 
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PostRequest;

