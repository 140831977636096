// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import { Link } from 'react-router-dom';

// const AccountPage = () => {
//   const [notificationsEnabled, setNotificationsEnabled] = useState(false);

//   const handleToggle = () => {
//     setNotificationsEnabled(!notificationsEnabled);
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#ffffff] font-poppins">
//       <Sidebar />
//       <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//         <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Account</h1>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex justify-center items-center w-4/5 mt-10 mx-auto lg:ml-8">
//         <div className="bg-gray-300 rounded-lg w-full md:w-[700px] p-5 md:p-10">
//           <div className="flex justify-between mb-5 ">
//             <div className="flex items-center space-x-3">
//               <img src="Assets/notification.svg" alt="Notification Icon" className="w-5 h-5" />
//               <span className="text-sm md:text-lg font-semibold">Notifications</span>
//             </div>
//             <div
//               className={`cursor-pointer w-7 h-4 md:w-9 md:h-6 flex items-center bg-[#171717] rounded-full p-1 ${notificationsEnabled ? 'bg-[#171717]' : 'bg-[#171717]'}`}
//               onClick={handleToggle}
//             >
//               <div
//                 className={`bg-[#737373] w-3 h-3 md:w-4 md:h-4 rounded-full transform ${notificationsEnabled ? 'translate-x-3 md:translate-x-3' : ''}`}
//               ></div>
//             </div>
//           </div>
          
//           <Link to="/forgot-password-page">
//             <div className="flex items-center space-x-3 cursor-pointer mb-5">
//               <img src="Assets/security.svg" alt="Change Password Icon" className="w-5 h-5" />
//               <span className="text-sm md:text-lg font-semibold">Change Password</span>
//             </div>
//           </Link>
//           <Link to="/details_phone">
//             <div className="flex items-center space-x-3 cursor-pointer mb-5">
//               <img src="/Assets/phone.svg" alt="Change Password Icon" className="w-5 h-5" />
//               <span className="text-sm md:text-lg font-semibold">Delete Account</span>
//             </div>
//           </Link>
//           {/* <Link to="/email">
//             <div className="flex items-center space-x-3 cursor-pointer mb-5">
//               <img src="/email23.svg" alt="Change Password Icon" className="w-5 h-5" />
//               <span className="text-sm md:text-lg font-semibold">Change Email</span>
//             </div>
//           </Link> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AccountPage;
import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import { Link, useNavigate } from 'react-router-dom';
import { deleteCompany } from '../../../Controller/delete_user';
import { update_notification_preference } from '../../../Controller/notification';
import DeletePopup from '../../Components/delete_pop_up'; // Import the DeletePopup component

const AccountPage = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation
  const [isUpdating, setIsUpdating] = useState(false); // State for API update
  const navigate = useNavigate(); // Use navigate to redirect after account deletion

  // Handle notification toggle and call the API
  const handleToggle = async () => {
    const newNotificationStatus = !notificationsEnabled;
    setNotificationsEnabled(newNotificationStatus);

    console.log('Toggling notification, sending value:', newNotificationStatus);

    setIsUpdating(true); // Show a loading state or spinner if needed

    try {
      const response = await update_notification_preference(newNotificationStatus);
      console.log('Notification preferences updated:', response);
    } catch (error) {
      console.error('Error updating notification preferences:', error);
    } finally {
      setIsUpdating(false); // End the loading state
    }
  };

  // Handle account deletion
  const handleDeleteClick = () => {
    setShowConfirmation(true); // Show the delete confirmation popup
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteCompany(); // Call the delete API
      if (response) {
        console.log('Company deleted successfully');
        localStorage.removeItem('company_id'); // Optionally remove company_id from localStorage
        navigate('/'); // Redirect after deletion
      }
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false); // Hide the confirmation popup
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#ffffff] font-poppins">
      <Sidebar />
      <div className="flex justify-start items-center ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
        <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Account</h1>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex justify-center items-center w-4/5 mt-10 mx-auto lg:ml-8">
        <div className="bg-[#E5E5E5] rounded-lg w-full md:w-[700px] p-5 md:p-10">
          <div className="flex justify-between mb-5 ">
            <div className="flex items-center space-x-3">
              <img src="Assets/notification.svg" alt="Notification Icon" className="w-5 h-5" />
              <span className="text-sm md:text-lg font-semibold">Notifications</span>
            </div>
            <div
              className={`cursor-pointer w-7 h-4 md:w-9 md:h-6 flex items-center bg-[#171717] rounded-full p-1 ${notificationsEnabled ? 'bg-[#171717]' : 'bg-[#171717]'}`}
              onClick={handleToggle}
            >
              <div
                className={`bg-[#737373] w-3 h-3 md:w-4 md:h-4 rounded-full transform ${notificationsEnabled ? 'translate-x-3 md:translate-x-3' : ''}`}
              ></div>
            </div>
          </div>

          <Link to="/new_password">
            <div className="flex items-center space-x-3 cursor-pointer mb-5">
              <img src="Assets/change_password.svg" alt="Change Password Icon" className="w-6 h-6" />
              <span className="text-sm md:text-lg font-semibold">Change Password</span>
            </div>
          </Link>

          <div className="flex items-center space-x-3 cursor-pointer mb-5" onClick={handleDeleteClick}>
            <img src="/Assets/bin.svg" alt="Delete Account Icon" className="w-5 h-5" />
            <span className="text-sm md:text-lg font-semibold">Delete Account</span>
          </div>

          {showConfirmation && (
            <DeletePopup
              message="Are you sure you want to delete your account?" // Pass your custom message
              onConfirm={handleConfirmDelete} // Handle confirmation
              onClose={handleCancelDelete} // Handle cancel
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
