// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import CatalogPicker from '../../Components/catalog_picker';
// import Button from '../../Components/button';
// import { useNavigate } from 'react-router-dom';
// import ReportContainer from '../../Components/report';
// import UploadDocuments from '../../../Controller/firebase/firebase_documents';
// import { showToast, Toast } from '../../Components/toast'; 
// import { update_catalogue_api } from '../../../Controller/company_details';

// const AddCatalog = () => {
//   const navigate = useNavigate();
//   const [catalogURL, setCatalogURL] = useState('');
//   const [uploading, setUploading] = useState(false); // State for file uploading
//   const [loading, setLoading] = useState(false); // State for save button loading
  

//   const handleBackClick = () => {
//     navigate('');
//   };

//   const handleFileSelect = async (file) => {
//     setUploading(true); // Set the uploading state to true when file upload starts
//     try {
//       const downloadURL = await UploadDocuments(file);
//       console.log('File uploaded successfully:', downloadURL);
//       setCatalogURL(downloadURL);
//       showToast('File uploaded successfully.', 'success');
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       showToast('Error uploading file.', 'error');
//     } finally {
//       setUploading(false); // Reset uploading state after upload is done
//     }
//   };

//   const handleSaveClick = async () => {
//     if (!catalogURL) {
//       showToast('Please upload a catalog file before saving.', 'error');
//       return;
//     }

//     setLoading(true); // Set loading state to true when save process starts
//     const payload = {
//       catalogue: catalogURL,
//     };

//     try {
//       const response = await update_catalogue_api(payload);
//       console.log('Documents updated successfully:', response);
//       showToast('Catalog saved successfully.', 'success');
//       navigate('/profile1');
//     } catch (error) {
//       console.error('Error updating documents:', error);
//       showToast('Error saving catalog.', 'error');
//     } finally {
//       setLoading(false); // Reset loading state after save process completes
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
//       <Sidebar />
//       <Toast /> {/* Add the Toast component */}
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Catalogue</h1>
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <div className="flex justify-center items-center flex-grow mt-2">
//             <CatalogPicker height="450px" width="450px" onFileSelect={handleFileSelect} loading={uploading} />
//           </div>
//           <div className="mt-8 lg:mx-2 mx-0">
//             <Button
//               text="Save"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSaveClick}
//               loading={loading} // Pass only the loading state for the save process
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddCatalog;

import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import CatalogPicker from '../../Components/catalog_picker';
import Button from '../../Components/button';
import { useNavigate,useLocation } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import UploadDocuments from '../../../Controller/firebase/firebase_documents';
import { showToast, Toast } from '../../Components/toast'; 
import { update_catalogue_api } from '../../../Controller/company_details';

const AddCatalog = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Get the location object
  const [catalogURL, setCatalogURL] = useState(location.state?.catalogueURL || '');
  // const [catalogURL, setCatalogURL] = useState('');
  const [uploading, setUploading] = useState(false); // State for file uploading
  const [loading, setLoading] = useState(false); // State for save button loading
  

  const handleBackClick = () => {
    navigate('');
  };

  const handleFileSelect = async (file) => {
    // Ensure only PDF files can be uploaded
    if (file.type !== 'application/pdf') {
      showToast('Only PDF files are allowed.', 'error');
      return;
    }

    setUploading(true); // Set the uploading state to true when file upload starts
    try {
      const downloadURL = await UploadDocuments(file);
      console.log('File uploaded successfully:', downloadURL);
      setCatalogURL(downloadURL);
      showToast('File uploaded successfully.', 'success');
    } catch (error) {
      console.error('Error uploading file:', error);
      showToast('Error uploading file.', 'error');
    } finally {
      setUploading(false); // Reset uploading state after upload is done
    }
  };

  const handleSaveClick = async () => {
    if (!catalogURL) {
      showToast('Please upload a catalog file before saving.', 'error');
      return;
    }

    setLoading(true); // Set loading state to true when save process starts
    const payload = {
      catalogue: catalogURL,
    };

    try {
      const response = await update_catalogue_api(payload);
      console.log('Documents updated successfully:', response);
      showToast('Catalog saved successfully.', 'success');
      navigate('/profile1');
    } catch (error) {
      console.error('Error updating documents:', error);
      showToast('Error saving catalog.', 'error');
    } finally {
      setLoading(false); // Reset loading state after save process completes
    }
  };

  const handleViewClick = () => {
    // Open the PDF in a new tab
    window.open(catalogURL, '_blank');
  };

  const handleReuploadClick = () => {
    // Clear the current catalog URL to allow re-upload
    setCatalogURL('');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <Toast /> {/* Add the Toast component */}
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
        <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">
      {catalogURL ? 'Edit Catalogue' : 'Catalogue'}
    </h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          {/* Conditional rendering based on whether the file has been uploaded or passed */}
          {!catalogURL ? (
            <div className="flex justify-center items-center flex-grow ">
              <CatalogPicker height="450px" width="450px" onFileSelect={handleFileSelect} loading={uploading} />
            </div>
          ) : (
            <div
              className="relative flex justify-between bg-[#F2F2F2] p-3 rounded-lg cursor-pointer h-[450px]  "
              onClick={handleReuploadClick}
            >
              <div className="flex mt-8  ">
                <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-8 h-8 mr-2" />
                <span className="text-black font-semibold ml-3">Document</span>
              </div>
              {/* Use absolute positioning to place the View button at the top right */}
              <button
                className="absolute mt-8 right-6 text-red-600 font-semibold underline"
                onClick={handleViewClick}
              >
                View
              </button>
            </div>
          )}
          <div className="mt-8 lg:mx-2 mx-0">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSaveClick}
              loading={loading} // Pass only the loading state for the save process
            />
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default AddCatalog;
