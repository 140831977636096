// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { getCompanyReviews } from '../../../Controller/reviews';
// import StarRating from '../../Components/star_rating';
// import Sidebar from '../../Components/main_navbar';
// import Button from '../../Components/button';

// const Adding = () => {
//   const navigate = useNavigate();
//   const [reviews, setReviews] = useState([]);
//   const [overallRating, setOverallRating] = useState(0);
//   const [reviewCount, setReviewCount] = useState(0);

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const company_uid = localStorage.getItem('company_uid'); // Retrieve from localStorage
//         if (!company_uid) {
//           throw new Error("company_uid is not defined in localStorage");
//         }
//         const res = await getCompanyReviews(company_uid, 0, 10); // Pass company_uid to the function
//         console.log("API Response:", res);

//         if (res.status_code === 200) {
//           const { rating_average, review_count, reviews } = res.data;
//           setOverallRating(rating_average);
//           setReviewCount(review_count);
//           setReviews(reviews);
//         }
//       } catch (error) {
//         console.error("Error fetching reviews:", error);
//       }
//     };

//     fetchReviews();
//   }, []);

//   const handleAddReview = () => {
//     navigate('/add_review');
//   };

//   const handleBackClick = () => {
//     navigate('');
//   };

//   const OnClick = () => {
//     navigate('/report_page'); 
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Reviews</h1>
//         </div>
//         <div className="mr-5 md:mr-7 pr-5 flex self-end"> 
//           <img
//             src="/Assets/dots.svg"
//             alt="Upload"
//             className="cursor-pointer h-5 w-5"
//             onClick={OnClick}
//           />
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      
//       <div className="flex flex-col md:flex-row mt-4 mx-4 h-[calc(100vh-100px)]">
//         <div className="bg-[#FFFFFF] p-6 rounded-lg shadow-lg w-full md:w-[300px] flex flex-col items-center justify-center text-center mb-4 md:mb-0">
//           <h1 className="text-6xl font-bold">{overallRating}</h1>
//           <div className="stars mt-2">
//             <StarRating rating={overallRating} />
//           </div>
//           <p className="text-gray-500 mt-2">Based on {reviewCount} Reviews</p>
//           <Button
//             text="Add Review"
//             borderColor="border-red-600"
//             backgroundColor="bg-[#DB0000]"
//             textColor="text-white"
//             onClick={handleAddReview}
//           />
//         </div>

//         <div className="mx-6 border-r-2 border-gray-200 hidden md:block"></div>

//         <div className="bg-[#FFFFFF] p-6 rounded-lg shadow-lg w-full md:w-[600px] overflow-y-auto">
//           {reviews.length > 0 ? (
//             reviews.map((review, index) => (
//               <div key={index} className="mb-6 border-b pb-4 flex items-start">
//                 <img 
//                   src={review.reviewer_profile_image || 'default-avatar.png'} 
//                   alt="Reviewer" 
//                   className="w-12 h-12 rounded-full mr-4"
//                 />
//                 <div className="flex-1">
//                   <div className="flex justify-between">
//                     <div>
//                       <h3 className="font-bold">{review.reviewer_company_name}</h3>
//                       <p className="text-sm text-gray-500">{review.title}</p>
//                     </div>
//                     <span className="text-sm text-gray-500">{new Date(review.created_on).toLocaleDateString()}</span>
//                   </div>
//                   <p className="mt-2 text-gray-700">{review.description}</p>
//                   {review.image_url && review.image_url.length > 0 && (
//                     <div className="mt-2">
//                       {review.image_url.map((img, imgIndex) => (
//                         <img key={imgIndex} src={img} alt="Review" className="w-20 h-20 object-cover mr-2 inline-block" />
//                       ))}
//                     </div>
//                   )}
//                   <div className="mt-2">
//                     <StarRating rating={review.rating} />
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <p>No reviews available.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Adding;
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { getCompanyReviews, deleteReview } from '../../../Controller/reviews';
// import StarRating from '../../Components/star_rating';
// import Sidebar from '../../Components/main_navbar';
// import Button from '../../Components/button';
// import DeletePopup from '../../Components/delete_pop_up';

// const Adding = () => {
//   const navigate = useNavigate();
//   const [reviews, setReviews] = useState([]);
//   const [overallRating, setOverallRating] = useState(0);
//   const [reviewCount, setReviewCount] = useState(0);
//   const [selectedReviewId, setSelectedReviewId] = useState(null);
//   const [showDeletePopup, setShowDeletePopup] = useState(false);
//   const [showDeleteText, setShowDeleteText] = useState(null);
  

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
       
//         // const company_uid = localStorage.getItem('company_uid'); 
//         const company_uid = 'cid_9204722eece2480cb310ae0e1f2dad58'; 
//         if (!company_uid) {
//           throw new Error("company_uid is not defined in localStorage");
//         }
//         const res = await getCompanyReviews(company_uid, 0, 10); 
//         console.log("API Response:", res);

//         if (res.status_code === 200) {
//           const { rating_average, review_count, reviews } = res.data;
//           setOverallRating(rating_average);
//           setReviewCount(review_count);
//           setReviews(reviews);
//         }
//       } catch (error) {
//         console.error("Error fetching reviews:", error);
//       }
//     };

//     fetchReviews();
//   }, []);

//   const handleAddReview = () => {
//     navigate('/add_review');
//   };

//   const handleBackClick = () => {
//     navigate('');
//   };

//   const OnClick = () => {
//     navigate('/report_page'); 
//   };

//   const handleDeleteClick = (reviewId) => {
//     setSelectedReviewId(reviewId);
//     setShowDeletePopup(true);
//   };

//   const handleConfirmDelete = async () => {
//     try {
//       if (selectedReviewId) {
//         const res = await deleteReview(selectedReviewId);
//         if (res.status_code === 202) {
//           setReviews((prevReviews) => prevReviews.filter(review => review.id !== selectedReviewId));
//           alert("Review deleted successfully!");
//         }
//       }
//     } catch (error) {
//       console.error("Error deleting review:", error);
//       alert("Failed to delete review. Please try again.");
//     } finally {
//       setShowDeletePopup(false);
//       setSelectedReviewId(null);
//     }
//   };

//   const handleClosePopup = () => {
//     setShowDeletePopup(false);
//     setSelectedReviewId(null);
//   };

//   const handleThreeDotsClick = (reviewId) => {
//     if (showDeleteText === reviewId) {
//       setShowDeleteText(null);
//     } else {
//       setShowDeleteText(reviewId);
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//     <Sidebar />
//     <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//       <div className="w-full flex justify-start items-center space-x-2">
//         <img
//           src="/Assets/arrow_back.svg"
//           alt="Back"
//           className="cursor-pointer mr-2 h-6 w-6"
//           onClick={handleBackClick}
//         />
//         <h1 className="text-black font-bold text-xl">Reviews</h1>
//       </div>
//       <div className="mr-5 md:mr-7 pr-5 flex self-end"> 
//         <img
//           src="/Assets/dots.svg"
//           alt="Upload"
//           className="cursor-pointer h-5 w-5"
//           onClick={OnClick}
//         />
//       </div>
//     </div>
//     <div className="border-b border-black mt-4 md:mt-[25px]"></div>
    
//     <div className="flex flex-col md:flex-row mt-4 mx-4 h-[calc(100vh-100px)]">
//       <div className="bg-[#FFFFFF] p-6 rounded-lg shadow-lg w-full md:w-[300px] flex flex-col items-center justify-center text-center mb-4 md:mb-0">
//         <h1 className="text-6xl font-bold">{overallRating}</h1>
//         <div className="stars mt-2">
//           <StarRating rating={overallRating} />
//         </div>
//         <p className="text-gray-500 mt-2">Based on {reviewCount} Reviews</p>
//         <Button
//           text="Add Review"
//           borderColor="border-red-600"
//           backgroundColor="bg-[#DB0000]"
//           textColor="text-white"
//           onClick={handleAddReview}
//         />
//       </div>

//       <div className="mx-6 border-r-2 border-gray-200 hidden md:block"></div>

//       <div className="bg-[#FFFFFF] p-6 rounded-lg shadow-lg w-full md:w-[600px] overflow-y-auto">
//         {reviews.length > 0 ? (
//           reviews.map((review, index) => (
//             <div key={index} className="mb-6 border-b pb-4 flex items-start relative">
//               <img 
//                 src={review.reviewer_profile_image || 'default-avatar.png'} 
//                 alt="Reviewer" 
//                 className="w-12 h-12 rounded-full mr-4"
//               />
//               <div className="flex-1 flex flex-col">
//                 <div className="flex justify-between">
//                   <div>
//                     <h3 className="font-bold">{review.reviewer_company_name}</h3>
//                     <p className="text-sm text-gray-500">{review.title}</p>
//                   </div>
//                 </div>
//                 <p className="mt-2 text-gray-700">{review.description}</p>
//                 {review.image_url && review.image_url.length > 0 && (
//                   <div className="mt-2">
//                     {review.image_url.map((img, imgIndex) => (
//                       <img key={imgIndex} src={img} alt="Review" className="w-20 h-20 object-cover mr-2 inline-block" />
//                     ))}
//                   </div>
//                 )}
//                 {/* Display the confirmation document if it exists */}
//                 {review.confirmation_document && (
//                   <div className="mt-2">
//                     <a 
//                       href={review.confirmation_document} 
//                       target="_blank" 
//                       rel="noopener noreferrer" 
//                       className="text-blue-600 underline"
//                     >
//                       View Confirmation Document
//                     </a>
//                   </div>
//                 )}
//                 <div className="mt-2 flex justify-between">
//                   <StarRating rating={review.rating} />
//                   <span className="text-sm text-gray-500 self-end">{new Date(review.updated_on).toLocaleDateString()}</span>
//                 </div>
//               </div>
//               {review.reviewer === 'cid_856e2c2935314f89b32685adb166ad41' && (
//                 <div className="absolute top-0 right-0">
//                   <img
//                     src="/Assets/dots.svg"
//                     alt="Options"
//                     className="cursor-pointer h-5 w-5"
//                     onClick={() => handleThreeDotsClick(review.id)}
//                   />
//                   {showDeleteText === review.id && (
//                     <div className="absolute right-0 mt-2 p-2 bg-white shadow-lg border rounded">
//                       <p 
//                         className="cursor-pointer text-red-600" 
//                         onClick={() => handleDeleteClick(review.id)}
//                       >
//                         Delete
//                       </p>
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))
//         ) : (
//           <p>No reviews available.</p>
//         )}
//       </div>
//     </div>

//     {showDeletePopup && (
//       <DeletePopup
//         message="Are you sure you want to delete this review?"
//         onConfirm={handleConfirmDelete}
//         onClose={handleClosePopup}
//       />
//     )}
//   </div>
// );
// };
// export default Adding;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { getCompanyReviews, deleteReview } from '../../../Controller/reviews';
import StarRating from '../../Components/star_rating';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import DeletePopup from '../../Components/delete_pop_up';
import ReportContainer from '../../Components/report';
import { FaSpinner } from 'react-icons/fa';
import ImageModal from '../../Components/image_modal';

const Adding = () => {
  const { uid } = useParams(); // Extract uid from the URL params
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [overallRating, setOverallRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteText, setShowDeleteText] = useState(null);
  const [isMyProfile, setIsMyProfile] = useState(false); // Check if it's the user's own profile
  const [loading, setLoading] = useState(true);
  const [fullImageUrl, setFullImageUrl] = useState(null); 


  const loggedInCompanyUid = localStorage.getItem('company_uid'); // Get the logged-in user's company UID from localStorage

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true); // Start loading
      try {
        let companyUid = null;

        // If uid exists in the URL, it's another profile
        if (uid) {
          companyUid = uid;
          setIsMyProfile(false); // Viewing another profile
        } else {
          // If no uid in the URL, fetch user's own profile by company_uid from localStorage
          companyUid = loggedInCompanyUid;
          setIsMyProfile(true); // Viewing own profile
        }

        if (!companyUid) {
          throw new Error("Company UID is not available.");
        }

        const res = await getCompanyReviews(companyUid, 0, 10);
        console.log("API Response:", res);

        if (res.status_code === 200) {
          const { rating_average, review_count, reviews } = res.data;
          setOverallRating(rating_average);
          setReviewCount(review_count);
          setReviews(reviews);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false); // Stop loading after data is fetched, in both success and failure cases
      }
    };

    fetchReviews();
  }, [uid, loggedInCompanyUid]); 
  // Add uid as a dependency to re-fetch when the URL changes
  const handleImageClick = (imageUrl) => {
    setFullImageUrl(imageUrl); // Set the image URL to display in the modal
  };

  const handleCloseImageModal = () => {
    setFullImageUrl(null); // Close the modal
  };

  const handleAddReview = () => {
    // Check if uid is available in the URL params or from localStorage
    const companyUid = uid || loggedInCompanyUid;
    
    if (companyUid) {
      // Navigate to add_review_page with the uid as a URL parameter
      navigate(`/add_review_page/${companyUid}`);
    } else {
      console.error("No Company UID found");
    }
  };
  
  const handleBackClick = () => {
    navigate('/');
  };

  

  const handleDeleteClick = (reviewId) => {
    setSelectedReviewId(reviewId);
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (selectedReviewId) {
        const res = await deleteReview(selectedReviewId);
        if (res.status_code === 202) {
          setReviews((prevReviews) => prevReviews.filter(review => review.id !== selectedReviewId));
          alert("Review deleted successfully!");
        }
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      alert("Failed to delete review. Please try again.");
    } finally {
      setShowDeletePopup(false);
      setSelectedReviewId(null);
    }
  };

  const handleClosePopup = () => {
    setShowDeletePopup(false);
    setSelectedReviewId(null);
  };

  const handleThreeDotsClick = (reviewId) => {
    if (showDeleteText === reviewId) {
      setShowDeleteText(null);
    } else {
      setShowDeleteText(reviewId);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
         
          <h1 className="text-black font-semibold text-xl">Reviews</h1>
        </div>
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col md:flex-row mt-4 mx-4 h-[calc(100vh-100px)]">
        <div className="bg-[#FFFFFF] lg:p-6 rounded-lg  w-full md:w-[300px] flex flex-col items-center justify-center text-center mb-4 md:mb-0">
          <h1 className="text-6xl font-bold font-techna">{overallRating}</h1>
          <div className="stars mt-2">
            <StarRating rating={overallRating} />
          </div>
          <p className="text-[#263238] font-techna font-normal lg:text-[18px] sm:text-[16px] mt-2">Based on {reviewCount} Reviews</p>

          {/* Hide "Add Review" button if it's not the user's own profile */}
          {!isMyProfile && (
            <Button
              text="Add Review"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleAddReview}
            />
          )}
        </div>

        <div className="mx-6 border-r-2 border-gray-200 hidden md:block"></div>

        <div className="bg-[#FFFFFF] lg:p-6 rounded-lg  w-full md:w-[600px] overflow-y-auto">
        {loading ? (
            <div className="flex justify-center items-center h-full">
              <FaSpinner className="animate-spin text-[#DB0000]" size={30} />
            </div>
          ) : reviews.length > 0 ? (
            reviews.map((review, index) => (
              <div key={index} className="mb-6 border-b pb-4 flex items-start relative">
                <img
                  src={review.reviewer_profile_image || 'default-avatar.png'}
                  alt="Reviewer"
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div className="flex-1 flex flex-col">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="font-normal font-techna lg:text-[18px] sm:text-[14px] text-[#263238]">{review.reviewer_company_name}</h3>
                      <p className="text-sm text-[#232323]">{review.title}</p>
                    </div>
                  </div>
                  <p className="mt-2 text-[#232323] text-[12px] font-poppins font-normal">{review.description}</p>
                  {review.image_url && review.image_url.length > 0 && (
                    <div className="mt-2">
                      {review.image_url.map((img, imgIndex) => (
                        <img
                          key={imgIndex}
                          src={img}
                          alt="Review"
                          className="w-20 h-20 object-cover mr-2 inline-block cursor-pointer"
                          onClick={() => handleImageClick(img)} // Set the full image view onClick
                        />
                      ))}
                    </div>
                  )}
                  {review.confirmation_document && (
                    <div className="mt-2">
                      <a
                        href={review.confirmation_document}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline text-xs font-gotham "
                      >
                        View Confirmation Document
                      </a>
                    </div>
                  )}
                  <div className="mt-2 flex justify-between">
                    <StarRating rating={review.rating} />
                    <span className="lg:text-[13px] text-[10px] text-[#232323] self-end">{new Date(review.updated_on).toLocaleDateString()}</span>
                  </div>
                </div>

                {/* Show delete option only if the reviewer is the logged-in user's company */}
                {review.reviewer === loggedInCompanyUid && (
                  <div className="absolute top-0 right-0">
                    <img
                      src="/Assets/dots.svg"
                      alt="Options"
                      className="cursor-pointer h-5 w-5"
                      onClick={() => handleThreeDotsClick(review.id)}
                    />
                    {showDeleteText === review.id && (
                      <div className="absolute right-0 mt-2 p-2 bg-white  border rounded">
                        <p className="cursor-pointer text-red-600" onClick={() => handleDeleteClick(review.id)}>
                          Delete
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center h-full">
    <p className="text-center">No reviews to show.</p>
  </div>
          )}
        </div>
      </div>
      {fullImageUrl && <ImageModal imageUrl={fullImageUrl} onClose={handleCloseImageModal} />}

      {showDeletePopup && (
        <DeletePopup
          message="Are you sure you want to delete this review?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Adding;
