import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to get reporting_uid
import InputBox from './input_box';
import TextArea from './text_area';
import Button from './button';
import AddDocument from './add_doc';
import { post_company_report } from '../../Controller/report'; // Import post_company_report API
import uploadImage from '../../Controller/firebase/firebase_upload_image';
import { AiOutlineClose } from 'react-icons/ai'; // Import the close icon

const CompanyReport = () => {
  const navigate = useNavigate();
  const { reporting_uid } = useParams(); // Get reporting_uid from the URL params
  const [title, settitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (!title || !description) {
      console.error('Title and description are required');
      return;
    }

    setLoading(true);
    try {
      let imageUrl = '';
      if (image) {
        imageUrl = await uploadImage(image); // Upload the image and get the URL
      }
      
      // Call post_company_report API with reporting_uid
      const response = await post_company_report(reporting_uid, title, description, [imageUrl]); // Pass imageUrl as an array
      
      if (response.status === 201) {
        console.log(response);
        // Handle success
      } else if (response.status === 422) {
        // Handle validation errors
      }
    } catch (error) {
      console.error('Error submitting the company report:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (file) => {
    setImage(file);
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-40"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg mx-4 flex flex-col">
          <div className="flex justify-between p-6">
            <h1 className="text-lg font-semibold">Report</h1>
            <button className="text-gray-400" onClick={handleBackClick}>
              <AiOutlineClose className="w-5 h-5 text-black" />
            </button>
          </div>
          <div className="border-b border-black"></div>
          <div className="p-6">
            <InputBox
              type="text"
              value={title}
              onChange={(e) => settitle(e.target.value)}
              placeholder="Subject"
              borderRadius="8px"
            />
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              className="mt-6"
              backgroundColor="#F2F2F2"
              borderRadius="8px"
            />
            <div className="mt-2">
              <AddDocument label="Add a screenshot" onFileUpload={handleFileUpload} accept="image/*" />
            </div>
            <div className="mt-5 py-6">
              <Button
                text="Report"
                borderColor="border-red-600"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                onClick={handleSubmit}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyReport;
