import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main_navbar';
import ComplaintCard from '../../Components/complaint_card';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import Tab from '../../Components/tab';
import InputBox from '../../Components/input_box';
import ImageUpload from '../../Components/image_upload';
import AddDocument from '../../Components/add_doc';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import { getComplaintsAddedMyself, postReply, deleteReply, deleteComplaintById } from '../../../Controller/complaints';
import { FaSpinner, FaEdit, FaTrash } from 'react-icons/fa';
import { Toast } from '../../Components/toast';
import { showToast } from '../../Components/toast';
import DeletePopup from '../../Components/delete_pop_up';
import EditReplyPopup from '../../Components/edit_reply_popup';
import { getComplaintById } from '../../../Controller/complaints';
import { updateComplaint } from '../../../Controller/complaints';
import ReviewImageUpload from '../../Components/review_image_add';
import LoaderSpinner from '../../Components/loader_spinner';
import { useRef } from 'react';
import { getComplaintsAgainstCompany } from '../../../Controller/complaints';
import ImagePopup from '../../Components/image_modal';
const Complaints = ({ owner }) => {
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [reply, setReply] = useState("");
    const [replies, setReplies] = useState([]);
    const [activeTab, setActiveTab] = useState('submitted');
    const [showForm, setShowForm] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileDetail, setShowMobileDetail] = useState(false);
    const [receivedComplaints, setReceivedComplaints] = useState([]);
    const [submittedComplaints, setSubmittedComplaints] = useState([]);
    const [canReply, setCanReply] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [page, setPage] = useState(0); 
    const [isEditingReply, setIsEditingReply] = useState(false);
    const [editingReplyIndex, setEditingReplyIndex] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editReplyPopupVisible, setEditReplyPopupVisible] = useState(false);
    const [replyToEdit, setReplyToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [productName, setProductName] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [reviewImages, setReviewImages] = useState([]);
    const [hasReplied, setHasReplied] = useState(false);
    const [showReplyBox, setShowReplyBox] = useState(true); // State to control visibility of the reply box
    const [showDeleteComplaintPopup, setShowDeleteComplaintPopup] = useState(false); // Show/Hide delete popup for complaint
const [complaintToDelete, setComplaintToDelete] = useState(null); // Store the complaint to be deleted
const [uniqueComplaintIds, setUniqueComplaintIds] = useState(new Set());
const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
const [selectedImageUrl, setSelectedImageUrl] = useState('');



    const observerRef = useRef();
    const leftSectionRef = useRef();
    const navigate = useNavigate();
    const company_uid = localStorage.getItem('company_uid'); // Retrieve company_uid from local storage
    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };

        const observer = new IntersectionObserver(handleIntersection, option);
        if (observerRef.current) observer.observe(observerRef.current);

        return () => {
            if (observerRef.current) observer.unobserve(observerRef.current);
        };
    }, [loading, hasMore]);

    const handleIntersection = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        fetchComplaints();
    }, [activeTab, company_uid, page]);

    useEffect(() => {
        if (selectedComplaint) {
            fetchComplaintDetails();
            console.log("Replies:", replies); // Debug log
        }
    }, [selectedComplaint]);

    const fetchComplaints = async () => {
        if (!hasMore) return;
        setLoading(true);
        try {
            if (activeTab === 'submitted') {
                const response = await getComplaintsAddedMyself(page, 5);
                console.log('Submitted Complaints Response:', response);
                if (response && response.data && response.data.data) {
                    const fetchedComplaints = response.data.data.map(complaint => ({
                        ...complaint,
                        id: complaint.id,
                        complaint_by_name: complaint.complaint_to_name,
                        complaint_by_profile_image: complaint.complaint_to_profile_image || '/profile.webp',
                        company_id: complaint.complaint_to_id,


                    }));
    
                    // Filter out duplicate complaints based on the unique complaint ID
                    const uniqueComplaints = fetchedComplaints.filter(complaint => !uniqueComplaintIds.has(complaint.id));
    
                    // Add unique complaint IDs to the Set
                    uniqueComplaints.forEach(complaint => uniqueComplaintIds.add(complaint.id));
    
                    setSubmittedComplaints(prev => [...prev, ...uniqueComplaints]);
                    setHasMore(fetchedComplaints.length === 5);
                    showToast('Submitted complaints loaded successfully!', 'success');
                } else {
                    setHasMore(false);
                    if (page === 0) setSubmittedComplaints([]);
                    showToast('No more submitted complaints found.', 'default');
                }
            } else if (activeTab === 'received') {
                const response = await getComplaintsAgainstCompany(company_uid, page, 5);
                console.log('Received Complaints Response:', response);
                if (response && response.data && response.data.data) {
                    const fetchedComplaints = response.data.data.map(complaint => ({
                        ...complaint,
                        id: complaint.id,
                        complaint_by_name: complaint.complaint_by_name,
                        complaint_by_profile_image: complaint.complaint_by_profile_image || '/profile.webp',
                        company_id: complaint.complaint_by_company_id
                        ,

                    }));
    
                    // Filter out duplicate complaints based on the unique complaint ID
                    const uniqueComplaints = fetchedComplaints.filter(complaint => !uniqueComplaintIds.has(complaint.id));
    
                    // Add unique complaint IDs to the Set
                    uniqueComplaints.forEach(complaint => uniqueComplaintIds.add(complaint.id));
    
                    setReceivedComplaints(prev => [...prev, ...uniqueComplaints]);
                    setHasMore(fetchedComplaints.length === 5);
                    showToast('Received complaints loaded successfully!', 'success');
                } else {
                    setHasMore(false);
                    if (page === 0) setReceivedComplaints([]);
                    showToast('No more received complaints found.', 'default');
                }
            }
        } catch (error) {
            console.error("Error fetching complaints:", error);
            showToast('Failed to load complaints.', 'error');
            setHasMore(false);
            if (page === 0) {
                if (activeTab === 'submitted') setSubmittedComplaints([]);
                if (activeTab === 'received') setReceivedComplaints([]);
            }
        } finally {
            setLoading(false);
        }
    };
    
    const handleImageClick = (imageUrl) => {
        setSelectedImageUrl(imageUrl);  // Set the clicked image's URL
        setIsImagePopupOpen(true);      // Open the image popup
    };
    
  
    const handleClosePopup = () => {
        setIsImagePopupOpen(false);  // Close the image popup
    };
    
    

    const fetchComplaintDetails = async () => {
        setLoading(true);
        try {
            const response = await getComplaintById(selectedComplaint.id);
            if (response.data) {
                const complaintReplies = response.data.replies || [];  // Ensure replies are an array
                setReplies(complaintReplies);  // Set replies correctly
                setShowReplyBox(!(complaintReplies.length > 0));  // Show reply box only if no replies exist
            }
        } catch (error) {
            console.error("Failed to fetch complaint details:", error);
        } finally {
            setLoading(false);
        }
    };
    
    
    
    useEffect(() => {
        if (selectedComplaint) {
            fetchComplaintDetails();
        }
    }, [selectedComplaint]);
    
    const handleViewComplaint = async (complaint) => {
        try {
            const response = await getComplaintById(complaint.id);
            console.log('Fetched Complaint Data:', response.data);
    
            if (response.data && response.data.data && response.data.data.length > 0) {
                const complaintData = response.data.data[0];
    
                const fetchedComplaint = {
                    id: complaintData.id,
                    complaint_by_name: complaintData.complaint_by_name || 'Name',
                    complaint_to_name: complaintData.complaint_to_name || 'Name',

                    complaint_by_profile_image: complaintData.complaint_by_profile_image || '/profile.webp',
                    title: complaintData.title || 'Complaint Title/Subject',
                    description: complaintData.description || 'Description not provided',
                    created_on: complaintData.created_on || 'Invalid Date',
                    product_name: complaintData.product_name || 'Product Name',
                    image_url: complaintData.image_url || [],
                    confirmation_document: complaintData.confirmation_document || '',
                    // Fetch reply if it's available in the complaint data
                    reply: complaintData.reply || null,
                    replied_on: complaintData.replied_on || null,
                    company_id: complaintData.complaint_to_company_id || complaintData.complaint_by_company_id,  // Ensure company_id is set correctly

                };
    
                console.log('Selected Complaint:', fetchedComplaint);
    
                setSelectedComplaint(fetchedComplaint);
                setReplies(complaintData.reply ? (Array.isArray(complaintData.reply) ? complaintData.reply : [complaintData.reply]) : []);
                setShowForm(false);
                setCanReply(activeTab === 'received');
                console.log('Can reply set to:', activeTab === 'received'); // Add this log
                setHasReplied(complaintData.reply && complaintData.reply.length > 0);
                if (isMobile) {
                    setShowMobileDetail(true);
                }
            } else {
                console.error("No complaint data found.");
                showToast('Failed to load complaint details.', 'error');
            }
        } catch (error) {
            console.error("Error fetching complaint by ID:", error);
            showToast('Failed to load complaint details.', 'error');
        }
    };
    

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toString() === 'Invalid Date' ? 'No date provided' : date.toLocaleDateString("en-US", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };
    
    
    const handleCloseMobileDetail = () => {
        setShowMobileDetail(false);
        setSelectedComplaint(null);
        setShowForm(false);
    };

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const handleSendReply = async () => {
        if (reply && reply.trim() !== "" && selectedComplaint) {
            try {
                const newReply = {
                    text: reply,
                    time: new Date().toLocaleTimeString(),
                    date: new Date().toLocaleDateString(),
                };
        
                const response = await postReply(selectedComplaint.id, newReply.text);
                console.log('Post Reply Response:', response); 
                if (response && response.status === 201) {
                    // Update the replies state and selectedComplaint with the new reply
                    setReplies([...replies, newReply]);
                    setSelectedComplaint(prev => ({
                        ...prev,
                        reply: newReply.text,
                        replied_on: new Date(),
                    }));
                    setReply(""); 
                    setHasReplied(true); // Mark as replied
                    setCanReply(false); // Disable further replies after one is sent
                    showToast('Reply sent successfully!', 'success');
                } else {
                    showToast('Failed to send reply.', 'error');
                }
            } catch (error) {
                console.error("Error posting reply:", error);
                showToast('Failed to send reply.', 'error');
            }
        } else {
            showToast('Please enter a valid reply.', 'error');
        }
    };
    
    const handleEditReply = () => {
        if (selectedComplaint && selectedComplaint.reply) {
            setIsEditingReply(true);
            setReplyToEdit(selectedComplaint.reply);
            setEditReplyPopupVisible(true);
        } else {
            console.error("No reply to edit.");
            showToast("No reply to edit.", "error");
        }
    };
    
    
    
    
    
    
    
    
    const handleDeleteReply = () => {
        setShowDeletePopup(true);
        setComplaintToDelete(selectedComplaint);
    };
    
    const confirmDeleteReply = async () => {
        if (complaintToDelete && complaintToDelete.id) {
            try {
                const response = await deleteReply(complaintToDelete.id, complaintToDelete.reply);
                if (response && response.status === 201) {
                    setSelectedComplaint({...selectedComplaint, reply: null, replied_on: null});
                    showToast('Reply deleted successfully!', 'success');
                } else {
                    showToast('Failed to delete reply.', 'error');
                }
            } catch (error) {
                console.error("Error deleting reply:", error);
                showToast('Failed to delete reply.', 'error');
            } finally {
                setShowDeletePopup(false);
                setComplaintToDelete(null);
            }
        }
    };

    


    const cancelDelete = () => {
        setShowDeletePopup(false);
        setDeleteIndex(null);
    };

    const handleEditReplySubmit = async (updatedReplyText) => {
    try {
        if (selectedComplaint && selectedComplaint.id) {
            // Here you would typically make an API call to update the reply
            // For now, we'll just update the local state
            setSelectedComplaint(prevComplaint => ({
                ...prevComplaint,
                reply: updatedReplyText
            }));
            setEditReplyPopupVisible(false);
            showToast('Reply updated successfully!', 'success');
        } else {
            console.error("No complaint selected or invalid complaint ID.");
            showToast('Failed to update reply.', 'error');
        }
    } catch (error) {
        console.error("Error updating reply:", error);
        showToast('Failed to update reply.', 'error');
    }
};
    
    
    

    const handleFormSubmit = async () => {
        if (title.trim() && description.trim() && productName.trim()) {
            setFormSubmitting(true);
            try {
                const complaintId = selectedComplaint ? selectedComplaint.id : null;
                if (!complaintId) {
                    throw new Error("No complaint ID found for submission.");
                }
    
                const imageUrl = Array.isArray(reviewImages) && reviewImages.length > 0 
                    ? reviewImages.map(image => String(image))
                    : [];
    
                const complaintData = {
                    product_name: productName.trim(),
                    title: title.trim(),
                    description: description.trim(),
                    image_url: imageUrl,
                    confirmation_document: selectedComplaint ? String(selectedComplaint.confirmation_document).trim() : '',
                };
    
                const response = await updateComplaint(complaintId, complaintData);
    
                if (response && response.status === 202) {
                    showToast('Complaint updated successfully!', 'success');
                    
                    // Update the local state
                    const updatedComplaint = {
                        ...selectedComplaint,
                        ...complaintData,
                    };
                    setSelectedComplaint(updatedComplaint);
                    
                    // Update the complaints list
                    setSubmittedComplaints(prevComplaints => 
                        prevComplaints.map(c => c.id === complaintId ? updatedComplaint : c)
                    );
                    
                    // Reset the editing state
                    setIsEditing(false);
                    setShowForm(false);
                } else {
                    showToast('Failed to update complaint.', 'error');
                }
            } catch (error) {
                console.error('Error submitting complaint:', error);
                showToast('Failed to submit complaint.', 'error');
            } finally {
                setFormSubmitting(false);
            }
        } else {
            showToast('Please fill out all required fields.', 'error');
        }
    };

    
    
    
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    // Function to handle editing a complaint
    const handleEditComplaint = (complaint) => {
        setIsEditing(true);
        setSelectedComplaint(complaint);
        setShowForm(true);
        setProductName(complaint.product_name || '');
        setTitle(complaint.title || '');
        setDescription(complaint.description || '');
        setImages(complaint.image_url || []);
    };
    const handleViewDocument = (documentUrl) => {
        if (documentUrl.startsWith('/')) {
            // Assuming your documents are hosted on a remote server
            const fullUrl = `https://your-server.com${documentUrl}`; // Replace with your server address
            window.open(fullUrl, '_blank');
        } else if (documentUrl) {
            window.open(documentUrl, '_blank'); // Already a full URL
        } else {
            showToast('Document is not available.', 'error');
        }
    };
    
    
    // Navigation to profile page
    const handleNavigateToProfile = (company_id) => {
        if (company_id) {
            navigate(`/profile2/${company_id}`);
        } else {
            console.log('error')
            showToast('Profile not available.', 'error'); // This message appears if company_id is not available
        }
    };
    

    const handleDeleteComplaint = (complaint) => {
        console.log('Complaint Object before deleting:', complaint); // Log the entire complaint object
        setComplaintToDelete(complaint); // Store the complaint object in state
        setShowDeleteComplaintPopup(true); // Show delete confirmation popup
    };
    
    // Function to confirm and delete the complaint
    const confirmDeleteComplaint = async () => {
        if (complaintToDelete && complaintToDelete.id) {
            console.log('Deleting Complaint ID:', complaintToDelete.id); // Log the complaint ID
            
            try {
                const response = await deleteComplaintById(complaintToDelete.id);
                console.log('Delete Complaint Response:', response); // Log the response
                if (response.status === 202) {
                    showToast('Complaint deleted successfully!', 'success');
                    setSubmittedComplaints(submittedComplaints.filter(c => c.id !== complaintToDelete.id));
                    setSelectedComplaint(null);
                } else {
                    showToast('Failed to delete complaint.', 'error');
                }
            } catch (error) {
                console.error("Error deleting complaint:", error);
                showToast('Failed to delete complaint.', 'error');
            } finally {
                setShowDeleteComplaintPopup(false); // Close the delete confirmation popup
                setComplaintToDelete(null); // Clear the complaint to delete
            }
        } else {
            console.error("Complaint ID is undefined or complaint object is malformed.");
            showToast('Failed to delete complaint. Invalid complaint data.', 'error');
        }
    };
    
    // Function to cancel the deletion
    const cancelDeleteComplaint = () => {
        setShowDeleteComplaintPopup(false); // Close the delete confirmation popup
        setComplaintToDelete(null); // Clear the complaint to delete
    };

    // Function to handle deleting a complaint
    // Function to handle deleting a complaint

    
    const tabLabels = {
        received: 'Complaints Received',
        submitted: 'Complaints Submitted'
    };

    const displayedComplaints = activeTab === 'received' ? receivedComplaints : submittedComplaints;

    return (
        <div className="flex flex-col md:flex-row w-full h-full bg-[#F2F3F3] font-poppins overflow-y-auto">
            <Sidebar />
            <div className="flex flex-col w-full">
                <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
                    <div className="w-full flex justify-start items-center space-x-2">
                        <h1 className="text-black font-bold text-xl">Complaints</h1>
                    </div>
                    <ReportContainer />
                </div>
                <div className="border-b border-black mt-4 md:mt-[25px]"></div>
                <div className="flex flex-col md:flex-row h-full">
                    <div className={`${isMobile ? 'w-full' : 'md:w-1/3'} border-r border-gray-400`}>
                        <Tab activeTab={activeTab} setActiveTab={setActiveTab} labels={tabLabels} />
                        <div className="flex flex-col items-center p-3 md:p-9">
                        {loading && page === 0 ? (
                                <FaSpinner className="animate-spin text-red-600" size={24} />
                            ) : (
                                <>
                                    {displayedComplaints.length > 0 ? (
                                        displayedComplaints.map((complaint, index) => {
                                            // console.log(Complaint at index ${index}:, complaint); // Log each complaint object
                                            return (
                                                <ComplaintCard
                                                    key={index}
                                                    complaint={complaint}
                                                    onView={handleViewComplaint}
                                                    onDelete={() => handleDeleteComplaint(complaint)} // Ensure the complaint object is passed
                                                    onEditReply={() => handleEditReply(index)} // Pass the index of the reply or complaint for editing

                                                />
                                            );
                                        })
                                    ) : (
                                        <div className="text-center text-black mt-4">No Complaints Found</div>
                                    )}
                                    {hasMore && (
                                        <div ref={observerRef} className="w-full flex justify-center py-4">
                                            <FaSpinner className="animate-spin text-red-600" size={24} />
                                        </div>
                                    )}
                                </>
                            )}
                           
                        </div>
                    </div>
                    {(!isMobile || (isMobile && showMobileDetail)) && (
                        <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-7`}>
                            {isMobile && (
                                <button
                                    onClick={handleCloseMobileDetail}
                                    className="absolute top-2 right-2 text-2xl"
                                >
                                    &times;
                                </button>
                            )}
           {!showForm && selectedComplaint && (
    <>
        <div className="bg-gray-200 rounded-lg p-4 shadow-md w-full mb-4">
        <div className="flex">
    {/* Make the profile image and name clickable */}
    <div className="flex cursor-pointer" onClick={() => handleNavigateToProfile(selectedComplaint.company_id)}>
    <img
            src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
            alt="Profile"
            className="rounded-full w-12 h-12 mr-4"
        />
        <div className="flex flex-col">
            <div className="font-bold">{selectedComplaint.complaint_by_name || 'Name'}</div>
            <div className="text-gray-500 text-sm">{new Date(selectedComplaint.created_on).toLocaleDateString()}</div>
        </div>
    </div>

    {activeTab === 'submitted' && (
        <div className="ml-auto flex items-center space-x-2">
            <button
                className="bg-gray-300 text-gray-600 hover:bg-gray-400 p-2 rounded"
                onClick={() => handleEditComplaint(selectedComplaint)}
            >
                <FaEdit />
            </button>
            <button
                className="bg-gray-300 text-gray-600 hover:bg-gray-400 p-2 rounded"
                onClick={() => handleDeleteComplaint(selectedComplaint)}
            >
                <FaTrash />
            </button>
        </div>
    )}
</div>


            <div className="mt-4">
                <div className="font-bold text-sm">{selectedComplaint.title || 'Complaint Title/Subject'}</div>
                <div className="text-gray-600 font-bold mt-2 text-sm">{selectedComplaint.product_name || 'Product Name'}</div>
                <div className="text-sm">{selectedComplaint.description || 'Description not provided'}</div>
                {selectedComplaint.image_url && selectedComplaint.image_url.length > 0 && (
    <img
        src={selectedComplaint.image_url[0]}
        alt="Complaint"
        className="w-16 h-16 rounded-lg mt-4 cursor-pointer"
        onClick={() => handleImageClick(selectedComplaint.image_url[0])}  // Open popup on click
    />
)}
{isImagePopupOpen && (
                <ImagePopup 
                    imageUrl={selectedImageUrl} 
                    onClose={handleClosePopup} 
                />
            )}

               {selectedComplaint.confirmation_document && (
    <div className="mt-4">
        <div className="flex items-center justify-between bg-gray-300 hover:bg-gray-200 p-6 rounded-lg cursor-pointer">
            <div className="flex items-center">
                <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-10 h-10 mr-2" />
                <span className="text-gray-600">Document</span>
            </div>
            <button 
                onClick={() => handleViewDocument(selectedComplaint.confirmation_document)} 
                className="text-red-600 underline">
                View
            </button>
        </div>
    </div>
)}

            </div>
        </div>

        {/* Check if reply exists */}
      
        {selectedComplaint.reply ? (
    <div className="mt-4 bg-[#E1E1E1] p-4 rounded-lg shadow-md">
        <div className="flex items-center">
            <img
                src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
                alt="Profile"
                className="rounded-full w-12 h-12 mr-4"
            />
            <div className="flex flex-col">
                <div className="font-bold">{selectedComplaint.complaint_by_name || 'Name'}</div>
                <div className="text-gray-500 text-sm">{new Date(selectedComplaint.replied_on).toLocaleDateString()}</div>
            </div>
        </div>
        <div className="mt-2">
            <div>{selectedComplaint.reply}</div>
        </div>
        {activeTab === 'received' && (
            <div className="mt-2 flex justify-end space-x-2">
                <button
                    className="bg-gray-300 text-gray-600 hover:bg-gray-400 p-2 rounded"
                    onClick={() => handleEditReply(selectedComplaint)}
                >
                    <FaEdit />
                </button>
                <button
                    className="bg-gray-300 text-gray-600 hover:bg-gray-400 p-2 rounded"
                    onClick={() => handleDeleteReply(selectedComplaint)}
                >
                    <FaTrash />
                </button>
            </div>
        )}
    </div>
) : (!hasReplied && canReply && activeTab === 'received' && (
    <div className="flex flex-col mt-2">
        <TextArea
            value={reply}
            onChange={handleReplyChange}
            placeholder="Reply"
        />
        <div className="w-40 self-end">
            <Button
                text="Send"
                backgroundColor="bg-red-600"
                textColor="text-white"
                borderColor="border-red-600"
                onClick={handleSendReply}
            />
        </div>
    </div>
))}


                                </>
                            )}

                


{owner && selectedComplaint && !showForm && canReply && !hasReplied && (
    <div className="flex flex-col mt-2">
        
    </div>
)}


{(showForm || (selectedComplaint && selectedComplaint.isEditing)) && (
    <div className="p-4 space-y-8 mt-4">
        <InputBox
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name"
            borderRadius="1px"
        />
        <InputBox
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title/Subject"
            borderRadius="1px"
        />
        <TextArea
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            height="20px"
        />
        <AddDocument 
            label="Add support document" 
            onFileUpload={(file) => setImages([...images, file])} 
            accept="application/pdf" 
        />
        <ReviewImageUpload 
            reviewImages={reviewImages} 
            setReviewImages={setReviewImages} 
            svgImagePath="/Assets/grey_plus.svg" 
            bgColor="#E6E6E6" 
            borderRadius="8px" 
            width="80px" 
            height="90px" 
        />
        <div className="mt-9">
            <Button
                text={formSubmitting ? <LoaderSpinner visible={true} height={20} /> : "Submit"}
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                borderColor="border-red-600"
                onClick={handleFormSubmit}
                disabled={formSubmitting}
            />
        </div>
    </div>
)}
                        </div>
                    )}
                </div>
            </div>
            <Toast /> 
            {showDeleteComplaintPopup && (
    <DeletePopup
        message="Are you sure you want to delete this complaint?"
        onConfirm={confirmDeleteComplaint} // Call confirmDeleteComplaint when "Yes" is clicked
        onClose={cancelDeleteComplaint} // Call cancelDeleteComplaint when "No" is clicked
    />
)}

            {editReplyPopupVisible && (
                <EditReplyPopup
                    reply={replyToEdit}
                    onSubmit={handleEditReplySubmit}
                    onClose={() => setEditReplyPopupVisible(false)}
                />
            )}
            {showDeletePopup && (
    <DeletePopup
        message="Are you sure you want to delete this reply?"
        onConfirm={confirmDeleteReply}
        onClose={cancelDelete}
    />
)}

        </div>
    );
};

export default Complaints;