import React, { useState, useEffect, useRef } from 'react';
import { getAllMedia } from '../../Controller/company_products'; // Assuming you have this API function
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa'; // FontAwesome Spinner

const Explore = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const navigate = useNavigate();
  const observerRef = useRef(null); // Ref for the IntersectionObserver target

  const fetchMediaItems = async (skip, limit) => {
    setLoading(true);
    try {
      const res = await getAllMedia(skip, limit);
      console.log('response' ,res)
      if (res.status === 200 && res.data.data) {
        const newItems = res.data.data.map(media => ({
          id: media.id,
          imageUrl: Array.isArray(media.image_url) && media.image_url.length ? media.image_url[0] : 'placeholder-image-url',
          description: media.description ? media.description.substring(0, 20) + (media.description.length > 20 ? '...' : '') : 'No description available',
          title:media.title ? media.title.substring(0, 30) + (media.title.length > 20 ? '...' : '') : 'No description available'
        }));

        setItems(prev => [...prev, ...newItems]);
        setHasMore(newItems.length === limit);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching media items:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMediaItems(0, limit);
  }, []);

  // Infinite scroll handler
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchMediaItems(items.length, limit);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore, loading, items.length]);

  const handleImageClick = (id) => {
    navigate(`/detailed_view/${id}`);
  };

  return (
    <div className="w-full p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column */}
        <div className="flex flex-col gap-4">
          {/* First Row */}
          <div className="flex gap-4">
            {items.slice(0, 2).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '180px' }}
                onClick={() => handleImageClick(item.id)}
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
          {/* Second Row */}
          <div className="flex gap-4">
            {items.slice(2, 5).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '280px' }}
                onClick={() => handleImageClick(item.id)}
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Right Column */}
        <div className="flex flex-col gap-4">
          {/* First Row */}
          <div className="flex gap-4">
            {items.slice(5, 8).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '280px' }}
                onClick={() => handleImageClick(item.id)}
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
          {/* Second Row */}
          <div className="flex gap-4">
            {items.slice(8, 10).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                style={{ height: '180px' }}
                onClick={() => handleImageClick(item.id)}
              >
                <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                <div className="absolute bottom-0 left-0 bg-opacity-50 text-white p-2 w-full text-left">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Infinite Scroll Spinner */}
      <div ref={observerRef} className="flex justify-center my-4">
        {loading && <FaSpinner className="animate-spin text-3xl text-gray-500" />}
      </div>
    </div>
  );
};

export default Explore;
