import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deleteManagementMember } from '../../Controller/management';
import ConfirmationPopup from './pop_up';
import DeletePopup from './delete_pop_up'; // Import the DeletePopup component

const ReportContainer = ({ showRemove, profileId }) => {
  const [isContainerVisible, setIsContainerVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false); // State for logout popup
  const navigate = useNavigate();

  const handleContainerOpen = () => {
    setIsContainerVisible(true);
  };

  const handleContainerClose = () => {
    setIsContainerVisible(false);
  };

  const handleRemove = () => {
    setIsPopupVisible(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleConfirmRemove = async () => {
    try {
      await deleteManagementMember(profileId);
      navigate('/view-management-owner', { state: { profileDeleted: true } });
    } catch (error) {
      console.error("Error deleting management member:", error);
      alert("Error deleting management member. Please try again.");
    } finally {
      setIsPopupVisible(false);
    }
  };

  const handleCancelRemove = () => {
    setIsPopupVisible(false);
  };

  // Show the logout popup
  const handleLogoutClick = () => {
    setIsLogoutPopupVisible(true);
  };

  // Confirm logout in the popup
  const handleConfirmLogout = () => {
    setIsLogoutPopupVisible(false); // Hide the popup
    handleLogout(); // Proceed with the logout logic
  };

  // Cancel logout in the popup
  const handleCancelLogout = () => {
    setIsLogoutPopupVisible(false);
  };

  return (
    <div className="relative">
      {/* Dots icon for opening the menu */}
      <img
        src="/Assets/dots.svg" 
        alt="Options"
        className="cursor-pointer h-5 w-5 mt-2 lg:mr-0 mr-2 mb-1"
        onClick={handleContainerOpen}
      />

      {/* Overlay for transparent background */}
      {isContainerVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleContainerClose}></div>
      )}

      {isContainerVisible && (
        <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-50">
          <button
            className="absolute  right-2 bg-transparent text-black hover:text-gray-500 text-xl "
            onClick={handleContainerClose}
          >
            &times;
          </button>
          <div className="flex flex-col items-center py-4 mt-2">
            <Link to="/report_page" className="flex items-center py-2 w-full px-3 hover:bg-gray-100 text-black">
              <img src="/Assets/report_icon.svg" alt="Report Icon" className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Report an Issue</span>
            </Link>
            <hr className="w-full border-gray-200 my-2" />
            <div onClick={handleLogoutClick} className="flex items-center py-2 px-3 w-full hover:bg-gray-100 cursor-pointer text-black">
              <img src="/Assets/logout_icon.svg" alt="Logout Icon" className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Save & Log out</span>
            </div>
          </div>
        </div>
      )}

      {/* Popup for deleting management member */}
      {isPopupVisible && (
        <ConfirmationPopup 
          message="Are you sure you want to delete this management member?"
          onConfirm={handleConfirmRemove}
          onClose={handleCancelRemove}
        />
      )}

      {/* Popup for confirming logout */}
      {isLogoutPopupVisible && (
        <DeletePopup
          message="Are you sure you want to log out?"
          onConfirm={handleConfirmLogout} // Logout on confirmation
          onClose={handleCancelLogout}    // Close the popup on cancel
        />
      )}
    </div>
  );
};

export default ReportContainer;
