// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";
// import InputBox from '../../Components/input_box';
// import TextArea from "../../Components/text_area";
// import FileUpload from '../../Components/image_upload';
// import { createVerificationImage } from '../../../Controller/verification';

// const CompanyPhotoManagement = ({ headingofcontent, paragraph, nextPath }) => {
//   const [files, setFiles] = useState([]);
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     // Reset state when component mounts
//     setFiles([]);
//     setTitle('');
//     setDescription('');
//   }, [location]);

//   const handleBackClick = () => {
//     navigate('/company_photos');
//   };

//   const handleSubmit = async () => {
//     try {
//       const image_url = files.length > 0 ? files[0].url : '';
//       const verification_type = headingofcontent;
//       await createVerificationImage(image_url, title, description, verification_type);
//       console.log("Navigating with state:", { title, description, image_url, company: verification_type, images: files.map(file => file.url) });
//       navigate(nextPath, {
//         state: {
//           title,
//           description,
//           image_url,
//           company: verification_type,
//           images: files.map(file => file.url) // Passing the images
//         }
//       });
//     } catch (error) {
//       console.error("Failed to create verification image:", error);
//     }
//   };

//   return (
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-poppins">
//       <Sidebar />
//       <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
//         <div className="flex items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold text-xl">Company Photo</h1>
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="w-3/4 ml-7">
//         <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-poppins" style={{ height: '200px', width: '450px' }}>
//           <p className="mt-1 font-bold font-poppins text-lg lg:text-xl lg:whitespace-nowrap">{headingofcontent}</p>
//           <p className="mt-7 text-sm lg:text-sm font-bold font-poppins">{paragraph}</p>
//           <div className="mt-4">
//             <p className="font-bold">Title</p>
//             <InputBox
//               placeholder="Title"
//               borderRadius="2px"
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//             />
//           </div>
//           <div className="mt-2">
//             <p className="font-bold">Description</p>
//             <TextArea
//               placeholder="Description"
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//             />
//             <div className="mt-2 mb-6 ml-2 flex space-x-4">
//               <FileUpload
//                 files={files}
//                 setFiles={setFiles}
//                 svgImagePath="Assets/grey_plus.svg"
//                 bgColor="#E6E6E6"
//                 borderRadius="8px"
//                 width="80px"
//                 height="90px"
//                 type="image"
//               />
//             </div>
//           </div>
//           <div className="mt-7">
//             <Button
//               text="Next"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSubmit}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyPhotoManagement;

// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import Button from '../../Components/button';
// import InputBox from '../../Components/input_box';
// import TextArea from '../../Components/text_area';
// import FileUpload from '../../Components/image_upload';
// import LoaderSpinner from '../../Components/loader_spinner';
// import { createVerificationImage } from '../../../Controller/verification'; // Updated API call
// import { showToast, Toast } from '../../Components/toast'; // Import the toast functions

// const CompanyPhotoManagement = ({ headingofcontent, paragraph, nextPath, verificationType }) => {
//   const [files, setFiles] = useState([]);
//   const [loading, setLoading] = useState(false); // Add loading state
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     // Reset the state when the component mounts or when the route changes
//     setFiles([]);
//   }, [location.pathname]);

//   const handleBackClick = () => {
//     navigate('/company_photos');
//   };

//   const handleSubmit = async () => {
//     if (files.length === 0) {
//       showToast('Please upload an image.', 'error');
//       return;
//     }

//     setLoading(true); // Set loading state to true
//     try {
//       const image_url = files.length > 0 ? files[0].url : '';
      
//       // Console log the data we are posting
//       console.log('Posting Data:', {
//         image_url,
//         verification_type: verificationType
//       });

//       // Make API call
//       const response = await createVerificationImage(image_url, verificationType);
      
//       // Check if response is successful
//       if (response.status === 201) {
//         showToast('Details saved successfully.', 'success');
//         navigate(nextPath, { state: { image: image_url } });
//       } else {
//         // Handle possible response errors (e.g., 400 status)
//         console.error('API response error:', response);
//         showToast('Failed to save the image. Please try again.', 'error');
//       }

//     } catch (error) {
//       console.error("Failed to create verification image:", error);
//       showToast('Failed to create verification image.', 'error');
//     } finally {
//       setLoading(false); // Set loading state to false after submission
//     }
//   };


//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
//       <Sidebar />
//       <Toast /> {/* Add the Toast component */}
//       <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
//         <div className="flex items-center space-x-2">
//           {/* <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           /> */}
//          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Photos</h1>
//         </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4 ">
//       <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg mt-8 lg:mt-10">
//           <p className="mt-1 font-semibold text-[16px] lg:text-xl">{headingofcontent}</p>
//           <p className="mt-2 text-[12px] lg:text-sm font-gotham text-gray-700 font-normal">{paragraph}</p>
//           <div className="mt-8 mb-6 ml-2 flex space-x-4 justify-center ">
//             <FileUpload
//               files={files}
//               setFiles={setFiles}
//               svgImagePath="Assets/grey_plus.svg"
//               bgColor="#E6E6E6"
//               borderRadius="8px"
//               width="130px"
//               height="190px"
//               type="image"
//             />
//           </div>
//           <div className="mt-16">
          
//               <Button
//                 text="Next"
//                 borderColor="border-red-600"
//                 backgroundColor="bg-[#DB0000]"
//                 textColor="text-white"
//                 onClick={handleSubmit}
//                 loading={loading}
//               />
          
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyPhotoManagement;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import Photoupload from '../../Components/file_upload_container';
import { createVerificationImage, updateVerificationImage } from '../../../Controller/verification';
import { showToast, Toast } from '../../Components/toast';

const CompanyPhotoManagement = ({ headingofcontent, paragraph, nextPath, verificationType }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingImageId, setExistingImageId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedPhotos = JSON.parse(localStorage.getItem('existingPhotos'));
    const existingData = storedPhotos?.find(photo => photo.verification_type === verificationType);
    if (existingData?.image_url) {
      setFiles([{ url: existingData.image_url }]);
      setExistingImageId(existingData.id);
    }
  }, [verificationType]);
  const handleSubmit = async () => {
    if (files.length === 0) {
      showToast('Please upload an image.', 'error');
      return;
    }
  
    setLoading(true);
    try {
      const image_url = files[0].url;
      let response;
  
      if (existingImageId) {
        // Update case (PUT)
        response = await updateVerificationImage(existingImageId, image_url);
        if (response) {
          // Update local storage
          const updatedPhotos = JSON.parse(localStorage.getItem('existingPhotos')) || [];
          const updatedPhotosList = updatedPhotos.map(photo => {
            if (photo.id === existingImageId) {
              return { ...photo, image_url: image_url };
            }
            return photo;
          });
          localStorage.setItem('existingPhotos', JSON.stringify(updatedPhotosList));
          showToast('Image updated successfully.', 'success');
        }
      } else {
        // Create new case (POST)
        response = await createVerificationImage(image_url, verificationType);
        if (response?.status === 201) {
          // Add the new photo to local storage
          const updatedPhotos = JSON.parse(localStorage.getItem('existingPhotos')) || [];
          updatedPhotos.push({
            id: response.data.id, // Assuming response contains the new image ID
            image_url: image_url,
            verification_type: verificationType,
            is_deleted: false,
          });
          localStorage.setItem('existingPhotos', JSON.stringify(updatedPhotos));
        
        }
      }
  
      navigate(nextPath);
    } catch (error) {
      console.error('Failed to save/update verification image:', error);
    
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
  
    if (newFiles.length > 0 && newFiles[0].url !== files[0]?.url) {
      if (existingImageId) {
        // The existing image ID should remain intact if it's just an update with a new image
        // Only reset it if the verification type or image has been removed entirely
        const updatedPhotos = JSON.parse(localStorage.getItem('existingPhotos')) || [];
        const updatedPhotosList = updatedPhotos.map(photo => {
          if (photo.verification_type === verificationType) {
            // Replace the existing photo with the new file, but retain the existingImageId
            return { ...photo, image_url: newFiles[0].url, is_deleted: false };
          }
          return photo;
        });
        localStorage.setItem('existingPhotos', JSON.stringify(updatedPhotosList));
      } else {
        setExistingImageId(null); // Reset only when there's no existing image ID
      }
    }
  };
  
  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <Toast />
      <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
        <div className="flex items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">
            Company Photos
          </h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg mt-8 lg:mt-10">
          <p className="mt-1 font-semibold text-[16px] lg:text-xl">{headingofcontent}</p>
          <p className="mt-2 text-[12px] lg:text-sm font-gotham text-gray-700 font-normal">
            {paragraph}
          </p>
          <div className="mt-8 mb-6 ml-2 flex space-x-4 justify-center">
            <Photoupload
            files={files}
              setFiles={handleFileChange}
              svgImagePath="Assets/grey_plus.svg"
              editIconPath="Assets/edit.svg" // Pass edit icon path here
              bgColor="#E6E6E6"
              borderRadius="8px"
              width="150px"
              height="220px"
              type="image"
            />
          </div>
          <div className="mt-16">
            <Button
              text={existingImageId ? "Update" : "Next"}
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyPhotoManagement;
