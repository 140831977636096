
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileImageContainer from '../../Components/profile_image_container.jsx';
import Button from '../../Components/button.jsx';
import Sidebar from '../../Components/main_navbar.jsx';
import { get_company_api, update_profile_image } from '../../../Controller/company_details.js';
import StarRating from '../../Components/review_star.jsx';
import TabButton from '../../Components/profile_tab.jsx';
import { WebLinkModal, BottomSheet } from '../../Components/web_link.jsx';
import ConfirmationPopup from '../../Components/pop_up';  // Import the ConfirmationPopup component
import { showToast } from '../../Components/toast.jsx';
import { FaSpinner } from 'react-icons/fa';

const ProfileVisitorsView = ({ editable }) => {
  const navigate = useNavigate();
  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [category, setCategory] = useState('');
  const [locationText, setLocationText] = useState('');
  const [bio, setBio] = useState('');
  const [webLinks, setWebLinks] = useState([]);
  const [review, setReview] = useState(0);
  const [catalogue, setCatalogue] = useState(null);
  const [website, setWebsite] = useState(''); // New state for website
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);  // State to manage the popup visibility
  const [popupMessage, setPopupMessage] = useState('');
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get_company_api();
        if (response.status === 200) {
          const data = response.data.data;
          setStoreName(data.name);
          setCategory(data.category);
          setLocationText(data.address);
          setSelectedProfileImage(data.profile_image_url);
          setReview(data.review);
          setBio(data.bio);
          setWebLinks(data.web_links || []);
          setCatalogue(data.catalogue);
          setWebsite(data.website); // Set the website from the response

          // Store data in localStorage
          localStorage.setItem('id', data.id);
          // localStorage.setItem('manager', data.manager);
          // localStorage.setItem('address', data.address);
          // localStorage.setItem('latitude', data.latitude);
          // localStorage.setItem('longitude', data.longitude);
          localStorage.setItem('name', data.name);
          localStorage.setItem('profile_image_url', data.profile_image_url);
          localStorage.setItem('authorization_file', data.authorization_file);
          localStorage.setItem('website', data.website);
        } else {
          console.error("Failed to fetch company data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
      finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchData();
  }, []);

  const handleSaveProfileImage = async (newImage) => {
    setSelectedProfileImage(newImage);
    try {
      const response = await update_profile_image(newImage);
      if (response.status === 201) {
        console.log("Profile image updated successfully:", response.data);
      } else {
        console.error("Failed to update profile image:", response);
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  // const handleViewCatalogue = () => {
  //   if (catalogue) {
  //     window.open(catalogue, '_blank');
  //   }
  // };
  const handleViewCatalogue = () => {
    if (catalogue) {
      navigate('/catalog', { state: { catalogueURL: catalogue } });  // Pass the catalogue URL as state
    }
  };
  

  const handleAddCatalogue = () => {
    navigate('/catalog');  // Adjust the path to your catalog page
  };

  const handleWebLinkClick = () => {
    if (window.innerWidth >= 1024) {
      setIsModalOpen(true);
    } else {
      setIsBottomSheetOpen(true);
    }
  };

  const handleImageClick = (route) => {
    navigate(route);
  };

  const handleEditProfileClick = () => {
    const verificationStatus = localStorage.getItem('verificationStatus');
    if (verificationStatus === 'VERIFICATION_PENDING' || verificationStatus === 'VERIFICATION_REJECTED') {
        setShowPopup(true);  // Show the popup if verification is pending or rejected
    } else {
        navigate('/edit_form');  // Navigate to edit form if verification is completed
    }
};
const handleShareProfileClick = () => {
  const verificationStatus = localStorage.getItem('verificationStatus');
  const profileUrl = window.location.href; // Get the current URL

  if (verificationStatus === 'VERIFICATION_PENDING') {
    setPopupMessage('Your profile is under verification. Please wait.'); 
    setShowPopup(true);
  } else {
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(profileUrl).then(() => {
      // Show a success message using the existing showToast function
      showToast('Profile URL copied to clipboard!', 'success');
    }).catch((error) => {
      console.error('Failed to copy URL:', error);
      showToast('Failed to copy URL. Please try again.', 'error');
    });
  }
};

  return (
    <div className="flex h-full  bg-[#FBFCF8] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex flex-col w-full h-full">
      <div
          className="relative w-full lg:h-72 h-[272px]"  // Ensure the div is full width
          style={{
            backgroundImage: `url(/Assets/cover_photo.svg)`,
            backgroundSize: 'cover', 
            backgroundPosition: 'center',  
            backgroundRepeat: 'no-repeat',  
          }}
        >
          <h1 className="absolute  text-[#263238] font-gotham-Bold font-bold lg:text-2xl text-xl p-4 z-10">Profile</h1>
        
          {/* Profile Image Container */}
          <div className="absolute inset-x-0 lg:mt-20 mt-16 flex justify-center lg:w-4/5">  {/* Adjust the bottom value */}
            <ProfileImageContainer
              editable={editable}
              profileImage={selectedProfileImage}
              onSaveProfileImage={handleSaveProfileImage}
            />
          </div>
          <div className="flex flex-col items-center  lg:w-4/5 lg:mt-52  mt-44 lg:mx-0 mx-5 " >
          {loading ? (
              <FaSpinner className="text-[#DB0000] animate-spin lg:w-6 lg:h-6 w-4 h-4" /> // Loading spinner for company name and address
            ) : (
              <>
                <h2 className="lg:text-lg text-[15px] font-normal font-techna">{storeName}</h2>
                <p className="lg:text-sm font-normal font-gotham-Book text-[13px]">{locationText}</p>
              </>
            )}

          <div className="flex items-center space-x-2">
  <StarRating review={review} />
  <span
    className="text-xs lg:text-sm  text-[#DB0000] cursor-pointer font-gotham-extralight font-extralight underline"  
    onClick={() => navigate('/reviews_page')}
  >
    View Reviews
  </span>
</div>
        
        </div>
        </div>


    
        <div className="mt-2  text-left mx-2 lg:mx-8">
          <h2 className="text-sm lg:text-lg font-semibold mb-1 font-gotham text-[#263238]">About</h2>
         
          <p 
    className="text-[13px] lg:text-[16px] mb-1 text-[#6B6B6B] lg:w-3/4 w-full lg:mx-0 font-gotham"  // Set width to 75% and center the text
    style={{
      wordWrap: 'break-word', // Ensure long words break correctly
    }}>
    {bio}
  </p>
  <h3
  className="flex items-center lg:text-xs text-xs font-normal mb-1 cursor-pointer text-[#DB0000] font-gotham"
  onClick={handleWebLinkClick} // Add onClick to the container
>
  <img
    src="/Assets/web_link.svg"
    alt="Link Icon"
    className="lg:w-5 lg:h-5 mr-2 w-4 h-4"
  />
  Web Links
</h3>
<ul className="list-disc ml-4 text-sm">
  {webLinks.map((link, index) => (
    <li key={index}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    </li>
  ))}
</ul>

        </div>
        {editable && (
          <div className=" w-full lg:w-4/5 flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2  space-x-3 mx-2">
              <div className="w-full   ">
                <Button
                  text="Edit Profile"
                  borderColor="border-[#DB0000]"
                  
                  textColor="text-[#DB0000]"
                  onClick={handleEditProfileClick}
                   // Use the new handler
                />
              </div>
              <div className="w-full  ">
                <Button
                  text="Share Profile"
                  borderColor="border-[#DB0000]"
                  backgroundColor="bg-[#DB0000]"
                  textColor="text-white"
                  onClick={handleShareProfileClick}
                    // Handle share profile action here
                  
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full lg:w-4/5 flex justify-between items-center lg:mt-1 mt-2  ">
          <div className="w-1/2 text-black font-semibold lg:ml-8 ml-2 text-sm lg:text-lg" >Product Catalogue</div>
          <div className='w-1/2  flex justify-end '>
          <div className='lg:w-1/3 w-1/2 h-1/5 mb-2 shadow-lg mr-4'>
  {catalogue ? (
    <div className="flex items-center justify-center cursor-pointer" onClick={handleViewCatalogue}>
      <img
        src="/Assets/eye_catalog.svg"  // Replace with the path to your "view" icon
        alt="View"
        className="h-6 w-6" // Adjust height and width as needed
      />
      <span className="text-[#263238] ml-2 p-3">View</span>
    </div>
  ) : (
    <div className="flex items-center justify-center cursor-pointer" onClick={handleAddCatalogue}>
      <img
        src="/Assets/add-square.svg"  // Replace with the path to your "add" icon
        alt="Add"
        className="h-6 w-6" // Adjust height and width as needed
      />
      <span className="text-[#263238] ml-2 p-3">Add</span>
    </div>
  )}
</div>

          </div>
        </div>
    
        <div className="w-full lg:w-4/5 relative p-2 lg:ml-6 lg:mt-3 ">
          {/* Grid of TabButtons */}
          <div className="grid grid-cols-2 lg:grid-cols-2 lg:gap-4 gap-5">
            <TabButton
              icon="/Assets/profile_management.svg"
              label="Management"
              path="/view-management"
            />
            <TabButton
              icon="/Assets/profile_docs.svg"
              label="Documents"
              path="/company_docs_view"
            />
            <TabButton
              icon="/Assets/profile_complaints.svg"
              label="Complaints"
              path="/complaints-received"
            />
            <TabButton
              icon="/Assets/profile_feedback.svg"
              label="Feedback"
              path="/feedback/owner"
            />
          </div>

          {/* Centered white circle */}
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="relative lg:w-40 lg:h-40 h-25 w-25 bg-white rounded-full flex items-center justify-center">
              <div className="grid grid-cols-2 lg:gap-3 gap-2 lg:p-3 p-1">
                <img
                  src="/Assets/photos.svg"
                  alt="Photos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/view_photos')}
                />
                <img
                  src="/Assets/videos.svg"
                  alt="Videos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/uploaded_video')}
                />
                <img
                  src="/Assets/product.png"
                  alt="Products"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/product_service_list')}
                />
                <img
                  src="/Assets/verified-tick.svg"
                  alt="Verification"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/profile-verification')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for large screens */}
      <WebLinkModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} link={website} />

      {/* Bottom Sheet for small screens */}
      <BottomSheet isOpen={isBottomSheetOpen} onClose={() => setIsBottomSheetOpen(false)} link={website} />

      {/* Confirmation Popup */}
      <ConfirmationPopup
        onClose={() => setShowPopup(false)}
        show={showPopup}
      />
    </div>
  );
};

export default ProfileVisitorsView;