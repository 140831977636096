


// imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxgPb5Zh6v6VCUq5or1p73ZDJPPbie7N9Jd1kblZ2xYrI2XBdyEZHMLya6uA&s', // replace with actual path


// DirectMessage.jsx
// src/View/Pages/DirectMessage.jsx

// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import ChatList from '../../Components/chatlist_ex';
// import ChatWindow from '../../Components/chatmsg_ex';
// import { collection, query, where, orderBy, onSnapshot, addDoc, getDocs, serverTimestamp, updateDoc, doc } from 'firebase/firestore';
// import { db } from '../../../firebase_service';

// const DirectMessage = () => {
//   const [selectedMessageId, setSelectedMessageId] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
//   const [messages, setMessages] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [currentUser, setCurrentUser] = useState(null);

//   const { chatId } = useParams();

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchCurrentUser = async () => {
//       const userId = localStorage.getItem('userId');
      
//       if (userId) {
//         try {
//           const usersRef = collection(db, 'users');
//           const q = query(usersRef, where('userId', '==', userId));
//           const querySnapshot = await getDocs(q);
    
//           if (!querySnapshot.empty) {
//             const userData = querySnapshot.docs[0].data();
//             setCurrentUser({
//               id: userId,
//               userName: userData.userName || 'Unknown User',
//               email: userData.email || 'Unknown Email',
//               userImage: userData.userImage || '/Assets/default_avatar.png',
//             });
//           } else {
//             console.error("User document not found in Firestore");
//             navigate('/login');
//           }
//         } catch (error) {
//           console.error("Error fetching user data from Firestore:", error);
//           navigate('/login');
//         }
//       } else {
//         console.error("No userId found in localStorage");
//         navigate('/login');
//       }
//     };

//     fetchCurrentUser();
//   }, [navigate]);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth < 1024);
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       const usersSnapshot = await getDocs(collection(db, 'users'));
//       const usersData = usersSnapshot.docs.map(doc => ({ 
//         id: doc.data().userId,
//         ...doc.data() 
//       }));
//       setUsers(usersData);
//     };
//     fetchUsers();
//   }, []);

//   useEffect(() => {
//     if (!currentUser) return;
  
//     const q = query(collection(db, 'chats'), orderBy('lastMessageTime', 'desc'));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
//       // Filter to exclude chats marked as important
//       const relevantMessages = fetchedMessages.filter(msg => 
//         msg.participants.includes(currentUser.id) &&
//         !(currentUser.id === msg.senderUid ? msg.isImportantForSender : msg.isImportantForReceiver)
//       );
  
//       setMessages(relevantMessages);
//     });
  
//     return () => unsubscribe();
//   }, [currentUser]);
  

//   const handleSelectMessage = async (userId) => {
//     const selectedUser = users.find(user => user.id === userId);

//     if (!selectedUser || !currentUser) {
//       console.error(`No user found with ID: ${userId} or currentUser is not defined`);
//       return;
//     }

//     setSelectedUser(selectedUser);

//     try {
//       const chatId = [currentUser.id, userId].sort().join('_');

//       const chatsRef = collection(db, 'chats');
//       const q = query(chatsRef, where('chatId', '==', chatId));
//       const querySnapshot = await getDocs(q);

//       let existingChat = null;

//       if (!querySnapshot.empty) {
//         existingChat = querySnapshot.docs[0];
//         setSelectedMessageId(existingChat.id);

//         // Update user's presence in the chat
//         await updateDoc(doc(db, 'chats', existingChat.id), {
//           [currentUser.id === existingChat.data().senderUid ? 'senderIsInChatPage' : 'receiverIsInChatPage']: true
//         });
//       } else {
//         const newChatDoc = await addDoc(collection(db, 'chats'), {
//           chatId: chatId,
//           participants: [currentUser.id, userId],
//           lastMessage: '',
//           lastMessageTime: serverTimestamp(),
//           lastMessageSeen: false,
//           lastMessageSeenTime: null,
//           lastMessageUid: '',
//           receiverUid: userId,
//           receiverName: selectedUser?.userName || 'Unknown User',
//           senderUid: currentUser.id,
//           senderName: currentUser.userName || 'Unknown User',
//           receiverIsInChatPage: false,
//           senderIsInChatPage: true,
//           isImportantForSender: false,
//           isImportantForReceiver: false,
//         });

//         setSelectedMessageId(newChatDoc.id);
//         existingChat = { id: newChatDoc.id, data: () => newChatDoc };
//       }

//       if (isMobile) {
//         navigate(`/direct_message/chat/${existingChat.id}`);
//       }

//     } catch (error) {
//       console.error("Error fetching or creating chat:", error);
//     }
//   };

//   const selectedMessage = messages.find(msg => msg.id === selectedMessageId || msg.id === chatId);

//   if (!currentUser) {
//     return <div></div>;
//   }

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        // <div className="w-full flex justify-start items-center space-x-2">
        //   {/* <img
        //     src="/Assets/arrow_back.svg"
        //     alt="Back"
        //     className="cursor-pointer mr-2 h-6 w-6"
        //     onClick={() => navigate(-1)}
        //   /> */}
        //   <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Direct Message</h1>
        // </div>
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex w-full h-full">
//         {(!isMobile || !chatId) && (
//           <div className="w-full lg:w-1/4 h-full bg-[#FFFFFF] border-r">
//             <div className="h-full overflow-y-auto">
//               <ChatList
//                 messages={messages}
//                 users={users}
//                 onSelectMessage={handleSelectMessage}
//                 selectedMessageId={selectedMessageId || chatId}
//                 currentUser={currentUser}
//               />
//             </div>
//           </div>
//         )}
//         {((!isMobile && selectedMessageId) || (isMobile && chatId)) && (
//           <div className={`${isMobile ? 'w-full' : 'hidden lg:flex lg:w-2/4'} h-full`}>
//             <ChatWindow
//               message={selectedMessage}
//               selectedUser={selectedUser}
//               currentUser={currentUser}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default DirectMessage;
//commented original code above
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import ChatList from '../../Components/chatlist_ex';
import ChatWindow from '../../Components/chatmsg_ex';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase_service';

const DirectMessage = () => {
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Add state to check screen size

  const { chatId } = useParams(); // Extract chatId from URL params
  const navigate = useNavigate();
  

  // Fetch the current user's chat messages from the chats collection
  useEffect(() => {
    const fetchChats = async () => {
      const companyUid = localStorage.getItem('company_uid'); // Get logged-in user's UID
      console.log('Fetching chats for companyUid:', companyUid); // Debug log

      if (companyUid) {
        setCurrentUserUid(companyUid); // Store the UID for future use

        const q = query(
          collection(db, 'chats'),
          where('participants', 'array-contains', companyUid) // Only check for participants, no sorting
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMessages(fetchedMessages); // No sorting needed
        });

        return () => unsubscribe(); // Clean up the snapshot listener
      } else {
        console.error('No companyUid found in localStorage.');
        // Handle missing localStorage value
      }
    };

    fetchChats();
  }, []);

  // Handle resizing of the window to detect if it is mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ADD THIS USEEFFECT TO HANDLE INITIAL CHAT SELECTION
  useEffect(() => {
    if (chatId && messages.length > 0) {
        const selectedMessage = messages.find(msg => msg.id === chatId);

        if (selectedMessage) {
            setSelectedUser({
                id: selectedMessage.receiverUid === currentUserUid ? selectedMessage.senderUid : selectedMessage.receiverUid,
                userName: selectedMessage.receiverUid === currentUserUid ? selectedMessage.senderName : selectedMessage.receiverName,
                userImage: selectedMessage.receiverUid === currentUserUid ? selectedMessage.senderImage : selectedMessage.receiverImage,
            });
            setSelectedMessageId(selectedMessage.id);
        }
    }
}, [chatId, messages, currentUserUid]);

  
  // const filteredMessages = messages.filter(
  //   (msg) =>
  //     !(msg.isImportantForSender && msg.senderUid === currentUserUid) &&
  //     !(msg.isImportantForReceiver && msg.receiverUid === currentUserUid)
  // );
  

  // Handle selecting a message from the chat list
  const handleSelectMessage = (chat) => {
    setSelectedUser({
      id: chat.receiverUid === currentUserUid ? chat.senderUid : chat.receiverUid,
      userName: chat.receiverUid === currentUserUid ? chat.senderName : chat.receiverName,
      userImage: chat.receiverUid === currentUserUid ? chat.senderImage : chat.receiverImage,
    });
    setSelectedMessageId(chat.id);
    navigate(`/direct_message/chat/${chat.id}`);
  };

  const selectedMessage = messages.find(msg => msg.id === chatId);

  // Conditionally render based on screen size
  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          {/* Show Back Arrow on Small Screens */}
          <img
  src="/Assets/arrow_back.svg"
  alt="Back"
  className="cursor-pointer mr-2 h-6 w-6 md:hidden" // Add 'md:hidden' to hide on larger screens
  onClick={() => setSelectedMessageId(null)} // Set selectedMessageId to null to navigate back to the chat list
/>

          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Direct Message</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      {/* For Large Screens: Show both Chat List and Chat Window */}
      <div className="hidden md:flex w-full h-full">
        <div className="w-full lg:w-1/4 h-full bg-[#FFFFFF] border-r">
          <div className="h-full overflow-y-auto">
            {messages.length === 0 ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                No messages available
              </div>
            ) : (
              <ChatList
                messages={messages}
                currentUserUid={currentUserUid}
                onSelectMessage={handleSelectMessage}
                selectedMessageId={selectedMessageId || chatId}
              />
            )}
          </div>
        </div>
        {/* Chat Window Section */}
        {selectedMessage && (
          <div className="w-full lg:w-[58%] h-full ">
            <ChatWindow
              message={selectedMessage}
              selectedUser={selectedUser}
              currentUserUid={currentUserUid}
            />
          </div>
        )}
      </div>

      {/* For Small Screens: Conditionally Render Chat List or Chat Window */}
      <div className="md:hidden">
        {/* Display chat list if no message is selected */}
        {!selectedMessageId && (
          <div className="w-full h-full bg-[#FFFFFF] border-r">
            <div className="h-full overflow-y-auto">
              {messages.length === 0 ? (
                <div className="flex items-center justify-center h-full text-gray-500">
                  No messages available
                </div>
              ) : (
                <ChatList
                  messages={messages}
                  currentUserUid={currentUserUid}
                  onSelectMessage={handleSelectMessage}
                  selectedMessageId={selectedMessageId || chatId}
                />
              )}
            </div>
          </div>
        )}

        {/* Display chat window if a message is selected */}
        {selectedMessageId && (
          <div className="w-full h-full ">
            <div className="p-2 ">
            
              <ChatWindow
                message={selectedMessage}
                selectedUser={selectedUser}
                currentUserUid={currentUserUid}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectMessage;






