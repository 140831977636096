import React from 'react';
import { ScaleLoader } from 'react-spinners';

const LoaderSpinner = ({ visible, height = 15 }) => { // Default height is 30 if no height is provided
  return (
    <div className={`flex justify-center items-center ${visible ? '' : 'hidden'}`}>
      <ScaleLoader
        height={height} // Use the height prop
        width={4}
        radius={2}
        margin={2}
        color="#DB0000"
        loading={visible}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoaderSpinner;
