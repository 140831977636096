// import React from 'react';

// const FilePicker = ({ label, width = 'w-full', height = 'h-20' }) => {
//   return (
//     <div className="flex flex-col mt-4">
//       <label className="mb-2 font-semibold text-gray-700 text-left">{label}</label>
//       <div className={`flex justify-center items-center ${width} ${height} bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300`}>
//         <input
//           type="file"
//           className="hidden"
//           id={`file-input-${label}`}
//         />
//         <label htmlFor={`file-input-${label}`} className="flex flex-col items-center cursor-pointer">
//           <img src="Assets/review.svg" alt="plus" className="w-8 h-8"/>
//         </label>
//       </div>
//     </div>
//   );
// };

// export default FilePicker;
import React, { useState, useEffect } from 'react';
import LoaderSpinner from './loader_spinner';
import UploadDocuments from '../../Controller/firebase/firebase_documents';
import { showToast } from './toast';

const  FilePicker = ({ label, width = 'w-full', height = 'h-20', file, onFileChange, required = false }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(file);
  const [downloadURL, setDownloadURL] = useState(null);

  useEffect(() => {
    setSelectedFile(file);
  }, [file]);

  const handleFileChange = async (event) => {
    const uploadedFile = event.target.files[0];
    const fileSizeLimit = 25 * 1024 * 1024; // 25 MB in bytes

    if (uploadedFile && uploadedFile.size <= fileSizeLimit) {
      if (uploadedFile.type === 'application/pdf') {
        setLoading(true);
        try {
          const url = await UploadDocuments(uploadedFile);
          setDownloadURL(url);
          setSelectedFile(uploadedFile);
          onFileChange(label, url);
          setLoading(false);
        } catch (error) {
          console.error('Error uploading file:', error);
          setLoading(false);
          showToast('Error uploading file. Please try again.', 'error');
        }
      } else {
        showToast('Please upload a PDF document.', 'error');
      }
    } else {
      showToast('File size should not exceed 25 MB.', 'error');
    }
  };

  const triggerFileInput = () => {
    document.getElementById(`file-input-${label}`).click();
  };

  return (
    <div className="flex flex-col mt-2">
      <label className="mb-2 font-semibold text-[#171717] text-left text-[12px] lg:text-[15px]">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <div 
        className={`relative flex justify-center items-center ${width} ${height} bg-[#E5E5E5] rounded-md cursor-pointer hover:bg-[#c7c4c4]`}
        onClick={triggerFileInput}
      >
        {loading ? (
          <div className="absolute inset-0 flex justify-center items-center bg-[#E5E5E5] rounded-md">
            <LoaderSpinner visible={true} />
          </div>
        ) : (
          selectedFile ? (
            <div className="flex justify-between items-center w-full p-2">
              <div className="flex items-center">
                <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-2" />
                <span>Document</span>
              </div>
              <div className="flex space-x-2">
                {downloadURL && (
                  <a 
                    href={downloadURL} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-red-600"
                    onClick={(e) => e.stopPropagation()} // Prevent triggering file input when clicking "View"
                  >
                    View
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <img src="Assets/review.svg" alt="plus" className="w-6 h-6"/>
            </div>
          )
        )}
        <input
          type="file"
          className="hidden"
          accept="application/pdf"
          id={`file-input-${label}`}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default FilePicker;