import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReportContainer from "../../Components/report";



const categories = [
  { id: 1, key: 'logistics', smallUrl: './logisticsmobile.svg', largeUrl: './new-logistics.svg' },
  { id: 2, key: 'legal', smallUrl: './legal_mobile.svg', largeUrl: './new-legal.svg' },
  { id: 3, key: 'finance', smallUrl: './finance_small.svg', largeUrl: './Finance.svg' },
  { id: 4, key: 'start_up', smallUrl: './start-up-mobile.svg', largeUrl: './new-start-up.svg' },
  { id: 5, key: 'jewelry', smallUrl: './jewelry_small.svg', largeUrl: './jewelry.svg' },
  { id: 6, key: 'seller', smallUrl: './seller-mobile.svg', largeUrl: './new-seller.svg' },
  { id: 7, key: 'investor', smallUrl: './investor-mobile.svg', largeUrl: './new-investor.svg' },
  { id: 8, key: 'buyer', smallUrl: './buyermobile35827.svg', largeUrl: './new-buyer.svg' },
  { id: 9, key: 'market_place', smallUrl: './marketplacemobile.svg', largeUrl: './new-market.svg' },
  { id: 10, key: 'projects', smallUrl: './projects(2).svg', largeUrl: './new-projects.svg' }
];

const TradeRolePageSelection = () => {
  const [imageUrls, setImageUrls] = useState(() => {
    const initialUrls = {};
    categories.forEach(category => {
      initialUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
    });
    return initialUrls;
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { completed } = location.state || { completed: [] };

  useEffect(() => {
    console.log('completed:', completed);

    const handleResize = () => {
      const newUrls = {};
      categories.forEach(category => {
        newUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
      });
      setImageUrls(newUrls);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [completed]);

  // const handleNavigation = (key) => {
  //   localStorage.setItem('category', key); 
  //   navigate('/category-listings', { state: { completed } });
  // };
  const handleNavigation = (key) => {
    const selectedCategory = categories.find(category => category.key === key);
    if (selectedCategory) {
      localStorage.setItem('category', selectedCategory.id); // Store category ID
      localStorage.setItem('category_key', selectedCategory.key);
      navigate('/category-listings', { state: { completed } });
    }
  };
  
  return (
    <div className="bg-[#FBFCF8] min-h-screen p-4 overflow-y-auto">
      {/* Updated Header Section */}
      <div className="ml-5 mt-4">
        <h1 className="text-2xl font-bold text-black">Select Trade Role</h1>
        <p className="text-gray-600 mt-2">Choose a category </p>
      </div>
      <div className="absolute top-5 right-6">
        <ReportContainer />
      </div>
      <div className="p-0 mt-10">
        <div className="hex-grid-container-wrapper">
          <div className="hex-grid-container">
            {categories.map((category) => (
              <div
                key={category.key}
                className={`hex-item ${category.key}`}
                onClick={() => handleNavigation(category.key)}
              >
                <img src={imageUrls[category.key]} alt={category.key} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeRolePageSelection;
