// import React from 'react';
// import "./filter_table.css";








// const FilterTable = ({ filter, setFilter, isMobile }) => {
//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   return (
//     <div className={`p-4 ${isMobile ? 'w-full' : 'mt-12 ml-24'}`}>
//       <div className="flex items-center mb-4 mr-7 font-poppins">
//         <img src="./trade_icon.svg" alt="Trade Role Icon" className="h-6 w-6 mr-2" />
//         <h2 className="font-techna text-2xl font-extrabold leading-tight text-custom-black w-full">
//           Trade Role
//         </h2>
//       </div>
//       <div className="mt-2">
//         {[
//           'Logistics', 
//           'Hand Craft', 
//           'Financial Institutions', 
//           'Start-Up', 
//           'Precious Stones / Metals', 
//           'Seller', 
//           'Buyer', 
//           'Investor', 
//           'Projects', 
//           'Marketplace'
//         ].map(role => (
//           <div key={role} className="mb-4">
//             <label className="flex items-center space-x-2">
//               <input 
//                 type="radio" 
//                 value={role} 
//                 checked={filter === role} 
//                 onChange={handleFilterChange} 
//                 className="custom-radio"
//               />
//               <span className="ml-2 text-option whitespace-nowrap">
//                 {role}
//               </span>
//             </label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default FilterTable;


import React from 'react';
import "./filter_table.css";
import { categoryMapping } from './category';


const FilterTable = ({ filter, setFilter, isMobile }) => {
  const handleFilterChange = (event) => {
    const selectedCategory = parseInt(event.target.value, 10);
    setFilter(selectedCategory);
    
  };
  console.log("Current Filter:", filter);  // Log current filter state
  console.log("Category Mapping:", categoryMapping);  // Log category mapping
  return (
    <div className={`p-4 ${isMobile ? 'w-full' : 'mt-12 ml-24'}`}>
      <div className="flex items-center mb-4 mr-7 font-poppins">
        <img src="./trade_icon.svg" alt="Trade Role Icon" className="h-6 w-6 mr-2" />
        <h2 className="font-poppins text-2xl font-semibold leading-tight text-custom-black w-full">
          Trade Role
        </h2>
      </div>
      <div className="mt-2 font-semibold font-gotham scroll-container" >
        {Object.entries(categoryMapping).map(([key, role]) => (
          <div key={key} className="mb-6">
            <label className="flex items-center space-x-2 cursor-pointer">
              <input 
                type="radio" 
                value={key}
                checked={filter === parseInt(key)}
                onChange={handleFilterChange} 
                className="custom-radio"
              />
              <span className="ml-2 text-option">
                {role}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterTable;