import React from 'react';

const ProductAdd = ({ text, onDelete }) => {
  return (
    <div className="flex justify-between font-technaitems-center bg-gray-200 rounded-lg lg:p-4 p-3 mb-2">
      <span className="text-[#B71C1C] font-semibold text-sm lg:text-[17px]">{text}</span>
      <img 
        src="/Assets/bin.svg" 
        alt="Delete" 
        className="h-6 w-6 cursor-pointer" 
        onClick={onDelete} 
      />
    </div>
  );
};

export default ProductAdd;
