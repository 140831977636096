// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import VideoCard from '../../Components/video_display_card';
// import DeletePopup from '../../Components/delete_pop_up';
// import { get_user_videos_api, delete_video_Id } from '../../../Controller/upload_videos'; // Import the API functions

// const UploadedVideo = () => {
//   const navigate = useNavigate();
//   const [videos, setVideos] = useState([]);
//   const [selectedVideo, setSelectedVideo] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [showMenu, setShowMenu] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   // Fetch videos and set the list of videos (without video URLs, which will be fetched later)
//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const videoData = await get_user_videos_api(); // Fetch the video list
//         console.log('Fetched videos:', videoData);
//         if (Array.isArray(videoData)) {
//           setVideos(videoData); // Set the list of videos in the state
//         } else {
//           console.error('Invalid response format.');
//         }
//       } catch (error) {
//         console.error("Error fetching videos:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchVideos();
//   }, []);

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   const handleUploadClick = () => {
//     navigate('/upload_video');
//   };

//   const handleDeleteClick = (video) => {
//     setSelectedVideo(video);
//     setShowPopup(true);
//   };

//   const confirmDelete = async () => {
//     if (!selectedVideo) return;
//     try {
//       await delete_video_Id(selectedVideo.id); // Call the API function to delete the video
//       setVideos(videos.filter(video => video.id !== selectedVideo.id)); // Remove video from the list
//       setSelectedVideo(null);
//     } catch (error) {
//       console.error("Error deleting video:", error);
//     } finally {
//       setShowPopup(false); // Close the popup
//     }
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   const toggleMenu = (index) => {
//     setShowMenu(showMenu === index ? null : index);
//   };

//   // Navigate to the VideoPlayer component and pass the videoId via state
//   const handleVideoClick = (videoId) => {
//     navigate(`/video_player`, { state: { videoId } }); // Pass videoId to the VideoPlayer component
//   };

//   return (
//     <div className="flex w-full h-full bg-[#F2F3F3] font-techna">
//       <Sidebar />
//       <div className="flex flex-col flex-grow">
//         <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//           <div className="w-full flex justify-start items-center space-x-2">
//             {/* <img
//               src="/Assets/arrow_back.svg"
//               alt="Back"
//               className="cursor-pointer mr-2 h-6 w-6"
//               onClick={handleBackClick}
//             /> */}
//             <h1 className="text-black font-bold text-xl">Upload Video</h1>
//           </div>
//           <div className="mr-5 md:mr-7 pr-5 flex self-end">
//             {/* <img
//               src="/Assets/review.svg"
//               alt="Upload"
//               className="cursor-pointer h-6 w-6"
//               onClick={handleUploadClick}
//             /> */}
//           </div>
//         </div>
//         <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//         <div className="lg:w-1/2 md:w-2/3 sm:w-2/3 p-5 lg:ml-5 mx-auto overflow-y-auto">
//           {isLoading ? (
//             <p>Loading videos...</p>
//           ) : videos.length === 0 ? (
//             <p>No videos uploaded yet.</p>
//           ) : (
//             <div className="h-[800px] sm:h-[540px] grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 p-8">
//               {videos.map((video, index) => (
//                 <div key={index} className="relative">
//                   <VideoCard
//                     videoId={video.id}
//                     thumbnail={video.thumb_nail_url || '/path-to-default-thumbnail'}
//                     onClick={() => handleVideoClick(video.id)} // Trigger navigation on click
//                   />
//                   <div className="absolute top-0 right-0 m-2 md:m-4">
//                     <img
//                       src="/Assets/dots.svg"
//                       alt="Menu"
//                       className="cursor-pointer h-5 w-5 md:h-6 md:w-6"
//                       onClick={() => toggleMenu(index)}
//                     />
//                   </div>
//                   {showMenu === index && (
//                     <div className="absolute top-10 right-0 bg-white border rounded shadow-lg">
//                       <button
//                         className="p-2 text-red-600 hover:bg-gray-100 w-full text-left"
//                         onClick={() => handleDeleteClick(video)}
//                       >
//                         Delete
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//       {showPopup && (
//         <DeletePopup
//           onClose={closePopup}
//           onConfirm={confirmDelete} // Pass the confirmDelete function
//           video={selectedVideo} // Pass the selected video to the popup
//         />
//       )}
//     </div>
//   );
// };

// export default UploadedVideo;
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { FaSpinner } from 'react-icons/fa'; // Import Font Awesome spinner
// import Sidebar from '../../Components/main_navbar';
// import VideoCard from '../../Components/video_display_card';
// import DeletePopup from '../../Components/delete_pop_up';
// import { get_user_videos_api, delete_video_Id } from '../../../Controller/upload_videos';

// const UploadedVideo = () => {
//   const navigate = useNavigate();
//   const { company_id } = useParams(); // Get company_id from URL if it exists
//   const [videos, setVideos] = useState([]);
//   const [selectedVideo, setSelectedVideo] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [showDeleteOption, setShowDeleteOption] = useState(null); // Track which video has the delete option shown
//   const [isLoading, setIsLoading] = useState(true);
//   const [isLoadingMore, setIsLoadingMore] = useState(false); // To track loading more videos
//   const [hasMoreVideos, setHasMoreVideos] = useState(true); // To check if more videos are available for loading
//   const [isMyProfile, setIsMyProfile] = useState(false); // To check if it's the user's own profile
//   const [skip, setSkip] = useState(0); // For pagination, track how many videos we've already fetched

//   useEffect(() => {
//     const fetchVideos = async () => {
//       setIsLoading(true);
//       try {
//         let idToUse;

//         if (company_id) {
//           // Viewing other profile
//           idToUse = company_id;
//           setIsMyProfile(false); // Not the logged-in user's profile
//         } else {
//           // Viewing own profile
//           const localCompanyId = localStorage.getItem('company_id');
//           if (!localCompanyId) {
//             console.error("No company ID found in localStorage.");
//             setIsLoading(false);
//             return;
//           }
//           idToUse = localCompanyId;
//           setIsMyProfile(true); // This is the user's own profile
//         }

//         // Pass the appropriate company_id to the API
//         const videoData = await get_user_videos_api(idToUse, skip);
//         console.log('Fetched videos:', videoData); // Fetch videos with pagination
//         if (Array.isArray(videoData)) {
//           if (videoData.length < 10) {
//             setHasMoreVideos(false); // No more videos to load if less than 10 are returned
//           }
//           setVideos((prevVideos) => [...prevVideos, ...videoData]); // Append new videos to the existing list
//         } else {
//           console.error('Invalid response format.');
//         }
//       } catch (error) {
//         console.error("Error fetching videos:", error);
//       } finally {
//         setIsLoading(false);
//         setIsLoadingMore(false); // Stop loading more videos once the fetch is done
//       }
//     };

//     fetchVideos();
//   }, [company_id, skip]);

//   const handleLoadMore = () => {
//     setIsLoadingMore(true);
//     setSkip((prevSkip) => prevSkip + 10); // Increment the skip value by 10 to fetch the next set
//   };

//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   const handleUploadClick = () => {
//     navigate('/upload_video');
//   };

//   const handleDotClick = (index) => {
//     setShowDeleteOption(index === showDeleteOption ? null : index); // Toggle the delete option
//   };

//   const handleDeleteClick = (video) => {
//     setSelectedVideo(video);
//     setShowPopup(true);
//   };

//   const confirmDelete = async () => {
//     if (!selectedVideo) return;
//     try {
//       await delete_video_Id(selectedVideo.id); // Call the API function to delete the video
//       setVideos(videos.filter(video => video.id !== selectedVideo.id)); // Remove video from the list
//       setSelectedVideo(null);
//     } catch (error) {
//       console.error("Error deleting video:", error);
//     } finally {
//       setShowPopup(false); // Close the popup
//     }
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   const handleVideoClick = (videoId) => {
//     navigate(`/video_player`, { state: { videoId } }); // Pass videoId to the VideoPlayer component
//   };

//   return (
//     <div className="flex w-full h-full bg-[#F2F3F3] font-techna">
//     <Sidebar />
//     <div className="flex flex-col flex-grow">
//       <div className="flex justify-between items-center ml-7 mt-4 md:mt-[40px] w-4/5">
//         <h1 className="text-black font-bold text-xl">Videos</h1>
//         {isMyProfile && (
//           <img
//             src="/Assets/review.svg"
//             alt="Upload"
//             className="cursor-pointer h-5 w-5"
//             onClick={handleUploadClick}
//           />
//         )}
//       </div>
  
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-1/2 md:w-2/3 sm:w-2/3 p-5 lg:ml-5 mx-auto overflow-y-auto">
//         {isLoading ? (
//           <div className="flex justify-center items-center">
//             <FaSpinner className="animate-spin text-red-600 text-2xl" /> {/* Spinner with animation */}
//           </div>
//         ) : videos.length === 0 ? (
//           <p>No videos uploaded yet.</p>
//         ) : (
//           <>
//             <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 ">
//               {videos.map((video, index) => (
//                 <div key={index} className="relative">
//                   <VideoCard
//                     videoId={video.id}
//                     thumbnail={video.thumb_nail_url || '/path-to-default-thumbnail'}
//                     onClick={() => handleVideoClick(video.id)} // Trigger navigation on click
//                   />
//                   {isMyProfile && (
//                     <>
//                       <img
//                         src="/Assets/dots.svg" // Add your 3-dot icon here
//                         alt="Options"
//                         className="absolute top-2 right-2 w-5 h-5 cursor-pointer"
//                         onClick={() => handleDotClick(index)}
//                       />
//                       {showDeleteOption === index && (
//                         <div
//                           className="absolute top-8 right-2 bg-white text-red-600 p-2 rounded-lg shadow-md cursor-pointer"
//                           onClick={() => handleDeleteClick(video)}
//                         >
//                           Delete
//                         </div>
//                       )}
//                     </>
//                   )}
//                 </div>
//               ))}
//             </div>
//             {hasMoreVideos && (
//               <div className="flex justify-center mt-4">
//                 <button
//                   onClick={handleLoadMore}
//                   className="px-4 py-2 bg-[#DB0000] text-white rounded-md"
//                   disabled={isLoadingMore}
//                 >
//                   {isLoadingMore ? (
//                     <FaSpinner className="animate-spin text-white" />
//                   ) : (
//                     'Load More'
//                   )}
//                 </button>
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//     {showPopup && (
//       <DeletePopup
//         onClose={closePopup}
//         onConfirm={confirmDelete} // Pass the confirmDelete function
//         video={selectedVideo} // Pass the selected video to the popup
//       />
//     )}
//   </div>
  
//   );
// };

// export default UploadedVideo;
import React, { useState, useEffect ,useRef} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa'; // Import Font Awesome spinner
import Sidebar from '../../Components/main_navbar';
import VideoCard from '../../Components/video_display_card';
import DeletePopup from '../../Components/delete_pop_up';
import { get_user_videos_api, delete_video_Id } from '../../../Controller/upload_videos';

const UploadedVideo = () => {
  const navigate = useNavigate();
  const { company_id } = useParams(); // Get company_id from URL if it exists
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeleteOption, setShowDeleteOption] = useState(null); // Track which video has the delete option shown
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // To track loading more videos
  const [hasMoreVideos, setHasMoreVideos] = useState(true); // To check if more videos are available for loading
  const [isMyProfile, setIsMyProfile] = useState(false); // To check if it's the user's own profile
  const [skip, setSkip] = useState(0); // For pagination, track how many videos we've already fetched
  const [verificationStatus, setVerificationStatus] = useState(''); // State for verification status
  const observerRef = useRef(); // Ref for the IntersectionObserver

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoadingMore(true); // Start loading more videos
      try {
        let idToUse;

        if (company_id) {
          // Viewing other profile
          idToUse = company_id;
          setIsMyProfile(false); // Not the logged-in user's profile
        } else {
          // Viewing own profile
          const localCompanyId = localStorage.getItem('company_id');
          const status = localStorage.getItem('verificationStatus'); // Get verification status from localStorage
          setVerificationStatus(status); // Set verification status

          if (!localCompanyId) {
            console.error("No company ID found in localStorage.");
            setIsLoading(false);
            return;
          }
          idToUse = localCompanyId;
          setIsMyProfile(true); // This is the user's own profile
        }

        // Pass the appropriate company_id to the API
        const videoData = await get_user_videos_api(idToUse, skip);
        console.log('Fetched videos:', videoData); // Fetch videos with pagination
        if (Array.isArray(videoData)) {
          if (videoData.length < 10) {
            setHasMoreVideos(false); // No more videos to load if less than 10 are returned
          }
          setVideos((prevVideos) => [...prevVideos, ...videoData]); // Append new videos to the existing list
        } else {
          console.error('Invalid response format.');
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false); // Stop loading more videos once the fetch is done
      }
    };

    fetchVideos();
  }, [company_id, skip]);


  // Infinite scroll logic with IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoadingMore && hasMoreVideos) {
        setSkip((prevSkip) => prevSkip + 10); // Increment skip to fetch next set of videos
      }
    });

    if (observerRef.current) {
      observer.observe(observerRef.current); // Start observing
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current); // Clean up observer on unmount
      }
    };
  }, [isLoadingMore, hasMoreVideos]);

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleUploadClick = () => {
    navigate('/upload_video');
  };

  const handleDotClick = (index) => {
    setShowDeleteOption(index === showDeleteOption ? null : index); // Toggle the delete option
  };

  const handleDeleteClick = (video) => {
    setSelectedVideo(video);
    setShowPopup(true);
  };

  const confirmDelete = async () => {
    if (!selectedVideo) return;
    try {
      await delete_video_Id(selectedVideo.id); // Call the API function to delete the video
      setVideos(videos.filter(video => video.id !== selectedVideo.id)); // Remove video from the list
      setSelectedVideo(null);
    } catch (error) {
      console.error("Error deleting video:", error);
    } finally {
      setShowPopup(false); // Close the popup
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleVideoClick = (videoId) => {
    navigate(`/video_player`, { state: { videoId } }); // Pass videoId to the VideoPlayer component
  };

  return (
    <div className="flex w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <div className="flex flex-col flex-grow">
        <div className="flex justify-between items-center ml-7 mt-4 md:mt-[40px] w-4/5">
          <h1 className="text-black font-semibold text-xl">Videos</h1>
          {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING' && (
            <img
              src="/Assets/review.svg"
              alt="Upload"
              className="cursor-pointer h-5 w-5 mr-3"
              onClick={handleUploadClick}
            />
          )}
        </div>

        <div className="border-b border-black mt-4 md:mt-[25px]"></div>
        <div className="lg:w-1/2 md:w-2/3 sm:w-2/3 p-5 lg:ml-5 mx-auto overflow-y-auto">
        {isLoading ? (
  <div className="flex justify-center items-center h-screen">
    <FaSpinner className="animate-spin text-gray-500 text-2xl" /> {/* Spinner with animation */}
  </div>
) : videos.length === 0 ? (
  <div className="flex justify-center items-center h-full min-h-[500px] lg:ml-72">
    <p className="text-center text-gray-500">No videos to show!.</p>
  </div>
) : (
  <>
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 ">
      {videos.map((video, index) => (
        <div key={index} className="relative">
          <VideoCard
            videoId={video.id}
            thumbnail={video.thumb_nail_url || '/path-to-default-thumbnail'}
            onClick={() => handleVideoClick(video.id)} // Trigger navigation on click
          />
          {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING' && (
            <>
              <img
                src="/Assets/dots.svg"
                alt="Options"
                className="absolute top-2 right-2 w-5 h-5 cursor-pointer"
                onClick={() => handleDotClick(index)}
              />
              {showDeleteOption === index && (
                <div
                  className="absolute top-8 right-2 bg-white text-red-600 p-2 rounded-lg shadow-md cursor-pointer"
                  onClick={() => handleDeleteClick(video)}
                >
                  Delete
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
    {hasMoreVideos && (
                <div ref={observerRef} className="flex justify-center mt-4">
                  {isLoadingMore && (
                    <FaSpinner className="animate-spin text-gray-500 text-2xl" /> // Spinner for loading more
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showPopup && (
        <DeletePopup
          onClose={closePopup}
          onConfirm={confirmDelete} // Pass the confirmDelete function
          video={selectedVideo} // Pass the selected video to the popup
        />
      )}
    </div>
  );
};

export default UploadedVideo;
