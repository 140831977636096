import React, { useEffect } from 'react';

const ImagePopup = ({ imageUrl, onClose }) => {
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('overlay')) {
                onClose();
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <>
            {/* Overlay background */}
            <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
            
            {/* Popup container */}
            <div className="fixed inset-0 flex items-center justify-center overlay">
                <div className="rounded-lg  shadow-lg text-center mx-4 flex flex-col items-center">
                    {/* Image to be displayed in popup */}
                    <img
                        src={imageUrl}
                        alt="Popup Image"
                        className="w-full sm:w-auto max-w-xs sm:max-w-sm h-auto"
                    />
                </div>
            </div>
        </>
    );
};

export default ImagePopup;
