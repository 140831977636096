import React, { useState, useEffect } from 'react';
import CompanyHeader from '../../Components/company_header';
import FilterTable from '../../Components/trade_selection_filter_table';
import CompanyCard from "../../Components/comapny_card";
import Sidebar from '../../Components/main_navbar';
import { get_sub_categories_api } from '../../../Controller/sub_categories_home';
import { useNavigate } from 'react-router-dom';
import { categoryMapping } from '../../Components/category';
import { FaSpinner } from 'react-icons/fa';

const MainPageSearch = ({ category = "Companies", hideLogo = false, hideFilterTable = false, hideSidebar = false }) => {
  const [filter, setFilter] = useState(0);  // Initialize category as 0 (default/all)
  const [search, setSearch] = useState('');
  const [lat, setLat] = useState(null);  // Latitude for location search
  const [lon, setLon] = useState(null);  // Longitude for location search
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFilterTableOpen, setIsFilterTableOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch companies based on the category, search query, and location (lat/lon)
  const fetchCompaniesByCategory = async (selectedCategory, searchKey = '', latitude, longitude) => {
    setLoading(true);
    try {
      const response = await get_sub_categories_api(selectedCategory, 0, 10, latitude, longitude, searchKey);
      console.log('API Response:', response);
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch companies when filter (category), search, or location changes
  useEffect(() => {
    fetchCompaniesByCategory(filter, search, lat, lon);
  }, [filter, search, lat, lon]);

  // Category selection handler
  const handleCategorySelection = (selectedCategory) => {
    setFilter(selectedCategory);  // Set selected category
  };

  const toggleFilterTable = () => {
    setIsFilterTableOpen(!isFilterTableOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="h-screen flex flex-col bg-[#FBFCF8] font-poppins">
      {/* Company Header with search and location functionality */}
      <CompanyHeader setSearch={setSearch} hideLogo={hideLogo} onLocationSelect={(latitude, longitude) => {
        setLat(latitude);  // Set latitude from location input
        setLon(longitude);  // Set longitude from location input
      }} />

      {/* Mobile Filter Button */}
      <button className="md:hidden fixed top-4 left-4 z-50" onClick={toggleFilterTable}>
        <img src="./hamburger_icon.svg" alt="Filter Icon" className="h-8 w-8" />
      </button>

      <div className="flex-grow flex overflow-hidden">
        <div className="flex-grow flex flex-col md:flex-row">
          <div className="md:w-1/2 flex flex-col p-4">
            <h1 className="text-left font-poppins lg:text-xl text-l font-semibold leading-tight text-custom-red mt-4 md:mt-16 md:ml-8">
              {filter === 0 ? 'All Companies' : categoryMapping[filter]}  {/* Show selected category */}
            </h1>

            {/* Display company cards */}
            {/* <div className="flex-grow overflow-y-auto mt-4 px-2 h-full"> */}
            <div className="flex-grow overflow-y-auto mt-4 px-2 h-[calc(100vh-200px)] md:h-auto">
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <FaSpinner className="animate-spin text-custom-red text-3xl" />
                </div>
              ) : companies.length === 0 ? (
                <div className="flex justify-center items-center h-full">
                <p className='text-gray-600  '>No companies found!</p>
                </div>
              ) : (
                companies.map(company => (
                  <div
                    key={company.id}
                    className="w-full p-2 mb-4"
                    onClick={() => navigate(`/profile2/${company.id}`)}
                  >
                    <CompanyCard company={company} />
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Filter Table for Categories (Desktop View) */}
          {!hideFilterTable && (
            <div className="hidden md:block md:w-1/2 p-4 overflow-y-auto">
              <FilterTable filter={filter} setFilter={handleCategorySelection} />
            </div>
          )}
        </div>

        {/* Sidebar */}
        {!hideSidebar && (
          <div className="hidden md:block w-64 p-4">
            <Sidebar />
          </div>
        )}
      </div>

      {/* Mobile Filter Table */}
      {isFilterTableOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 md:hidden">
          <div className="bg-white w-64 p-4 absolute left-0 h-full overflow-auto">
            <button className="text-right mb-4" onClick={toggleFilterTable}>
              <img src="/Assets/Close_round.svg" alt="Close Icon" className="h-6 w-6" />
            </button>
            <FilterTable filter={filter} setFilter={handleCategorySelection} isMobile={true} />
          </div>
        </div>
      )}

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 md:hidden">
          <div className="bg-white w-64 p-4 absolute left-0 h-full overflow-auto">
            <button className="text-right mb-4" onClick={toggleMobileMenu}>
              <img src="/Assets/Close_round.svg" alt="Close Icon" className="h-6 w-6" />
            </button>
            <Sidebar />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPageSearch;
