// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import FilePicker from '../../Components/file_picker';
// import { useNavigate } from 'react-router-dom';
// import Button from '../../Components/button';
// import ReportContainer from '../../Components/report';


// const CompanyDocs = () => {
//   const navigate = useNavigate();
      
//   const handleBackClick = () => {
//     navigate('/profile1');
//   };
  

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-poppins">
//       <Sidebar />
//       <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
//             <div className="w-full flex justify-start items-center space-x-2">
                
//               <img
//                 src="/Assets/arrow_back.svg"
//                 alt="Back"
//                 className="cursor-pointer mr-2 h-5 w-5"
//                 onClick={handleBackClick}
//               />
//               <h1 className="text-black font-bold text-xl">Company Documents</h1>   </div>
//               <ReportContainer/>
//           </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="w-4/5 ml-9">
//       <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-poppins" style={{ height: '600px', width: '450px' }}>
       
//         <div className="flex flex-col ">
//           <FilePicker label="Trading License" width="w-70" height="h-20" />
//           <FilePicker label="Trading Register" width="w-70" height="h-20" />
//           <FilePicker label="Tenancy Contract" width="w-70" height="h-20"/>
//         </div>
//         <div className="flex  lg:mt-24 mt-14">
//         <Button
//           text="Save"
//           borderColor="border-red-600"
//           backgroundColor="bg-[#DB0000]"
//           textColor="text-white"
//           navigateTo="/details_page"
//         />
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default CompanyDocs;

import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import FilePicker from '../../Components/file_picker';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/button';
import ReportContainer from '../../Components/report';
import { Toast } from '../../Components/toast';
import { saveCompanyDocuments } from '../../../Controller/company_document';

const CompanyDocs = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState({
    'Business License': '',
    'Tenancy Contract': '',
    'Certificate of Incorporation/Trading Register': '',
  });
  const [loading, setLoading] = useState(false);

  const handleFileChange = (label, url) => {
    setDocuments(prevState => ({
      ...prevState,
      [label]: url
    }));
  };

  const handleSave = async () => {
    saveCompanyDocuments(documents, navigate, setLoading);
    localStorage.setItem('COMPANY_DETAILS_DOCUMENTS_COMPLETED', 'true'); // Call the controller function
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Documents</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex flex-col font-semibold font-poppins">
            <FilePicker label="Business License" width="w-70" height="h-20" onFileChange={handleFileChange} required />
            <FilePicker label="Tenancy Contract" width="w-70" height="h-20" onFileChange={handleFileChange} required />
            <FilePicker label="Certificate of Incorporation/Trading Register" width="w-70" height="h-20" onFileChange={handleFileChange} />
          </div>
          <div className="flex justify-center items-center mt-10">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDocs;


// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import DocumentUpload from '../../Components/document_upload'; // Import DocumentUpload component
// import { useNavigate } from 'react-router-dom';
// import Button from '../../Components/button';
// import ReportContainer from '../../Components/report';
// import { post_documents } from '../../../Controller/company_details';
// import { showToast, Toast } from '../../Components/toast';
// import UploadDocuments from '../../../Controller/firebase/firebase_documents';

// const CompanyDocs = () => {
//   const navigate = useNavigate();
//   const [documents, setDocuments] = useState({
//     'Trading License': '',
//     'Trading Register': '',
//     'Tenancy Contract': '',
//   });
//   const [loading, setLoading] = useState(false);

//   const handleFileChange = async (e, label) => {
//     const uploadedFile = e.target.files[0];
//     const fileSizeLimit = 25 * 1024 * 1024; // 25 MB in bytes

//     if (uploadedFile && uploadedFile.size <= fileSizeLimit) {
//       if (uploadedFile.type === 'application/pdf') {
//         setLoading(true);
//         try {
//           const url = await UploadDocuments(uploadedFile); // Upload to Firebase
//           setDocuments(prevState => ({
//             ...prevState,
//             [label]: url
//           }));
//           showToast(`${label} uploaded successfully.`, 'success');
//         } catch (error) {
//           console.error('Error uploading file:', error);
//           showToast('Error uploading file. Please try again.', 'error');
//         } finally {
//           setLoading(false);
//         }
//       } else {
//         showToast('Please upload a PDF document.', 'error');
//       }
//     } else {
//       showToast('File size should not exceed 25 MB.', 'error');
//     }
//   };

//   const handleSave = async () => {
//     if (!documents['Tenancy Contract']) {
//       showToast('Please add the Tenancy Contract before saving.', 'error');
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await post_documents(
//         documents['Tenancy Contract'],
//         documents['Trading Register'],
//         documents['Trading License']
//       );

//       if (response.status === 202) {
//         navigate('/home');
//       } else {
//         showToast('Error saving documents. Please try again.', 'error');
//       }
//     } catch (error) {
//       console.error('Error saving documents:', error);
//       showToast('Error saving documents. Please try again.', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBackClick = () => {
//     navigate('/login');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
//       <Sidebar />
//       <Toast />
//       <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Documents</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>

//       <div className="lg:w-4/5 md:w-3/4 lg:mt-8 mt-4 p-4">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <div className="flex flex-col font-semibold font-poppins">
//             <DocumentUpload
//               label="Tenancy Contract"
//               imgSrc="./icon-up.svg"
//               onFileChange={(e) => handleFileChange(e, 'Tenancy Contract')}
//             />
//             <DocumentUpload
//               label="Trading License"
//               imgSrc="./icon-up.svg"
//               onFileChange={(e) => handleFileChange(e, 'Trading License')}
//             />
//             <DocumentUpload
//               label="Trading Register"
//               imgSrc="./icon-up.svg"
//               onFileChange={(e) => handleFileChange(e, 'Trading Register')}
//             />
//           </div>
//           <div className="flex justify-center items-center mt-10">
//             <Button
//               text="Save"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSave}
//               loading={loading}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDocs;
