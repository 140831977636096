import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; 
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import InputBox from "../../Components/input_box";
import TextArea from "../../Components/text_area";
import AddDocument from "../../Components/add_doc";
import ReviewImageUpload from "../../Components/review_image_add";
import { add_review } from "../../../Controller/reviews"; 
import UploadDocuments from "../../../Controller/firebase/firebase_documents";
import ReportContainer from "../../Components/report";

const ReviewAdd = () => {
  const { uid } = useParams(); 
  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [documentUrl, setDocumentUrl] = useState(''); 
  const [reviewImages, setReviewImages] = useState([]); 
  const [loading, setLoading] = useState(false); // State to handle loading

  const onDocumentUpload = async (file) => {
    try {
      const downloadURL = await UploadDocuments(file);
      setDocumentUrl(downloadURL);
      console.log("Document uploaded and available at:", downloadURL);
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("Failed to upload document. Please try again.");
    }
  };

  const handleSubmit = async () => {
    setLoading(true); // Start the loading spinner
    const payload = {
      rating: rating,
      title: productName,
      description: review,
      confirmation_document: documentUrl || null, 
      image_url: reviewImages, 
    };

    try {
      const response = await add_review(uid, payload); 
      console.log("Review submitted successfully:", response);
      navigate(`/reviews_page/${uid}`);
    } catch (error) {
      console.error("Error submitting review:", error);
      alert("There was an error submitting your review. Please try again.");
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Reviews</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-2 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4 ">
      <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg mt-8">
          <h3 className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Rate the Product</h3>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {[1, 2, 3, 4, 5].map((star) => (
              <svg
                key={star}
                style={{ margin: '10px', cursor: 'pointer' }}
                className={rating >= star ? 'filled' : 'empty'}
                onClick={() => setRating(star)}
                viewBox="0 0 24 24"
                width="29"
                height="29"
                fill={rating >= star ? '#DB0000' : '#FFFFFF'}
                stroke={rating >= star ? '#DB0000' : '#DB0000'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
              </svg>
            ))}
          </div>
          <p className="text-sm md:text-base font-semibold mb-2 text-[#4A4A4A]">Add Review</p>
          <InputBox
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name"
            borderRadius="1px"
          />
          <TextArea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Sleek design, great performance. Earbuds are worth every penny."
            className="mt-4"
          />
          <h3 className="text-sm md:text-base font-semibold mt-6 mb-2 text-[#4A4A4A]">Confirmation of your purchase</h3>
          
          {/* Add Document Component */}
          <AddDocument label="Add Documents" onFileUpload={onDocumentUpload} accept=".pdf" />

          {/* Review Image Upload Component */}
          <ReviewImageUpload
            reviewImages={reviewImages}
            setReviewImages={setReviewImages}
            svgImagePath="/Assets/review.svg"
            bgColor="#C4C4C4"
            borderRadius="8px"
            width="80px"
            height="90px"
          />

          <div className="mt-10">
            <Button
              text="Submit"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSubmit}
              loading={loading} // Pass loading state to Button component
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAdd;
